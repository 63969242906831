import axios from 'axios';
import { decrypt } from 'n-krypta';
import { useState } from 'react';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../css/elevatorPitchTab.css';
import discordBtn from '../img/discordBtn.png';
import facebookBtn from '../img/facebookBtn.png';
import linkedInBtn from '../img/linkedInBtn.png';

function ElevatorPitchTab() {

    const [userData, setUserData] = useState([]);
    const [displayStateTrue, setDisplayStateTrue] = useState('');
    const [displayStateFalse, setDisplayStateFalse] = useState('');
    const [elevatorPitchAnswer, setElevatorPitchAnswer] = useState('');
    const [elevPitchVideo, setElevPitchVideo] = useState('');
    const [elevatorReflectionAnswer, setElevatorReflectionAnswer] = useState('');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        setUserData(decryptedUserData);

        axios.post('https://maxp.quadrixsoft.com/api/elevatorPitch.php', { 'userId': decryptedUserData.id, 'apiCall': 'checkUser' }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'User already exists!') {
                setDisplayStateFalse('displayState');
                setElevatorPitchAnswer(res.data.user.elevatorPitch);
                setElevPitchVideo(<iframe style={{ marginBottom: '32px', borderRadius: '11px' }} src={res.data.user.video} title="YouTube video player" width="100% " height="560"></iframe>);
                setElevatorReflectionAnswer(res.data.user.pitchReflection);
            }
            if (res.data.message === 'User not found!') {
                setDisplayStateTrue('displayState');
            }

        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <>
            <div className='elevPitchElemDiv'>
                <div className={displayStateFalse}>
                    <h4 className='elevPitchTitle'>You do not have a test Elevator Pitch done!</h4>
                </div>
                <div className={displayStateTrue}>
                    <div className='elevPitchTitleDiv'>
                        <i className="bi bi-check"></i>
                        <h4 className='elevPitchTitle'>Elevator Pitch</h4>
                        <span className='winPoints'>2000 XP</span>
                    </div>
                    <p className='elevPitchTxt'>Your Elevator Pitch has been submitted and you have won additional experience points!</p>
                    <div className='elevPitchAnswerBox'>
                        <ul>
                            <li className='elevPitchBoxQuestion'>Write your elevator pitch here.</li>
                        </ul>
                        <span className='elevPitchInput'>{elevatorPitchAnswer}</span>
                    </div>
                    <div className='elevPitchTitleDiv'>
                        <i className="bi bi-check"></i>
                        <h4 className='elevPitchTitle'>Pilot Presentation Video</h4>
                        <span className='winPoints'>7000 XP</span>
                    </div>
                    <p className='elevPitchTxt'>Your Video has been submitted and you have won additional experience points!</p>
                    {elevPitchVideo}
                    <div className='elevPitchTitleDiv'>
                        <i className="bi bi-check"></i>
                        <h4 className='elevPitchTitle'>Pilot Pitch Reflection</h4>
                        <span className='winPoints'>1000 XP</span>
                    </div>
                    <p className='elevPitchTxt'>Your Pilot Pitch Reflection has been submitted and you have won additional experience points!</p>
                    <div className='elevPitchAnswerBox'>
                        <ul>
                            <li className='elevPitchBoxQuestion'>Write your Pitch Reflection here.</li>
                        </ul>
                        <span className='elevPitchInput'>{elevatorReflectionAnswer}</span>
                    </div>
                </div>
                <div className='elevPitchSocialDiv'>
                    <h4 className='elevPitchSocialTitle'>Share with other Pilots</h4>
                    <div className='elevPitchSocialBtn'>
                        <a href='#'>
                            <img src={discordBtn} alt='' />
                        </a>
                        <a href='#'>
                            <img src={facebookBtn} alt='' />
                        </a>
                        <a href='#'>
                            <img src={linkedInBtn} alt='' />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ElevatorPitchTab;