import { useState } from "react";
import { Col, Row, Modal, Form, Button } from "react-bootstrap";
import google from '../img/google.png';
import facebook from '../img/facebook.png';
import axios from "axios";

function SignUpModal(props) {

    const [validated, setValidated] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const userLocation = props.ipData.city + ', ' + props.ipData.country_name;

    function submitReg(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            const nowDate = new Date();
            const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const registrationDate = month[nowDate.getMonth()] + ' ' + nowDate.getDate() + ', ' + nowDate.getFullYear();

            axios.post('https://maxp.quadrixsoft.com/api/signup.php', { 'firstName': firstName, 'lastName': lastName, 'email': email, 'password': password, 'userIpAddress': props.ipData.ip, 'userCountryCode': props.ipData.country_code2, 'registrationDate': registrationDate, 'timeZone': props.ipData.time_zone.offset, 'userLocation': userLocation, 'country_flag': props.ipData.country_flag }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                if (res.data.message === "Go to your email address to activate your account.") {
                    close();
                    props.onSuccess(res.data.email)
                } else {
                    setErrorMessage(res.data.message);
                }
            });
        }
    }

    function close() {
        setValidated(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setShowPass(false);
        setErrorMessage('');
        props.close();
    }

    return (
        <Modal className="loginPopup regPopup"
            show={props.show}
            onHide={close}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h2>Sign up for Free</h2>
            </Modal.Header>
            <Modal.Body>
                <div className="formMsg">
                    <span className="formMsgTrue"></span>
                    {
                        errorMessage !== '' ?
                            <span className="formMsgFalseLogin"><i className="bi bi-exclamation-circle"></i>{errorMessage}</span> :
                            null
                    }
                </div>
                <Form noValidate validated={validated} onSubmit={submitReg}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridName" className="plr0 pr10">
                            <Form.Label>FIRST NAME</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your First Name"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridLastname" className="plr0 pl10">
                            <Form.Label>LAST NAME</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your Last Name"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmailReg">
                        <Form.Label>EMAIL</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="name@email.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPasswordReg">
                        <Form.Label>PASSWORD</Form.Label>
                        <div className="passElem">
                            <Form.Control
                                className="passField"
                                type={showPass ? "text" : "password"}
                                placeholder="Your Password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {!showPass && <i className="bi bi-eye showPass" onClick={() => setShowPass(true)}></i>}
                            {showPass && <i className="bi bi-eye-slash showPass" onClick={() => setShowPass(false)}></i>}
                        </div>
                        <Form.Text className="text-muted">
                            <span>Between 8 and 70 characters</span>
                        </Form.Text>
                    </Form.Group>
                    <button className="buttonWrapperMaxp w100" type="submit">
                        <div className="button2Maxp w100">
                            SIGN UP
                        </div>
                    </button>
                    <Row className="hrElem">
                        <Col>
                            <hr className="hr-text" data-content="or"></hr>
                        </Col>
                        <Col md={1} className="orCol">
                            <span>or</span>
                        </Col>
                        <Col>
                            <hr className="hr-text" data-content="or"></hr>
                        </Col>
                    </Row>
                    <a className="btn btn-lg btn-google_facebook " href="#"><img src={google} /> Continue with Google</a>
                    <a className="btn btn-lg btn-google_facebook" href="#"><img src={facebook} /> Continue with Facebook</a>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <span>Already on MAXP? &nbsp;<span className="loginTxt" onClick={() => { close(); props.onLogin() }}>Log In</span></span>
            </Modal.Footer>
        </Modal>
    );
}

export default SignUpModal;