import React, { useEffect } from "react";
import '../css/Page-404.css';
import { Container } from "react-bootstrap";
import Header from "../app_elements/header";

function Page404() {

    // Browser TAB Title
    useEffect(() => {
        (document.title = 'MAXP | ' + '404 - Page Not Found')
    });
    // END Browser TAB Title

    return (
        <>
            <div id="ifHeader"></div>
            <Container>
                <h1 className="text-center">Page Not Found<br />404</h1>
            </Container>
        </>
    )
}

export default Page404;