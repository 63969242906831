import '../lessons_css/LessonArsirf.css';
import arsirfHeaderImg from '../lessons_img/lessonArsirf_1/arsirfSmallBg.png';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import picture1b from '../lessons_img/lessonArsirf_2/picture1b.jpg';
import picture2b from '../lessons_img/lessonArsirf_2/picture2b.jpg';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';

function LessonArsirf_2() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [practice3, setPractice3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 4 && userData.userPracticeId >= 14) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2, practice3];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '13') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
                if (practiceid === '14') {
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='lessonArsirfPage'>
                <div className='lessonArsirfHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Arsirf' >Arsirf</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 2</span>
                    </div>
                    <img src={arsirfHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO ARSIRF</p>
                    <h2 className='lessHeaderTitle'>Work with others</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 2
                        </div>
                        <h4 className="lessTitle">Networking</h4>
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>It’s not what you know, it’s who you know.</h4>
                                <p>Unknown Author</p>
                            </div>
                        </div>
                        <p className='lessTxt'>Networking is the action or process of interacting with others to exchange information and develop professional or social contacts. Also, don’t forget that we are social beings! Look for opportunities to network when you attend concerts, school competitions, or even birthday parties! Who knows you might meet the one person that changes your life! </p>
                        <img src={picture1b} className='lessImg' alt='' />
                        <h4 className='lessSubtitle'>Basics of Networking</h4>
                        <p className='lessTxt'>Not everyone has the confidence to network, but everyone is given the opportunity to do it. When it comes to making new contacts, there are some ground rules to follow. Here are four basic rules to follow:</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className="lessSubtitle">Read the room</h4>Always read the room and observe, find out who is there and what they are doing. Show respect and interest in other pilots first.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className="lessSubtitle">Share your story</h4>After listening, you usually find yourself in a situation to discuss your spaceship or goals. Make sure you are talking clearly. Have a little introduction of yourself prepared.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className="lessSubtitle">Exchange contact information</h4> Once you are talking to someone who might seem like your supporter or ally, or you are just simply amazed by them and their story - exchange contact information. Always try to leave any social situation even with a slew of new contacts and acquaintances! Facebook, Instagram, and LinkedIn are there to help you to stay in touch.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'><h4 className="lessSubtitle">Follow up and stay in touch</h4> Don't forget to send them a message, or email the next day and remind them of the great conversation you had with them. You can invite them for coffee, lunch, or dinner to continue your conversation. You might win a once-in-a-lifetime friend, colleague, or opportunity this way. Make an effort.</p>
                            </div>
                        </div>
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>If you want to go fast, go alone. If you want to go far, go together.</h4>
                                <p>A famous proverb for networking</p>
                            </div>
                        </div>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 4.C</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Pilot, let us know about your network experience. Answer these questions:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyArsirf mb32'>
                            <Form name='Practice 4.C' onSubmit={submit} practiceid='13' id='13'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Have you ever tried networking? How did you feel?</li>
                                        </ul>
                                        <Form.Control inputques="Have you ever tried networking? How did you feel?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">What kind of contact information did you receive - E-mail, Phone Number, Twitter, Instagram, TikTok, LinkedIn, ...?</li>
                                        </ul>
                                        <Form.Control inputques="What kind of contact information did you receive - E-mail, Phone Number, Twitter, Instagram, TikTok, LinkedIn, ...?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">Describe where you are going to try networking within the next two weeks.</li>
                                        </ul>
                                        <Form.Control inputques="Describe where you are going to try networking within the next two weeks." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice3(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac4C' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac4C').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <h4 className='lessSubtitle'>Increase your social capital - Where? Online!</h4>
                        <img src={picture2b} className='lessImg' alt='' />
                        <p className='lessTxt'>Increasing your social capital is another way to look at networking. Social capital is great for learning from multiple people and sharing your ideas with people with different perspectives.
                            <br /><br />
                            Increase your social capital with LinkedIn - a social network that focuses on professional networking and career development. It's a must-have for every pilot. You can use this platform to display your resume, search for jobs, and contacts and enhance your professional reputation by posting updates and interacting with other people.
                            <br /><br />
                            Obviously, there are a huge amount of other platforms for networking - Instagram, Facebook, TikTok, Twitch. Find which one works best for you. So have you updated your social media profiles yet?
                            Within the EU there is also a Europass profile where you can upload all your skills, qualifications, and experiences in one secure, online location.
                            <br /><br />
                            What can be really handy is that you can create a profile in one or more European Languages. Pilot, Your Europass profile is your personal record of your achievements. </p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 4.D</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>The questions below you should give you a good overview of your networks, and you should be able to develop strategies for expanding your network and community. The key is to take advantage of existing platforms for meeting people!</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyArsirf mb32'>
                            <Form name='Practice 4.D' onSubmit={submit} practiceid='14' id='14'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Which social media platform works best for you? And why?</li>
                                        </ul>
                                        <Form.Control inputques="Which social media platform works best for you? And why?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">How do you usually communicate with professionals on social media?</li>
                                        </ul>
                                        <Form.Control inputques="How do you usually communicate with professionals on social media?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">When do you connect with people over social media platforms and why?</li>
                                        </ul>
                                        <Form.Control inputques="When do you connect with people over social media platforms and why?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice3(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac4D' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitPrac4D').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonArsirfNextCh'>
                        <div className='lessonContainer'>
                            <img src={arsirfHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessArsirfNextChTitle'>Second Chapter of Arsirf</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Arsirf-3')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Arsirf-3'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>LEADERSHIP</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Arsirf-3'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonArsirf_2;