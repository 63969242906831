import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonMyrthyr.css';
import myrthyrHeaderImg from '../lessons_img/lessonMyrthyr_1/myrthyrSmallBg.png';
import picture1a from '../lessons_img/lessonMyrthyr_1/picture1a.jpg';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';

function LessonMyrthyr_1() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [spanClickVal, setSpanClickVal] = useState('');
    const [practice1, setPractice1] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 6 && userData.userPracticeId >= 19) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '19') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    function accHeaderClick() {
        const idForm = document.getElementById('19');
        idForm.querySelector('.accordion-button').click();
    }

    return (
        <>
            <div className='lessonMyrthyrPage'>
                <div className='lessonMyrthyrHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Myrthyr' >Myrthyr</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={myrthyrHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO MYRTHYR</p>
                    <h2 className='lessHeaderTitle'>Develop a plan</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <p className='lessTxt'>As we fly through Pisces  constellation we have reached another planet called Myrthyr. It's a beautiful but rocky planet with majestic mountains. You will explore how to climb up the mountains of planning. Time is precious and therefore planning is a required skill of a pilot. You need to know when exactly to land or fly further.
                            <br /><br />
                            We will look for opportunities and explain how to develop a plan. Planning is the stepladder that leads from desired outcomes (dreams) to results. Your task is to bridge the gap between where you are now and where you want to go, what resources you might need, and how long it will take you to implement it.</p>
                        <h4 className='lessTitle'>Developing a plan and planning</h4>
                        <img src={picture1a} className='lessImg' alt='' />
                        <p className='lessTxt'>The process of planning is more important than the plan itself. No plan is perfect but it is better to have a plan than no plan at all. Winston Churchill in his later years stated “plans are of little importance, but planning is essential.”</p>
                        <iframe style={{ marginBottom: "32px" }} width="100%" height="423" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        <p className='lessTxt'>Planning lays out the necessary steps to achieve a future goal. Mapping the course from a desire to making things happen requires a blend of logical thinking and imagination that is accessible to everyone. The details of a plan which was designed years in advance are often incorrect, but the planning process demands a thorough exploration of options and contingencies.
                            <br /><br />
                            The knowledge gained during the process of planning is crucial to the selection of appropriate actions as future events unfold.
                            <br /><br />
                            Always have a plan but not a prophecy. We cannot predict the future and our plans should reflect that. Therefore your plans should be as short-term as possible but have long-term visions. </p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 6.A</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Pick one of the four options and show us your planning skills. Plan the picked option from the beginning to the end of the final result.</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMyrthyr mb32'>
                            <Form name='Practice 6.A' onSubmit={submit} practiceid='19' id='19'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <Accordion defaultActiveKey="" flush className='lessAcc'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='lessTaskAcc practiceQuestion'>{spanClickVal}</Accordion.Header>
                                                <Accordion.Body>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Plan a vacation / 3 days hiking in local mountains</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Plan a vacation / 3 days visiting your favorite city</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Plan a small prank on .. (Your siblings, friends…)</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Plan how to get a job interview for the job you always wanted</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Plan the launch of your project</span>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Form.Control inputques="accInput" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => setPractice1(e.target.value)} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac6A' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac6A').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonMyrthyrNextCh'>
                        <div className='lessonContainer'>
                            <img src={myrthyrHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessMyrthyrNextChTitle'>First Chapter of Myrthyr</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Myrthyr-2')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Myrthyr-2'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>SWOT analysis  - a secret code that will help you plan better</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Myrthyr-2'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonMyrthyr_1;