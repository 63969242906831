import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../css/main.css";
import "../css/EditProfile.css";
import ImageUploading from 'react-images-uploading';
import { decrypt, encrypt } from "n-krypta";
import axios from "axios";
import ProfileUpdateModal from "../modals/ProfileUpdateModal";
import userDashboardBg from "../img/userDashboardBg.jpg";

function EditProfile() {

    const navigate = useNavigate();

    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userAvatar, setUserAvatar] = useState('');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        if (encryptedObject === null) {
            navigate("/");
        } else {
            const secret = localStorage.getItem("hash");
            const decryptedUserData = decrypt(encryptedObject, secret);
            setUserId(decryptedUserData.id);
            setFirstName(decryptedUserData.firstName);
            setLastName(decryptedUserData.lastName);
            setUserAvatar(decryptedUserData.userAvatar);
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const [btnDisable, setShowLessonBtn] = useState('none');
    const [disabledBtnClass, setDisableBtnClass] = useState('disabledBtnMaxp');
    const [imageData, setImageData] = useState([]);
    const [uploadBtn, setUploadBtn] = useState('');
    const [validated, setValidated] = useState(false);
    const [imageDataUrl, setImageDataUrl] = useState('');
    const [avatarState, setAvatarState] = useState('disp-none');

    const onChange = (imageList) => {
        setImageData(imageList);
        setUploadBtn('hideBtn');
        setAvatarState('');
        setShowLessonBtn('');
        setDisableBtnClass('');
    };

    useEffect(() => {
        if (imageData != "") {
            setImageDataUrl(imageData[0].data_url);
        } else if (userAvatar != "") {
            setImageDataUrl(userAvatar);
            setAvatarState('');
            setUploadBtn('hideBtn');
        } else {
            setImageDataUrl('');
        }
    }, [userAvatar, imageData]);

    //Profile Update PopUp modal
    const [showUpdate, setShowUpdate] = useState(false);
    const [updateMsg, setUpdateMsg] = useState('');

    const handleCloseUpdate = () => setShowUpdate(false);
    const handleShowUpdate = () => setShowUpdate(true);
    //END Profile Update PopUp modal

    function submit(event) {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {

            axios.post('https://maxp.quadrixsoft.com/api/editProfile.php', { 'userId': userId, 'firstName': firstName, 'lastName': lastName, 'userAvatar': imageDataUrl }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                setUpdateMsg(res.data.message);

                if (res.data.message === "Your profile has been updated.") {
                    //setMsgFalse(<><i class="bi bi-exclamation-circle"></i>{res.data.message}</>);
                    localStorage.removeItem("userData");
                    const secret = res.data.user.hash;
                    const encryptedObject = encrypt(res.data.user, secret);
                    localStorage.setItem("userData", encryptedObject);
                    handleShowUpdate();
                    setTimeout(function () {
                        handleCloseUpdate();
                        navigate(0);
                    }, 3000);
                } else {
                    // props.onSuccess(res.data.email)
                }
            });
            event.preventDefault();
        }
    }

    return (
        <>
            <div className="userDashboard">
                <Container>
                    <div className="userContentDiv">
                        <Row className='editProfileBreadcrumbs'>
                            <Col>
                                <div className="editProfileBreadcrumbsTitle">
                                    <h4 className="">User Profile</h4>
                                    <span className="spanPoint">+200 XP</span>
                                </div>
                            </Col>
                            <Col>
                                <Link to='/user-dashboard' className="editProfileBreadcrumbsBackLink">
                                    <i className="bi bi-arrow-left"></i><span>Back to Dashboard</span>
                                </Link>
                            </Col>
                        </Row>
                        <hr className="hrLineEditProf" />
                        <div className="editProfileContent">
                            <Form onSubmit={submit} noValidate validated={validated}>
                                <Row>
                                    <Form.Group as={Row} className="mb-12 fieldRow" controlId="formGroupAvatar">
                                        <Col md={6}>
                                            <Form.Label>
                                                <h4 className="labelTitle">Profile Photo</h4>
                                                <p className="labelTxt">PNG, JPEG, JPG up to 500kb. Recommended Ratio 1:1</p>
                                            </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <ImageUploading
                                                value={imageData}
                                                onChange={onChange}
                                                dataURLKey="data_url"
                                                acceptType={['jpg', 'jpeg', 'png']}
                                                maxFileSize='500000'
                                            >
                                                {({ imageList, onImageUpload, onImageRemove }) => (
                                                    <div className="upload__image-wrapper">
                                                        <a className={"uploadBtn " + uploadBtn} onClick={onImageUpload}><i className="bi bi-cloud-upload"></i>UPLOAD A PHOTO</a>
                                                        <div className={"image-item " + avatarState}>
                                                            <img className="imgAvatar" src={imageDataUrl} alt="" width="100" height="100" />
                                                            <div className="image-item__btn-wrapper">
                                                                <span onClick={() => {
                                                                    setImageDataUrl(''); setUploadBtn(''); setAvatarState("disp-none"); setShowLessonBtn(''); setDisableBtnClass('');
                                                                }}><i className="bi bi-x-circle"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-12 fieldRow" controlId="formGroupName">
                                        <Col md={6}>
                                            <Form.Label>
                                                <h4 className="labelTitle">Name</h4>
                                                <p className="labelTxt">Example: Jane</p>
                                            </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Control type="text" defaultValue={firstName} onChange={(e) => { setFirstName(e.target.value); setShowLessonBtn(''); setDisableBtnClass(''); }} required />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-12 fieldRow" controlId="formGroupTimezone">
                                        <Col md={6}>
                                            <Form.Label>
                                                <h4 className="labelTitle">Last Name</h4>
                                                <p className="labelTxt">Example: Smith</p>
                                            </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Control type="text" defaultValue={lastName} onChange={(e) => { setLastName(e.target.value); setShowLessonBtn(''); setDisableBtnClass(''); }} required />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col md={6}></Col>
                                        <Col md={6}>
                                            <div className={"buttonWrapperEditProf " + disabledBtnClass} type="submit" style={{ pointerEvents: btnDisable }}>
                                                <div className={"button2 " + disabledBtnClass}>
                                                    UPDATE
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Container>
            </div >
            {/* Update Profile PopUp modal */}
            <ProfileUpdateModal
                show={showUpdate}
                close={handleCloseUpdate}
                updateMsg={updateMsg}
            />
            {/* END Update Profile PopUp modal */}
        </>
    );
}

export default EditProfile;