import React, { useEffect, useState } from 'react';
import '../css/LessonChapters.css';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import alzubraPlanetTitle from '../lessons_img/alzubraPlanetTitle.png';
import najimaPlanetTitle from '../lessons_img/najimaPlanetTitle.png';
import esterPlanetTitle from '../lessons_img/esterPlanetTitle.png';
import arsirfPlanetTitle from '../lessons_img/arsirfPlanetTitle.png';
import moranonPlanetTitle from '../lessons_img/moranonPlanetTitle.png';
import myrthyrPlanetTitle from '../lessons_img/myrthyrPlanetTitle.png';
import infinityPlanetTitle from '../lessons_img/infinityPlanetTitle.png';
import skillBoxImg1 from '../lessons_img/1.png';
import skillBoxImg2 from '../lessons_img/2.png';
import skillBoxImg3 from '../lessons_img/3.png';
import discordImg from '../img/discordImg.png';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import { decrypt } from 'n-krypta';

function Item(props) {

    const navigate = useNavigate();

    const [btnDisable, setShowLessonBtn] = useState('none');
    const [disabledBtn, setDisableBtnClass] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);
    const [lessonPage, setLessonPage] = useState('');
    const lessonUrl = '/lesson-' + props.planetCh + '-' + props.chapterNumberCh;
    const [practiceFin, setPracticeFin] = useState('0');

    useEffect(() => {
        const encryptedObject = localStorage.getItem("userData");
        const encryptedObjectCh = localStorage.getItem("userNextChapter");

        if (encryptedObject) {
            const secret = localStorage.getItem("hash");
            const userData = decrypt(encryptedObject, secret);
            const userNextChapter = decrypt(encryptedObjectCh, secret);

            if (userData.userLessonId > 0 && userNextChapter.lessonNumber == props.lessonNumberCh && userNextChapter.chapterNumber == props.chapterNumberCh) {
                setShowLessonBtn('');
                setDisableBtnClass('');
                setLessonPage(lessonUrl);
                setUserData(userData);
            } else if (userData.userLessonId == 0 && props.idCh == 1) {
                setShowLessonBtn('');
                setDisableBtnClass('');
                setLessonPage(lessonUrl);
            }
            else if (userData.userLessonId >= props.lessonNumberCh) {
                setShowLessonBtn('');
                setDisableBtnClass('');
                setLessonPage(lessonUrl);
            } else if (userNextChapter.lessonNumber === 7) {
                setShowLessonBtn('');
                setDisableBtnClass('');
                setLessonPage(lessonUrl);
            } else {
                setShowLessonBtn('none');
                setDisableBtnClass('disabledBtn');
            }
        } else if (props.idCh === 1) {
            setShowLessonBtn('');
            setDisableBtnClass('');
            setLessonPage(lessonUrl);
        } else {
            setShowLessonBtn('none');
            setDisableBtnClass('disabledBtn');
        }
    }, [lessonUrl]);

    return (
        <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtn} onClick={() => navigate(lessonPage)} style={{ pointerEvents: btnDisable }}>
            <div className={"button2Maxp w100 " + disabledBtn} style={{ pointerEvents: btnDisable }}>
                <Row className='lessonChapterBtn'>
                    <Col md={10}>
                        <Link to={lessonPage} state={props.titleCh}>
                            <p className='chapterNum '>Chapter {props.chapterNumberCh}</p>
                            <h4 className='chapterTitle '>{props.titleCh}</h4>
                        </Link>
                    </Col>
                    <Col md={2} className="btnRightCol">
                        <Link to={lessonPage} state={props.titleCh}>
                            <p className='practiceTxt '>Practice</p>
                            <h4 className='practiceNum '>{userData.userPracticeId ? userData.userPracticeId : '0'}/{props.practiceCh}</h4>
                        </Link>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

function LessonChapters() {

    const location = useLocation();

    let params = useParams();
    const planet = params.planetName;

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const [items, setItems] = useState([]);
    const [lessonData, setLessonData] = useState({});

    const { isLoading, error, data, refetch } = useQuery('chapLesson', () =>
        axios.post('https://maxp.quadrixsoft.com/api/lessonsMainPage.php', { planet: planet }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((response) => {

            setItems(response.data.chapters.map((item, index) => {
                return <Item key={'lessonsChapter' + index}
                    idCh={item.idCh}
                    lessonNumberCh={item.lessonNumberCh}
                    chapterNumberCh={item.chapterNumberCh}
                    planetCh={item.planetCh}
                    titleCh={item.titleCh}
                    practiceCh={item.practiceCh}
                    featured_imageCh={item.featured_imageCh} />
            }))
            setLessonData(response.data.lessons);
        }));

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [location]);

    if (isLoading) return <Spinner className='sectionSpinner' animation="border" />
    if (error) return <div className='sectionError'><h2>An error has occurred: {error.message}<br />Please try to reload again on the bottom button.</h2><Button className='defButton' onClick={() => window.location.reload(false)}>RELOAD</Button></div>


    let featuredImage = '/';
    let planetTitle = '';

    if (lessonData?.planet === "Alzubra") { planetTitle = alzubraPlanetTitle; featuredImage = "classAlzubraBg"; }
    if (lessonData?.planet === "Najima") { planetTitle = najimaPlanetTitle; featuredImage = "classNajimaBg"; }
    if (lessonData?.planet === "Ester") { planetTitle = esterPlanetTitle; featuredImage = "classEsterBg"; }
    if (lessonData?.planet === "Arsirf") { planetTitle = arsirfPlanetTitle; featuredImage = "classArsirfBg"; }
    if (lessonData?.planet === "Moranon") { planetTitle = moranonPlanetTitle; featuredImage = 'classMoranonBg' }
    if (lessonData?.planet === "Myrthyr") { planetTitle = myrthyrPlanetTitle; featuredImage = 'classMyrthyrBg' }
    if (lessonData?.planet === "Infinity") { planetTitle = infinityPlanetTitle; featuredImage = 'classInfinityBg' }


    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <section className={'lessonHeaderSec ' + featuredImage}>
                <div className='container'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i>
                        <span>{lessonData?.planet} World</span>
                    </div>
                    <div className='lessonHeaderDiv'>
                        <img className='planetImgTitle' src={planetTitle} alt='' />
                        <div className='lessonSkillsDiv'>
                            <p className='lessonSkills'>SKILLS TO LEARN</p>
                            <div className='skillsBoxes'>
                                <span className='skillBox'><img src={skillBoxImg1} alt='' /> Self-awareness</span>
                                <span className='skillBox'><img src={skillBoxImg1} alt='' /> Communication</span>
                                <span className='skillBox'><img src={skillBoxImg1} alt='' /> Time management</span>
                            </div>
                            <div className='skillsBoxes'>
                                <span className='skillBox'><img src={skillBoxImg1} alt='' /> Problem-solving</span>
                                <span className='skillBox'><img src={skillBoxImg2} alt='' /> Confidence</span>
                                <span className='skillBox'><img src={skillBoxImg3} alt='' /> Prioritization</span>
                            </div>
                        </div>
                        <Row className='lessonTitleDiv'>
                            <Col md={10} className='p0'>
                                <p className='lessonSubtitle'>{lessonData?.subTitle}</p>
                                <h2 className='lessonTitle'>{lessonData?.title}</h2>
                            </Col>
                            <Col md={2} className='p0'>
                                <div className="plusPoints">+XX XP</div>
                            </Col>
                        </Row>
                        <p className='lessonDescription'>{lessonData?.description}</p>
                        {items}
                    </div>
                </div>
            </section>
            <section className='discordSec'>
                <Row className='discordRow container'>
                    <Col className='discordCol' md={7}>
                        <div className='discordTitle'>
                            <h4>Join MAXP Community <br />on Discord</h4>
                            <div className="plusPoints">+100 XP</div>
                        </div>
                        <p className='discordTxt'>Discuss and ask Questions about this World.</p>
                        <div className="buttonWrapperMaxp" onClick={() => openInNewTab('https://discord.com/')}>
                            <div className="button2Maxp w100">
                                JOIN OUR COMMUNITY
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <img src={discordImg} alt='' />
                    </Col>
                </Row>
            </section>
        </>
    );
}

export default LessonChapters;