import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../css/FutureJourneys.css';
import logoTop from '../img/logoCertificate.png';
import discordImg from '../img/discordImg.png';

function FeedbackInfo() {

    const navigate = useNavigate();

    function userSpacePort() {
        navigate("/user-dashboard", { state: "spacePort" });
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <div className='feedbackInfoPage'>
                <section className='feedbackInfoBg'>
                    <div className='feedbackBox'>
                        <img className='feedbackInfoLogo' src={logoTop} alt='' />
                        <h4 className='feedbackInfoTitle'>Thank you pilot, your opinion means a lot to us</h4>
                        <p className='feedbackInfoTxt'>Let’s stay in touch with the community of pilots and we hope to guide you on your travels through upcoming constellations soon.
                            <br /><br />
                            See you soon,<br />
                            Yours MAXP team</p>
                        <div className="buttonWrapperMaxp" onClick={userSpacePort}>
                            <div className="button2Maxp">
                                GO BACK TO YOUR SPACE PORT
                                <i className="bi bi-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='discordSec'>
                    <Row className='discordRow container'>
                        <Col className='discordCol' md={7}>
                            <div className='discordTitle'>
                                <h4>Join MAXP Community <br />on Discord</h4>
                                <div className="plusPoints">+100 XP</div>
                            </div>
                            <p className='discordTxt'>Discuss and ask Questions about this World.</p>
                            <div className="buttonWrapperMaxp" onClick={() => openInNewTab('https://discord.com/')}>
                                <div className="button2Maxp w100">
                                    JOIN OUR COMMUNITY
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <img src={discordImg} alt='' />
                        </Col>
                    </Row>
                </section>
            </div>
        </>
    );
}

export default FeedbackInfo;