import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonInfinity.css';
import infinityHeaderImg from '../lessons_img/lessonInfinity_1/infinitySmallBg.png';
import picture1c from '../lessons_img/lessonInfinity_3/picture1c.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import astroIco from '../lessons_img/lessonInfinity_3/g1612.png';
import playBoardIco from '../lessons_img/lessonInfinity_3/g1957.png';
import infinityMedal from '../lessons_img/lessonInfinity_3/infinityMedal.png';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';

function LessonInfinity_3() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [btnDisablePractice3, setShowLessonBtnPractice3] = useState('none');
    const [disabledBtnPractice3, setDisableBtnPractice3] = useState('disabledBtn');
    const [disabledInputTrue1, setDisabledInputTrue1] = useState('');
    const [disabledInputTrue2, setDisabledInputTrue2] = useState('');
    const [disabledInputTrue3, setDisabledInputTrue3] = useState('');
    const [input1Val, setInput1Val] = useState('');
    const [input2Val, setInput2Val] = useState('');
    const [input3Val, setInput3Val] = useState('');
    const [iFrameVideo, setIFrameVideo] = useState('');
    const [userData, setUserData] = useState([]);

    const [elevatorAnswer1, setElevatorAnswer1] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, [encryptedObject]);

    function getYoutubeId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }
    useEffect(() => {
        if (elevatorAnswer1) {
            const videoId = getYoutubeId(elevatorAnswer1);
            if (videoId != null) {
                setIFrameVideo(<iframe style={{ marginTop: '24px', borderRadius: '11px' }} src={'https://www.youtube.com/embed/' + videoId} title="YouTube video player" width="100% " height="400"></iframe>);
                setElevatorAnswer1('https://www.youtube.com/embed/' + videoId);
            } else {
                setElevatorAnswer1(elevatorAnswer1);
            }
        } else {
            setElevatorAnswer1('');
            setIFrameVideo('');
        }
    }, [elevatorAnswer1]);

    useEffect(() => {
        axios.post('https://maxp.quadrixsoft.com/api/elevatorPitch.php', { 'userId': userData.id, 'apiCall': 'checkUser' }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'User already exists!') {

                if (res.data.user.elevatorPitch) {
                    setInput1Val(res.data.user.elevatorPitch);
                    setDisabledInputTrue1('true');
                }
                if (res.data.user.video) {
                    setInput2Val(res.data.user.video);
                    setDisabledInputTrue2('true');
                    setIFrameVideo(<iframe style={{ marginTop: '24px' }} src={res.data.user.video} title="YouTube video player" width="100% " height="400"></iframe>);
                }
                if (res.data.user.pitchReflection) {
                    setInput3Val(res.data.user.pitchReflection);
                    setDisabledInputTrue3('true');
                }
                if (res.data.user.elevatorPitch && res.data.user.video && res.data.user.pitchReflection) {
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                }
            }

        }).catch((error) => {
            console.log(error);
        });
    }, [userData]);

    // PITCH SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const apiCall = event.target.getAttribute('name');

        axios.post('https://maxp.quadrixsoft.com/api/elevatorPitch.php', { 'userId': userData.id, 'apiCall': apiCall, 'userAnswer': elevatorAnswer1, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            switch (res.data.message) {
                case 'Successful1':
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                    setInput1Val(res.data.user.elevatorPitch);
                    setDisabledInputTrue1('true');
                    break;
                case 'Successful2':
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                    setInput2Val(res.data.user.video);
                    setDisabledInputTrue2('true');
                    setIFrameVideo(<iframe style={{ marginTop: '24px' }} src={res.data.user.video} title="YouTube video player" width="100% " height="400"></iframe>);
                    break;
                case 'Successful3':
                    setShowLessonBtnPractice3('none');
                    setDisableBtnPractice3('disabledBtn');
                    setInput3Val(res.data.user.elevatorPitch);
                    setDisabledInputTrue3('true');
                    break;
            }
            if (res.data.user.elevatorPitch && res.data.user.video && res.data.user.pitchReflection) {
                setShowLessonBtnNext('');
                setDisableBtnNext('');
            }
            console.log(res.data.message)
            setCheckTrue(false);

        }).catch((error) => {
            console.log(error);
        });
    }
    //END PITCH SUBMIT

    return (
        <>
            <div className='lessonInfinityPage'>
                <div className='lessonInfinityHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Infinity' >Infinity</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 3</span>
                    </div>
                    <img src={infinityHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO INFINITY</p>
                    <h2 className='lessHeaderTitle'>Present yourself</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 3
                        </div>
                        <h4 className='lessTitle'>Final practice - your spaceship pitch</h4>
                        <img src={picture1c} className='lessImg' alt='' />
                        <div className='lessBox'>
                            <h4 className='lessRocketTitle'>For example, a student's elevator pitch might go something like this:</h4>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>"Hi, my name is [name] and I am an experienced pilot with a passion for flying. I have overcome many challenges in my studies, including [specific examples]. I believe what sets me apart as a pilot is my [unique qualities or skills]. I have a strong track record of [achievements or milestones] and am confident in my abilities to handle any situation that may arise. I am looking to take the next step in my career and am excited to explore opportunities with [specific company or program]. Thank you for considering me as a potential candidate."</p>
                            </div>
                        </div>
                        <p className='lessTxt'>Dear Pilot, it has been fun! Now it's time to wrap it up in one final task.</p>
                        <div className='lessPracticeHeader lessElevatorPitch'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <img src={astroIco} alt='' />
                                        <h4 className='lessPracticeTitle'>Elevator Pitch</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Write an elevator pitch about yourself and your piloting skills. Be sure to include the following elements:</p>
                                    <ul>
                                        <li>A catchy introduction </li>
                                        <li>The challenges you have overcome as a pilot </li>
                                        <li>What makes you special or unique as a pilot </li>
                                        <li>Your strengths and abilities as a pilot</li>
                                        <li>What you hope will happen next (e.g., getting hired for a specific job, being accepted into a training program, etc.)</li>
                                    </ul>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyInfinity mb32'>
                            <Form name='elevatorPitch' onSubmit={submit} id='1'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv br11'>
                                            <ul>
                                                <li className='practiceQuestion'>Write your elevator pitch here.</li>
                                            </ul>
                                            <Form.Control defaultValue={input1Val} disabled={disabledInputTrue1} as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setElevatorAnswer1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitElevPitch1' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitElevPitch1').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className='lessPracticeHeader lessElevatorPitch'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <img src={playBoardIco} alt='' />
                                        <h4 className='lessPracticeTitle'>Pilot Presentation Video</h4>
                                    </div>
                                    <ul>
                                        <li>Record your 60-second pitch and upload it to services such as YouTube, Vimeo, or a similar service as Wetransfer.com. </li>
                                        <li>Upload the link to your elevator pitch right here to collect feedback from other pilots in this constellation.</li>
                                    </ul>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyInfinity mb32'>
                            <Form name='video' onSubmit={submit} id='2'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv br11'>
                                            <ul>
                                                <li>Paste your link here</li>
                                            </ul>
                                            <Form.Control defaultValue={input2Val} disabled={disabledInputTrue2} as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setElevatorAnswer1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                            {iFrameVideo}
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <button id='submitVideo' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitVideo').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className='lessPracticeHeader lessElevatorPitch'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <img src={astroIco} alt='' />
                                        <h4 className='lessPracticeTitle'>Pilot Pitch Reflection</h4>
                                    </div>
                                    <ul>
                                        <li>Write down how you felt about writing and recording your pitch.</li>
                                    </ul>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyInfinity mb32'>
                            <Form name='pitchReflection' onSubmit={submit} id='3'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv br11'>
                                            <ul>
                                                <li>Write your Pitch Reflection here.</li>
                                            </ul>
                                            <Form.Control defaultValue={input3Val} disabled={disabledInputTrue3} as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setElevatorAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitReflection' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice3} onClick={() => { document.getElementById('submitReflection').click() }} style={{ pointerEvents: btnDisablePractice3 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice3} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Infinity you should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Have a clear understanding of how to present yourself. </li>
                                    <li>Write a brief and effective way of presenting your story. </li>
                                    <li>Know how to engage your audience. </li>
                                    <li>Create and deliver an elevator pitch.</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://techjobsfair.com/ways-introduce-yourself-professionally-casually/' target='_blank' >techjobsfair.com</a>
                                        <a className='lessResourcesUrl' href='https://www.scienceofpeople.com/how-to-give-captivating-presentations/' target='_blank'>scienceofpeople.com</a>
                                        <a className='lessResourcesUrl' href='https://www.indeed.com/career-advice/career-development/asking-the-right-questions' target='_blank'>indeed.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecInfinity'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={infinityHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Infinity</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col></Col>
                                    <Col>
                                        <img className='alzubraMedal' src={infinityMedal} alt='' />
                                        <p className='lessP'>Infinity Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/boundary')} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 infiLastBtn " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <Link to={'/boundary'}>
                                                    <p className='chapterNum '>End of the Pisces Constellation</p>
                                                    <h4 className='chapterTitle '>PISCES Constellation boundary</h4>
                                                </Link>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <Link to={'/boundary'}>
                                                    <i className="bi bi-arrow-right"></i>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonInfinity_3;