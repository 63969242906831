import { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import google from '../img/google.png';
import facebook from '../img/facebook.png';
import axios from "axios";
import { encrypt } from "n-krypta";

function LoginModal(props) {
    const [validated, setValidated] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function submit(event) {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            // axios.post('https://maxp.quadrixsoft.com/api/login.php', { 'email': email, 'password': password }, {
            //     headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            // }).then((res) => {
            axios.post('https://maxp.quadrixsoft.com/api/login.php', { 'email': email, 'password': password }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                if (res.data.message === "You are logged in.") {
                    close();
                    const secret = res.data.user.hash;
                    const encryptedObject = encrypt(res.data.user, secret);
                    const encryptedObjectCh = encrypt(res.data.userNextChapter, secret);
                    localStorage.setItem("userData", encryptedObject);
                    localStorage.setItem("userNextChapter", encryptedObjectCh);
                    localStorage.setItem("hash", secret);
                    props.onSuccess();
                } else {
                    setErrorMessage(res.data.message);
                }
            }).catch((error) => {
                console.log(error);
            });

            event.preventDefault();
        }
    }
    function close() {
        setValidated(false);
        setShowPass(false);
        setEmail('');
        setPassword('');
        setErrorMessage('');
        props.close();
    }

    return (
        <Modal className="loginPopup"
            show={props.show}
            onHide={close}
            backdrop="static"
            keyboard={false}
            //size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h2>Pilot, <br />Welcome Back!</h2>
            </Modal.Header>
            <Modal.Body>
                <div className="formMsg">
                    <span className="formMsgTrue"></span>
                    {
                        errorMessage !== '' ?
                            <span className="formMsgFalseLogin"><i className="bi bi-exclamation-circle"></i>{errorMessage}</span> :
                            null
                    }
                </div>
                <Form name="loginForm" noValidate validated={validated} onSubmit={submit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>EMAIL</Form.Label>
                        <Form.Control
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            placeholder="name@email.com"
                            required />
                    </Form.Group>

                    <Form.Group className="mb32" controlId="formBasicPassword">
                        <Form.Label>PASSWORD</Form.Label>
                        <div className="passElem">
                            <Form.Control
                                onChange={(e) => setPassword(e.target.value)}
                                value={password} className="passField"
                                type={showPass ? "text" : "password"}
                                placeholder="Your Password"
                                required />
                            {showPass ?
                                <i className="bi bi-eye showPass" onClick={() => setShowPass(false)}></i> :
                                <i className="bi bi-eye-slash showPass" onClick={() => setShowPass(true)}></i>
                            }
                        </div>
                        <Form.Text className="text-muted">
                            <a className="forgotPass" href="/forgot-password">Forgot Password?</a>
                        </Form.Text>
                    </Form.Group>
                    <button className="buttonWrapperMaxp w100" type='submit'>
                        <div className="button2Maxp w100">
                            LOGIN
                        </div>
                    </button>
                    <Row className="hrElem">
                        <Col>
                            <hr className="hr-text" data-content="or"></hr>
                        </Col>
                        <Col md={1} className="orCol">
                            <span>or</span>
                        </Col>
                        <Col>
                            <hr className="hr-text" data-content="or"></hr>
                        </Col>
                    </Row>
                    <a className="btn btn-lg btn-google_facebook " href="#"><img src={google} /> Continue with Google</a>
                    <a className="btn btn-lg btn-google_facebook" href="#"><img src={facebook} /> Continue with Facebook</a>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <span>New to MAXP? &nbsp;<span className="loginTxt" onClick={() => { close(); props.onSignUp() }}>Sign Up</span></span>
            </Modal.Footer>
        </Modal>
    )
}

export default LoginModal;