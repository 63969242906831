import axios from 'axios';
import { decrypt } from 'n-krypta';
import { useState } from 'react';
import { useEffect } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../css/selfAssesmentTestTab.css';

function Item(props) {

    const [satGraph, setSatGraph] = useState('');
    const [satUserAnswer, setSatUserAnswer] = useState('');
    const [satInput, setSatInput] = useState('');

    let cardTitle = '';

    if (props.questionId === 1) { cardTitle = 'Self-awareness' };
    if (props.questionId === 2) { cardTitle = 'Motivation' };
    if (props.questionId === 3) { cardTitle = 'Leadership' };
    if (props.questionId === 4) { cardTitle = 'Confidence to be a change agent' };
    if (props.questionId === 5) { cardTitle = 'Creativity / Divergent thinking' };
    if (props.questionId === 6) { cardTitle = 'Critical thinking' };
    if (props.questionId === 7) { cardTitle = 'Communication & Ability to find support through networking' };
    if (props.questionId === 8) { cardTitle = 'Ability to plan' };
    if (props.questionId === 9) { cardTitle = 'Budget' };
    if (props.questionId === 10) { cardTitle = 'Present yourself with confidence' };

    useEffect(() => {
        if (props.hasImproved && props.questionId != 2) { setSatGraph(<><i className="satGraph bi bi-graph-up-arrow"></i></>) }
        if (props.testId === 1 && props.questionId != 2) { setSatUserAnswer(<><span className='satUserAnswerBtn'>{props.userAnswer}</span></>) }
        if (props.questionId === 2) { setSatInput(<><input className='satInputAnswer' type='text' disabled value={props.userAnswer} /></>) }
    }, []);

    return (
        <>
            <Row className='satCardDiv'>
                <Col md={11} className=''>
                    <h4 className='satCardTitle'>{cardTitle}</h4>
                    <p className='satCardQuestion m0'>{props.question}</p>
                    {satInput}
                </Col>
                <Col md={1}>
                    {satGraph}
                    {satUserAnswer}
                </Col>
            </Row>
        </>
    );

}

function SelfAssesmentTestTab() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [userData, setUserData] = useState([]);
    const [welcomeTitle, setWelcomeTitle] = useState('');
    const [btnDisable, setShowLessonBtn] = useState('none');
    const [disabledBtn, setDisableBtn] = useState('disabledBtn');
    const [accordian1, setAccordian1] = useState('');
    const [accordian2, setAccordian2] = useState('');
    const [items1, setItems1] = useState([]);
    const [items2, setItems2] = useState([]);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        if (encryptedObject) {
            const secret = localStorage.getItem("hash");
            const decryptedUserData = decrypt(encryptedObject, secret);
            setUserData(decryptedUserData);
        }
    }, []);

    useEffect(() => {

        if (userData.selfAssTest === '0') {
            setWelcomeTitle(<><h4 className='sat1TabTitle'>Pilot, you don't have any Self Assessment Test done.</h4></>)
            setAccordian1('disp-none');
            setAccordian2('disp-none');
        }
        if (userData.selfAssTest === '1') {
            setWelcomeTitle(<><h4 className='sat1TabTitle' > Pilot, you have one more Self Assessment Test left to do...</h4></>);
            setAccordian1('');
            setAccordian2('disp-none');
        }
        if (userData.selfAssTest === '2') {
            setWelcomeTitle(<>
                <div className='sat2TabTitleDiv'>
                    <i className="bi bi-check"></i>
                    <h4 className='sat2TabTitle'>Congratulations Pilot, you have completed the Self Assessment Tests</h4>
                    <span className='sat2TabPoints'>200 XP</span>
                </div>
                <p className='sat2TabTxt'>You have successfully completed both self-assessment tests and have made a lot of progress. Now you are ready for new journeys and discoveries. Now you are more experienced with new knowledge. May luck accompany you on your next adventures. Good luck</p>
            </>);
            setShowLessonBtn('');
            setDisableBtn('');
            setAccordian1('');
            setAccordian2('');
        }
    }, [userData]);



    useEffect(() => {
        axios.post('https://maxp.quadrixsoft.com/api/selfAssesmentTestUserCheck.php', { 'userId': userData.id }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'SAT exists') {
                let satRes1 = [];
                let satRes2 = [];

                satRes1 = res.data.userSat.filter(item => item.testId == 1).map((item, index) => {
                    return <Item key={index}
                        id={item.id}
                        userId={item.userId}
                        testId={item.testId}
                        questionId={item.questionId}
                        question={item.question}
                        userAnswer={item.userAnswer}
                        dateAndTime={item.dateAndTime}
                    />
                })

                satRes2 = res.data.userSat.filter(item => item.testId == 2).map((item, index) => {
                    return <Item key={index}
                        id={item.id}
                        userId={item.userId}
                        testId={item.testId}
                        questionId={item.questionId}
                        question={item.question}
                        userAnswer={item.userAnswer}
                        dateAndTime={item.dateAndTime}
                        hasImproved={satRes1[index].props.userAnswer < item.userAnswer}
                    />
                })

                setItems1(satRes1);
                setItems2(satRes2);
            } else {
                console.log(res.data.message)
            }

        }).catch((error) => {
            console.log(error);
        });
    }, [userData]);

    return (
        <>
            <div className='satContentDiv'>
                {welcomeTitle}
                <div className={"buttonWrapperMaxp " + disabledBtn} style={{ pointerEvents: btnDisable }}>
                    <Link to='/future-journeys' className={"button2Maxp " + disabledBtn} style={{ pointerEvents: btnDisable }}>
                        Go to NEXT Constalation
                    </Link>
                </div>
                <Accordion defaultActiveKey="" flush className='satAccordian'>
                    <Accordion.Item eventKey="0" className={accordian1}>
                        <Accordion.Header className='firstAcc'>Self Assesment Test 1</Accordion.Header>
                        <Accordion.Body>
                            {items1}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className={accordian2}>
                        <Accordion.Header className='secAcc'>Self Assesment Test 2</Accordion.Header>
                        <Accordion.Body>
                            {items2}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default SelfAssesmentTestTab;