import React, { useEffect, useState } from 'react';
import '../css/Course.css';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import alzubraSmallBg from '../lessons_img/alzubraSmallBg.png';
import najimaSmallBg from '../lessons_img/najimaSmallBg.png';
import esterSmallBg from '../lessons_img/esterSmallBg.png';
import arsirfSmallBg from '../lessons_img/arsirfSmallBg.png';
import infinitySmallBg from '../lessons_img/infinitySmallBg.png';
import moranonSmallBg from '../lessons_img/moranonSmallBg.png';
import myrthyrSmallBg from '../lessons_img/myrthyrSmallBg.png';
import discordImg from '../img/discordImg.png';
import { useLocation, BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { decrypt } from 'n-krypta';

function Item(props) {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const lessonMainPage = '/lesson-chapters' + '/' + props.planet;

    let lessonCardBgImg = '';

    const [btnDisable, setShowLessonBtn] = useState('none');
    const [disabledBtnClass, setDisableBtnClass] = useState('disabledBtn');

    useEffect(() => {
        const encryptedObject = localStorage.getItem("userData");
        const encryptedObjectCh = localStorage.getItem("userNextChapter");

        if (encryptedObjectCh) {
            const secret = localStorage.getItem("hash");
            const userData = decrypt(encryptedObject, secret);
            const userNextChapter = decrypt(encryptedObjectCh, secret);

            if (userNextChapter.lessonNumber >= props.id) {
                setShowLessonBtn('');
                setDisableBtnClass('');
            }

            if (props.id === 1 && userData.userLessonId === 0) {
                setShowLessonBtn('');
                setDisableBtnClass('');
            }
        } else if (props.id === 1) {
            setShowLessonBtn('');
            setDisableBtnClass('');
        }
    }, []);

    if (props.planet === 'Alzubra') { lessonCardBgImg = alzubraSmallBg; }
    if (props.planet === 'Najima') { lessonCardBgImg = najimaSmallBg; }
    if (props.planet === 'Ester') { lessonCardBgImg = esterSmallBg; }
    if (props.planet === 'Arsirf') { lessonCardBgImg = arsirfSmallBg; }
    if (props.planet === 'Infinity') { lessonCardBgImg = infinitySmallBg; }
    if (props.planet === 'Moranon') { lessonCardBgImg = moranonSmallBg; }
    if (props.planet === 'Myrthyr') { lessonCardBgImg = myrthyrSmallBg; }

    let cardTitle = '';
    let cardDescription = '';

    if (props.description.length > 90) { cardDescription = props.description.substr(0, props.description.lastIndexOf(' ', 90)) + '...' } else { cardDescription = props.description; }

    if (props.title.length > 24) { cardTitle = props.title.substr(0, props.title.lastIndexOf(' ', 24)) + '...' } else { cardTitle = props.title; }

    return (
        <Col md={4} className='courseLessonCard'>
            <Row className='courseLessonHeaderCardRow'>
                <Col className='courseLessonHeaderCardCol' xs={6}>
                    <span className='lessonNo'>Lesson {props.lessonNumber}</span>
                    <h4 className='cardHeaderPlanet'>{props.planet}</h4>
                </Col>
                <Col className='curseCardPoint' xs={6}>
                    <div className="plusPoints">+{props.points} XP</div>
                </Col>
            </Row>
            <img src={lessonCardBgImg} alt={props.planet} />
            <div className='cardTextDiv'>
                <h4 className='lessonCardTitle'>{cardTitle}</h4>
                <p className='cardDescriptionText'>{cardDescription}</p>
                <div className='text-center'>
                    <div className={"buttonWrapperMaxp w100 " + disabledBtnClass} onClick={() => navigate(lessonMainPage)} style={{ pointerEvents: btnDisable }}>
                        <div className={"button2Maxp w100 " + disabledBtnClass}>
                            START EXPEDITION
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

function Course() {

    const location = useLocation();
    const rootName = window.location.origin;

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const [items, setItems] = useState([]);

    let cardLessons = [];

    const { isLoading, error, data, refetch } = useQuery('course', () =>
        fetch('https://maxp.quadrixsoft.com/api/lessons.php')
            .then(res => res.json())
            .then((res) => {

                cardLessons = res.lessons.map((item, index) => {
                    return <Item key={index}
                        id={item.id}
                        constalationId={item.constalationId}
                        planet={item.planet}
                        title={item.title}
                        subTitle={item.subTitle}
                        description={item.description}
                        lessonNumber={item.lessonNumber}
                        featured_image={item.featured_image}
                        small_img={item.small_img}
                        points={item.points} />
                });
                setItems(cardLessons);
            }))
    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [location]);

    if (isLoading) return <Spinner className='sectionSpinner' animation="border" />
    if (error) return <div className='sectionError'><h2>An error has occurred: {error.message}<br />Please try to reload again on the bottom button.</h2><Button className='defButton' onClick={() => window.location.reload(false)}>RELOAD</Button></div>

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <section className='courseHeaderSec' >
                <Row className='courseHeaderRow container'>
                    <Col className='plr0'>
                        <div className='breadcrumbsCourse'>
                            <p className=''>Pisces Constalation</p>
                        </div>
                        <div className='courseHeaderElem'>
                            <div className='courseHeaderContent'>
                                <p className='courseHeaderSubtitle'>WELCOME TO PISCES CONSTELATION</p>
                                <h2 className='courseHeaderTitle'>Let’s begin with our journey of self development</h2>
                                <p className='courseHeadetTxt'>Learn the basics of ideation, creativity, budgeting, and becoming a team player.<br /> Entrepreneurship competence level 1</p>
                            </div>
                            <Row className='courseHeaderRowScore justify-content-md-center'>
                                <Col className='courseHeaderColScore' md={2}>
                                    <h4 className='scoreNum'>7</h4>
                                    <p className='scoreTxt'>Max Earned <br />Badges</p>
                                </Col>
                                <Col className='courseHeaderColScore' md={2}>
                                    <div className="plusPoints">+1000 XP</div>
                                </Col>
                                <Col className='courseHeaderColScore' md={2}>
                                    <h4 className='scoreNum'>86</h4>
                                    <p className='scoreTxt'>Max Completed <br />Questions</p>
                                </Col>
                                <Col className='courseHeaderColScore lastCol' md={2}>
                                    <h4 className='scoreNum'>25</h4>
                                    <p className='scoreTxt'>Max Completed <br />Practices</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </section>
            <section className='courseLessonsBoxSec'>
                <Row className='courseLessonsBoxRow container' id='courseLessonsBoxRow'>
                    {items}
                </Row>
            </section>
            <section className='discordSec'>
                <Row className='discordRow container'>
                    <Col className='discordCol' md={7}>
                        <div className='discordTitle'>
                            <h4>Join MAXP Community <br />on Discord</h4>
                            <div className="plusPoints">+100 XP</div>
                        </div>
                        <p className='discordTxt'>Discuss and ask Questions about this World.</p>
                        <div className="buttonWrapperMaxp" onClick={() => openInNewTab('https://discord.com/')}>
                            <div className="button2Maxp w100">
                                JOIN OUR COMMUNITY
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <img src={discordImg} alt='' />
                    </Col>
                </Row>
            </section>
        </>
    );
}

export default Course;