import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonMyrthyr.css';
import myrthyrHeaderImg from '../lessons_img/lessonMyrthyr_1/myrthyrSmallBg.png';
import picture1d from '../lessons_img/lessonMyrthyr_4/picture1d.jpg';
import picture2d from '../lessons_img/lessonMyrthyr_4/picture2d.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import myrthyrMedal from '../lessons_img/lessonMyrthyr_4/myrthyrMedal.png';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';
import ModuleSpecificQuestionsModal from '../../modals/ModuleSpecificQuestionsModal';

function LessonMyrthyr_4() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [showMsq, setShowMsq] = useState(false);

    const handleCloseMsq = () => setShowMsq(false);
    const handleShowMsq = () => setShowMsq(true);

    function buttonUrl() {
        if (userData.modulSpecQuestion === 5) {
            handleShowMsq();
        } else {
            navigate('/lesson-Infinity-1');
        }
    }

    const [quizAnswer1, setQuizAnswer1] = useState('');
    const [quizAnswer2, setQuizAnswer2] = useState('');
    const [quizAnswer3, setQuizAnswer3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 6 && userData.quizTotal >= 5) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    //QUIZ SUBMIT
    function submitQuiz(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const quizid = event.target.getAttribute('quizid');
        const quizTitle = event.target.getAttribute('name');
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const quizQuestion = $('#' + formId + ' .quizQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [quizAnswer1, quizAnswer2, quizAnswer3];

        axios.post('https://maxp.quadrixsoft.com/api/usersQuiz.php', { 'userId': userData.id, 'quizid': quizid, 'quizTitle': quizTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'quizQuestion': quizQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (quizid === '5') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END QUIZ SUBMIT

    return (
        <>
            <div className='lessonMyrthyrPage'>
                <div className='lessonMyrthyrHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Myrthyr' >Myrthyr</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 4</span>
                    </div>
                    <img src={myrthyrHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO MYRTHYR</p>
                    <h2 className='lessHeaderTitle'>Develop a plan</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 4
                        </div>
                        <h4 className='lessTitle'>Adapt, Evolve, Survive</h4>
                        <img src={picture1d} className='lessImg' alt='' />
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>No plan survives the first contact with the enemy</h4>
                                <p>Helmuth von Moltke the Elder</p>
                            </div>
                        </div>
                        <p className='lessTxt'>This final chapter of Lesson 6 will focus on situations when your plans just don't work out. There is almost 100% certainty that everything you've planned won't go smoothly according to the plan.</p>
                        <h4 className='lessSubtitle'>Overcoming obstacles and pivoting</h4>
                        <p className='lessTxt'>Pilots, there are a few tips on how to overcome obstacles.
                            <br /><br />
                            Various obstacles can hinder successful spaceship planning. In order for plans to be effective and to yield the desired results, pilots must identify any potential barriers and work to overcome them.
                            <br /><br />
                            The common barriers that inhibit successful planning are as follows:</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Inability to plan or inadequate planning.</h4>Pilots are not born with the ability to plan. It is a skill that is learned gradually. Therefore there is nothing better than practice.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Lack of commitment to the planning process</h4>Developing a plan is hard work and you might need to redo your plans many times. It's important to know what you are doing and why you are doing it.  Another possible reason for lack of commitment can be fear of failure. We need to realize what is holding us back and how to deal with it. It is important to keep in mind that we all have some limitations.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Bad Communication and Participation</h4>When you set goals and plan everything, you need to inform others about everything. Pilots and co-pilots must have a voice in the spaceship process. Remember, equal participation and setting proper goals for each is the key to the right motivation.  </p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>No Effective Reward Systems</h4>Never forget to reward yourself and your pals. But remember! Fairly and consistently.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'><h4 className='lessSubtitle'>Fear of Change</h4>Pilots, as we discussed in previous lessons -  Change is inevitable, change is good and change is needed. You need change and development that is coming with it.
                                    <br /><br />
                                    So don't hold onto old principles when they are not serving your purposes. Adapt, progress, and fly.</p>
                            </div>
                        </div>
                        <img src={picture2d} className='lessImg' alt='' />
                        <div className='lessPracticeHeader headerQuiz'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-mortarboard"></i>
                                        <h4 className='lessPracticeTitle'>Myrthyr Quiz</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear Pilot, practice makes perfect so here is a little quiz:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMyrthyr mb32'>
                            <Form name='Myrthyr Quiz' onSubmit={submitQuiz} quizid='5' id='5'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv'>
                                            <ul>
                                                <li className='quizQuestion'>What is not part of the SWOT analysis?</li>
                                            </ul>
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Strength' value='Strength' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Weaknesses' value='Weaknesses' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Threats' value='Threats' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Time' value='Time' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Opportunities' value='Opportunities' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv br0'>
                                            <ul>
                                                <li className='quizQuestion'>What does the A in SMART stand for?</li>
                                            </ul>
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='Achievable' value='Achievable' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='Agreed upon' value='Agreed upon' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='Altogether impossible' value='Altogether impossible' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv bbr'>
                                            <ul>
                                                <li className='quizQuestion'>What is more important?</li>
                                            </ul>
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='The plan we create' value='The plan we create' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='The Planning Process ' value='The Planning Process ' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitMyrthyrQuiz' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitMyrthyrQuiz').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Myrthyr you should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Do a SWOT analysis of your spaceship</li>
                                    <li>Have a clear understanding of SMART goals setting</li>
                                    <li>Focus on the planning process more than the plans itself</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://demo.dspacedirect.org/handle/10673/792' target='_blank' >demo.dspacedirect.org</a>
                                        <a className='lessResourcesUrl' href='https://www.forbes.com/sites/forbesbusinesscouncil/2022/01/06/why-your-business-planning-process-is-more-important-than-the-plan-itself/?sh=2fbfd5df1066' target='_blank'>forbes.com</a>
                                        <a className='lessResourcesUrl' href='https://quoteinvestigator.com/2017/11/18/planning/' target='_blank'>quoteinvestigator.com</a>
                                        <a className='lessResourcesUrl' href='https://mchenryconsulting.net/plans-are-nothing-planning-is-everything/' target='_blank'>mchenryconsulting.net</a>
                                        <a className='lessResourcesUrl' href='https://www.mindtools.com/a4wo118/smart-goals' target='_blank'>mindtools.com</a>
                                        <a className='lessResourcesUrl' href='https://www.atlassian.com/blog/productivity/how-to-write-smart-goals' target='_blank'>atlassian.com</a>
                                        <a className='lessResourcesUrl' href='https://www.cliffsnotes.com/study-guides/principles-of-management/organizational-planning/identifying-barriers-to-planning' target='_blank'>cliffsnotes.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecMyrthyr'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={myrthyrHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Myrthyr</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col>
                                        <h4 className='lessFin'>3/3</h4>
                                        <p className='lessP'>Practice</p>
                                    </Col>
                                    <Col>
                                        <h4 className='lessFin'>1/1</h4>
                                        <p className='lessP'>Quiz</p>
                                    </Col>
                                    <Col>
                                        <img className='alzubraMedal' src={myrthyrMedal} alt='' />
                                        <p className='lessP'>Myrthyr Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={buttonUrl} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <p className='chapterNum '>Visit Infinity</p>
                                                <h4 className='chapterTitle '>Present Yourself</h4>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <i className="bi bi-arrow-right"></i>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModuleSpecificQuestionsModal
                show={showMsq}
                close={handleCloseMsq}
            />
        </>
    );
}

export default LessonMyrthyr_4;