import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonInfinity.css';
import infinityHeaderImg from '../lessons_img/lessonInfinity_1/infinitySmallBg.png';
import picture1a from '../lessons_img/lessonInfinity_1/picture1a.jpg';
import picture2a from '../lessons_img/lessonInfinity_1/picture2a.jpg';
import picture3a from '../lessons_img/lessonInfinity_1/picture3a.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';

function LessonInfinity_1() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    // const [btnDisable, setShowLessonBtn] = useState('none');
    // const [disabledBtn, setDisableBtnClass] = useState('disabledBtn');
    const [btnDisable, setShowLessonBtn] = useState('');
    const [disabledBtn, setDisableBtnClass] = useState('');

    return (
        <>
            <div className='lessonInfinityPage'>
                <div className='lessonInfinityHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Infinity' >Infinity</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={infinityHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO INFINITY</p>
                    <h2 className='lessHeaderTitle'>Present yourself</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <p className='lessTxt'>Oh, pilots, this fun journey is about to end as we just landed on planet Infinity and it's the last one of this constellation.  This planet seems wholesome and reminds you of the earth as you can see everything. The ocean, all the elements, nature, and houses.
                            <br /><br />
                            Pilots, our fun journey is coming to an end as we've just landed on the final planet of the constellation, Infinity. This planet looks a lot like Earth, with oceans, nature, and houses in view. But don't be fooled, because on Infinity we will be embarking on an important inner journey. We will learn how to present our best selves, craft our stories, and engage those around us. Are you ready to start this lesson? Let's get started!</p>
                        <img src={picture1a} className='lessImg' alt='' />
                        <h4 className='lessTitle'>Present yourself</h4>
                        <p className='lessTxt'>Pilot, everyone finds themselves in situations where one has to introduce themselves whether it is a professional or casual gathering. In such a situation it's useful to have a few tricks up your sleeve that will send the right impression and cues.
                            <br /><br />
                            Whether you realize it or not, you are constantly sending signals to everyone around you. From the first moment you enter a room, you are scanned by other people looking for cues and you are doing the same.
                            <br /><br />
                            Try to make the first impression remarkable, warm and positive. So, you want to start with the right cues while introducing yourself. Because the introduction is essential to any further interactions. No matter who you are or what you've achieved or are about to achieve - balancing warmth and competence is a key to your success in your personal and professional life.
                            <br /><br />
                            Before you enter any room the most important thing is to think about what intentions and cues you want to send based on your goals. Prepare yourself, greet others, make eye contact, and shake hands. After the greeting it's time for your introduction - The right introduction is not too long and too short and it also doesn't have to be completely technical or professional.</p>
                        <img src={picture2a} className='lessImg' alt='' />
                        <div className='lessBox'>
                            <h4 className='lessRocketTitle'>Your introduction should have these three simple points:</h4>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Your name (who you are), - say it confidently</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Your occupation (what you do) or what you intend to be if you are not working right now</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>Lastly, some facts that will create a nice impression on the person you are interacting with (what others need to know about you - pilot, find anything special, or something you are confident about)  or finish with a question about the other person, so you can keep the conversation going and show interest in them.</p>
                            </div>
                        </div>
                        <p className='lessTxt'>These three steps are important, because if you are interesting enough, others will remember you, and will want to know more about you.</p>
                        <div className='lessBox'>
                            <h4 className='lessRocketTitle'>Here are important tips for further conversation after the introduction:</h4>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Always be aware of the context</h4>It is by far the essential tip for any introduction. Be highly prepared to avoid any cliché or wrong questions to the wrong person.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Ask the right questions</h4>Pilot, choose well the first question that will start the conversation. Asking the right questions is important to help you receive the information you seek. It's important to ask specific questions as you likely want and listen for a specific answer.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'><h4 className='lessSubtitle'>Always be aware of the context</h4>Lastly, some facts that will create a nice impression on the person you are interacting with (what others need to know about you - pilot, find anything special, or something you are confident about)  or finish with a question about the other person, so you can keep the conversation going and show interest in them.</p>
                            </div>
                            <div className='rocketListDiv'>
                                <h4 className='rocketListTitle'>- Think about what you want to know.</h4>
                                <p className='lessTxt rocketListTxt'>The more specific questions the more specific answers. Deciding what you want to know may help ensure you ask the right questions.</p>
                                <h4 className='rocketListTitle'>- Determine why you want to ask this question.</h4>
                                <p className='lessTxt rocketListTxt'>Think about what kind of answer you want to receive. Consider if you want to receive advice, a fact-based answer, or someone else's opinion or perspective.</p>
                                <h4 className='rocketListTitle'>- Develop an open-ended question</h4>
                                <p className='lessTxt rocketListTxt'>Create an open-ended question related to what you want to know. These questions may help the person you are asking to feel more comfortable as you do not limit their response. Ensure your question is easy to understand. Evaluate your question to determine if it is unbiased. Be sure to focus your question on only one topic to help avoid confusion.</p>
                                <h4 className='rocketListTitle'>- Find the right person to ask</h4>
                                <p className='lessTxt rocketListTxt'>The right person depends on what you hope to learn.</p>
                                <h4 className='rocketListTitle'>- Determine the right time to ask them</h4>
                                <p className='lessTxt rocketListTxt'>It's important to choose the right time to ask questions.  Avoid tense or stressful situations, and reduce distraction. Try to plan ahead to have enough time for the conversation.</p>
                                <h4 className='rocketListTitle'>- Ask follow-up questions</h4>
                                <p className='lessTxt rocketListTxt'>Mainly to learn more about the situation. and ask questions that highlight your natural curiosity and genuine desire to learn more.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Dress the part</h4>Dressing is one of the most basic and vital presentation tips. It’s also essential in personality building. Every occasion and company has its own uniform and expectations. Dress the part you want to play.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Take an interest in the world</h4>Take an interest in the world around you - it will be easier to find topics to talk about, so read articles, watch the news, and have a general awareness, and education and you can talk to people of any age.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'><h4 className='lessSubtitle'>Body language</h4>This is 70-93% of our communication, so be aware of it and try to control it, and master it. Mainly try to mind your hands, don't rush with words and pilots, mainly be you. Body language is key. It speaks a lot about your confidence and who you are.</p>
                            </div>
                        </div>
                        <p className='lessTxt'>After any introduction or conversation say a proper goodbye and something like “<span className='lessTxtColorGreen'>It was a pleasure meeting you. I appreciate you giving me your time today. Hope to see you again.</span>”</p>
                        <img src={picture3a} className='lessImg mb64' alt='' />
                    </div>
                    <div className='lessonInfinityNextCh'>
                        <div className='lessonContainer'>
                            <img src={infinityHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessInfinityNextChTitle'>First Chapter of Infinity</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtn} onClick={() => navigate('/lesson-Infinity-2')} style={{ pointerEvents: btnDisable }}>
                                <div className={"button2Maxp w100 " + disabledBtn} style={{ pointerEvents: btnDisable }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Infinity-2'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>Prepare your elevator pitch</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Infinity-2'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonInfinity_1;