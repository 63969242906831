import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Resend(props) {

    const [errorMessage, setErrorMessage] = useState('');
    const [formMsgBg, setFormMsgBg] = useState('');

    // Resend Email API
    function resendEmail() {
        axios.post('https://maxp.quadrixsoft.com/api/resendEmail.php', { 'email': props.email }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'An error occurred. Please try again to Re-send Email.') {
                setErrorMessage('');
                const elemStyle = { "background": "#F76763", "color": "#EEF0F4" };
                setFormMsgBg(elemStyle);
                setErrorMessage(res.data.message);
            } else {
                setErrorMessage('');
                const elemStyle = { "background": "#43E8DD", "color": "#281F53" };
                setFormMsgBg(elemStyle);
                setErrorMessage(res.data.message);
            }
        });
    }
    // END Resend Email API

    return (
        <Modal className="loginPopup "
            show={props.show}
            onHide={props.close}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
        // centered
        >
            <Modal.Header closeButton>
                <h2>Check your Inbox</h2>
            </Modal.Header>
            <Modal.Body className="modalResend">
                <div className="formMsg">
                    {
                        errorMessage !== '' ?
                            <span className="formMsgFalseLogin" style={formMsgBg}>
                                <i className="bi bi-exclamation-circle" style={formMsgBg}></i>{errorMessage}
                            </span> : null
                    }
                </div>
                <p className="mb32">We emailed you an login link to <span className="userEmail">{props.email}</span>.</p>
                <button className="buttonWrapperMaxp w100" onClick={resendEmail}>
                    <div className="button2Maxp w100">
                        RE-SEND EMAIL
                    </div>
                </button>
                <p className="resendBottomTxt">Can’t find your link? Check your spam folder.</p>
            </Modal.Body>
            <Modal.Footer className="resendFooter">
                <Link className="resendBack" to="/" onClick={props.close}>Go Back</Link>
            </Modal.Footer>
        </Modal>
    );
}

export default Resend;