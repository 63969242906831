import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// ========== APP PAGES ==========
import Home from './pages/Home';
import ForgotPassword from './pages/ForgotPassword';
import Activation from './pages/Activation';
import UserDashboard from './pages/UserDashboard';
import EditProfile from './pages/EditProfile';
import ResetPassword from './pages/ResetPassword';
import Page404 from './pages/Page-404';
import LessonChapters from './pages/LessonChapters';
import LessonAlzubra_1 from './lessons/lessons_pages/LessonAlzubra_1';
import LessonAlzubra_2 from './lessons/lessons_pages/LessonAlzubra_2';
import LessonNajima_1 from './lessons/lessons_pages/LessonNajima_1';
import LessonNajima_2 from './lessons/lessons_pages/LessonNajima_2';
import LessonNajima_3 from './lessons/lessons_pages/LessonNajima_3';
import LessonEster_1 from './lessons/lessons_pages/LessonEster_1';
import LessonArsirf_1 from './lessons/lessons_pages/LessonArsirf_1';
import LessonArsirf_2 from './lessons/lessons_pages/LessonArsirf_2';
import LessonArsirf_3 from './lessons/lessons_pages/LessonArsirf_3';
import LessonMoranon_1 from './lessons/lessons_pages/LessonMoranon_1';
import LessonMoranon_2 from './lessons/lessons_pages/LessonMoranon_2';
import LessonMyrthyr_1 from './lessons/lessons_pages/LessonMyrthyr_1';
import LessonMyrthyr_2 from './lessons/lessons_pages/LessonMyrthyr_2';
import LessonMyrthyr_3 from './lessons/lessons_pages/LessonMyrthyr_3';
import LessonMyrthyr_4 from './lessons/lessons_pages/LessonMyrthyr_4';
import LessonInfinity_1 from './lessons/lessons_pages/LessonInfinity_1';
import LessonInfinity_2 from './lessons/lessons_pages/LessonInfinity_2';
import LessonInfinity_3 from './lessons/lessons_pages/LessonInfinity_3';
// import Lesson from './pages/Lesson';
import Boundary from './pages/Boundary';
import Course from './pages/Course';
import Faq from './pages/Faq';
import FutureJourneys from './pages/FutureJourneys';
import FeedbackInfo from './pages/FeedbackInfo';
import Intro from './pages/Intro';

function Router() {

    // Browser TAB Title
    const titles = {
        '/': 'Home',
        '/forgot-password': 'Forgot Password',
        '/activation': 'Activation',
        '/user-dashboard': 'Dashboard',
        '/edit-profile': 'Edit Profile',
        '/reset-password': 'Reset your Password',
        '/lesson-chapters': 'Lesson Chapters',
        '/lesson': 'Lesson',
        '/course': 'Course',
        '/faq': 'FAQ',
        '/lesson-Alzubra-1': 'Lesson Alzubra - Chapter 1',
        '/lesson-Alzubra-2': 'Lesson Alzubra - Chapter 2',
        '/lesson-Najima-1': 'Lesson Najima - Chapter 1',
        '/lesson-Najima-2': 'Lesson Najima - Chapter 2',
        '/lesson-Najima-3': 'Lesson Najima - Chapter 3',
        '/lesson-Ester-1': 'Lesson Ester - Chapter 1',
        '/lesson-Arsirf-1': 'Lesson Arsirf - Chapter 1',
        '/lesson-Arsirf-2': 'Lesson Arsirf - Chapter 2',
        '/lesson-Arsirf-3': 'Lesson Arsirf - Chapter 3',
        '/lesson-Moranon-1': 'Lesson Moranon - Chapter 1',
        '/lesson-Moranon-2': 'Lesson Moranon - Chapter 2',
        '/lesson-Myrthyr-1': 'Lesson Myrthyr - Chapter 1',
        '/lesson-Myrthyr-2': 'Lesson Myrthyr - Chapter 2',
        '/lesson-Myrthyr-3': 'Lesson Myrthyr - Chapter 3',
        '/lesson-Myrthyr-4': 'Lesson Myrthyr - Chapter 4',
        '/lesson-Infinity-1': 'Lesson Infinity - Chapter 1',
        '/lesson-Infinity-2': 'Lesson Infinity - Chapter 2',
        '/lesson-Infinity-3': 'Lesson Infinity - Chapter 3',
        '/boundary': 'Boundary',
        '/future-journeys': 'Future Journeys',
        '/feedback-info': 'Feedback Info',
        '/intro': 'Intro'
    }

    const location = window.location.pathname;
    useEffect(() => {
        (document.title = titles[location.pathname] ?? 'MAXP | ' + titles[location])
    }, [location]);

    return (
        <>
            <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='activation' element={<Activation />} />
                <Route path='user-dashboard' element={<UserDashboard />} />
                <Route path='edit-profile' element={<EditProfile />} />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='lesson-chapters/:planetName' element={<LessonChapters />} />
                <Route path='course' element={<Course />} />
                <Route path='faq' element={<Faq />} />
                <Route path='*' element={<Page404 />} />
                <Route path='boundary' element={<Boundary />} />
                {/* LESSONS PAGES */}
                <Route path='lesson-Alzubra-1' element={<LessonAlzubra_1 />} />
                <Route path='lesson-Alzubra-2' element={<LessonAlzubra_2 />} />
                <Route path='lesson-Najima-1' element={<LessonNajima_1 />} />
                <Route path='lesson-Najima-2' element={<LessonNajima_2 />} />
                <Route path='lesson-Najima-3' element={<LessonNajima_3 />} />
                <Route path='lesson-Ester-1' element={<LessonEster_1 />} />
                <Route path='lesson-Arsirf-1' element={<LessonArsirf_1 />} />
                <Route path='lesson-Arsirf-2' element={<LessonArsirf_2 />} />
                <Route path='lesson-Arsirf-3' element={<LessonArsirf_3 />} />
                <Route path='lesson-Moranon-1' element={<LessonMoranon_1 />} />
                <Route path='lesson-Moranon-2' element={<LessonMoranon_2 />} />
                <Route path='lesson-Myrthyr-1' element={<LessonMyrthyr_1 />} />
                <Route path='lesson-Myrthyr-2' element={<LessonMyrthyr_2 />} />
                <Route path='lesson-Myrthyr-3' element={<LessonMyrthyr_3 />} />
                <Route path='lesson-Myrthyr-4' element={<LessonMyrthyr_4 />} />
                <Route path='lesson-Infinity-1' element={<LessonInfinity_1 />} />
                <Route path='lesson-Infinity-2' element={<LessonInfinity_2 />} />
                <Route path='lesson-Infinity-3' element={<LessonInfinity_3 />} />
                <Route path='future-journeys' element={<FutureJourneys />} />
                <Route path='feedback-info' element={<FeedbackInfo />} />
                <Route path='intro' element={<Intro />} />
                {/* END LESSON PAGES */}
                {/* <Route path='lesson/:planet/:lessonNum/:chapter' element={<Lesson />} /> */}
            </Routes>
        </>
    );
}

export default Router;