import { decrypt, encrypt } from 'n-krypta';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonNajima.css';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import najimaHeaderImg from '../lessons_img/lessonNajima_1/najimaSmallBg.png';
import picture1 from '../lessons_img/lessonNajima_1/picture1.jpg';
import picture2 from '../lessons_img/lessonNajima_1/picture2.jpg';
import $ from 'jquery';
import picture3 from '../lessons_img/lessonNajima_1/picture3.jpg';
import axios from 'axios';

function LessonNajima_1() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [practice3, setPractice3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 2 && userData.userPracticeId >= 4) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2, practice3];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '4') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='lessonNajimaPage'>
                <div className='lessonNajimaHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Najima' >Najima</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={najimaHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO NAJIMA</p>
                    <h2 className='lessHeaderTitle'>Be in and Trendy</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <h4 className='lessTitle'>Welcome to the second planet called Najima, Pilot!</h4>
                        <p className='lessTxt'>In the atmosphere of this planet, you will become familiar with the concept of trends, trendspotting, technology, sustainability, and The Seventeen Sustainable Development Goals (SDGs).<br />
                            Your spaceship can have an impact on society and the economy. The pilot is commonly seen as someone who is able to spot trends, innovator and is a source of new ideas, and services but is also aware of what's happening around him. </p>
                        <img className='lessImg' src={picture1} alt='' />
                        <h4 className='lessTitle'>Spotting Trends and New Technology</h4>
                        <p className='lessTxt'>In the current universe, one might be a bit confused with all the new technologies and trends. In any work field, trends are changing rapidly. Throughout this course, we would like to encourage you to support your adaptability and trendspotting skills to be able to keep up with the flow.
                            <br /><br />
                            On the most basic level - trendspotting is exactly what it sounds like - the ability to spot current and upcoming trends in specific industries or areas so you can adapt yourself, stay on top of them and get ahead with the next big thing. Trends could be a cool way to help you see what your options are or you can track down how some trends helped previous spaceships.
                            <br /><br />
                            The pilots who are able to use this tactic are the ones that stand to have the most success and growth over the years with their spaceships.</p>
                        <img className='lessImg' src={picture2} alt='' />
                        <h4 className='lessSubtitle'>Spot what it's cool before it happens</h4>
                        <p className='lessTxt'>The trend is what is popular at a certain point in time. It can be in any area and doesn't only reflect fashion, pop culture, and entertainment. There can be trends in the market, in the economy, or a political trend that reflects a nation's current mood. Some trends are fun, some fabulous, and some appalling, but however long they last, you can be sure there will always be a new trend coming along to replace the old.
                            Here is a small theory box about how to spot trends:</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Scanning - is a survey of current media (newspapers, websites, social media, …) Scanning focuses on trends and changes that occur throughout time. Remember changes that occur very quickly and generally are less significant for understanding the future. (What is the main topic? Do you see something else coming behind this?)</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Ask people - you want to know something, ask people the right questions. For example, you want to know what is trendy among students. Ask them who is the coolest one in their class and why. If the answer repeats itself, multiple times you find a trend.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Historical analysis - Winston Churchill wrote, “Those that fail to learn from history are doomed to repeat it.” Use historical events and trends to anticipate the outcome of current developments and don't forget trends can return.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Visioning - Systematic creation of visions of a desirable future for an organization or an individual. Typically, this procedure starts with a review of past events and the current situation, moves on to envision desirable futures, and concludes with the identification of specific ways to move toward the desired future.</p>
                            </div>
                        </div>
                        <img className='lessImg' src={picture3} alt='' />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 2.A</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Trends are usually spotted whenever they are already trendy. Pilots, we would like to encourage your abilities to think ahead and be able to recognize what will be trendy earlier than others. Basically to be a step ahead of others.<br />
                                        Think and answer these questions about trends: </p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyNaj'>
                            <Form name='Practice 2.A' onSubmit={submit} practiceid='4' id='4'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>Ask your parents, teachers, or Grandparents what was cool when they were your age?</li>
                                        </ul>
                                        <Form.Control inputques='Ask your parents, teachers, or Grandparents what was cool when they were your age?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>Who are the coolest people in your class/school and why? What do they have in common?</li>
                                        </ul>
                                        <Form.Control inputques='Who are the coolest people in your class/school and why? What do they have in common?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv' style={{ marginBottom: '0' }}>
                                        <ul>
                                            <li className='practiceQuestion'>Can you describe from newspapers, TV, and social media discussions what's trending now in certain areas like culture, technology, and politics? Choose one area so it's easier for you to spot the trend</li>
                                        </ul>
                                        <Form.Control inputques="Can you describe from newspapers, TV, and social media discussions what's trending now in certain areas like culture, technology, and politics? Choose one area so it's easier for you to spot the trend" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice3(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac2A' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac2A').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonNajimaNextCh'>
                        <div className='lessonContainer'>
                            <img src={najimaHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessNajNextChTitle'>First Chapter of Najima</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Najima-2')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Najima-2'}>
                                                <p className='chapterNum '>Chapter 2</p>
                                                <h4 className='chapterTitle '>TECHNOLOGY SHAPES THE WORLD</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Najima-2'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonNajima_1;