import { Button, Modal } from 'react-bootstrap';
import '../css/CookieModal.css';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import { useState } from 'react';

function CookieModal(props) {

    console.log(props.ipData)

    const [visiblyState, setVisiblyState] = useState('');
    const [hiddenState, setHiddenState] = useState('hiddenState');

    function submitCookies() {
        const getBtn = document.querySelector('.cookieSubmitBtn');
        //getBtn.click();
        props.close();
    }

    return (
        <>
            <Modal className='cookieModal' show={props.show} onHide={props.close} backdrop="static" keyboard={false}>
                <Modal.Body className='cookieModalBody'>
                    <div className={'cookieModalDivPreff ' + hiddenState}>
                        <i className="bi bi-arrow-left" onClick={() => { setHiddenState('hiddenState'); setVisiblyState('') }}></i>
                        <h4 className='cookieModalTitle'>Customize your prefferences</h4>
                    </div>
                    <h4 className={'cookieModalTitle ' + visiblyState}>Do you allow us to use cookies?</h4>
                    <p className={'cookieModalTxt ' + visiblyState}>We use cookies to learn where you struggle when you’re navigating our website and fix them for you future visit.</p>
                    <CookieBanner className={'cookieBanner ' + hiddenState}
                        message=""
                        onAcceptPreferences={() => {
                            // load your preferences trackers here
                        }}
                        onAcceptStatistics={() => {
                            // load your statistics trackers here
                        }}
                        onAcceptMarketing={() => {
                            // load your marketing trackers here
                        }}
                    />
                    <div className='cookieModalBtns' onClick={() => { setHiddenState(''); setVisiblyState('hiddenState') }}>
                        <div className={"buttonWrapperMaxp " + visiblyState}>
                            <div className="buttonSimple-3Maxp">
                                CUSTOMIZE
                            </div>
                        </div>
                        <div className={"buttonWrapperMaxp " + visiblyState} onClick={submitCookies}>
                            <div className="button2Maxp">
                                ALLOW ALL
                            </div>
                        </div>
                        <div className={"buttonWrapperMaxp cookieSubmitBtn " + hiddenState} onClick={submitCookies}>
                            <div className="button2Maxp">
                                SAVE AND SUBMIT
                            </div>
                        </div>
                    </div>
                    <p className={'cookieModalTxtDeclineAll ' + visiblyState} onClick={submitCookies}>Decline all</p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CookieModal;