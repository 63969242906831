import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useLocation, useNavigate } from 'react-router-dom';
import '../css/Home.css';
import { Button, Col, Row, Accordion, Spinner, Card, CardGroup } from 'react-bootstrap';
import $ from 'jquery';
import astronaut from '../img/Hero-1-1.png';
import cillingAstronaut from '../img/Cilling-astronaut-1.png';
import bonusPoints from '../img/bonusPoints.png';
import erasmusFlag from '../img/erasmusFlag.png';
import alzubraSmall from '../lessons_img/alzubraSmallBg.png';
import najimaSmall from '../lessons_img/najimaSmallBg.png';
import esterSmall from '../lessons_img/esterSmallBg.png';
import arsifSmall from '../lessons_img/arsirfSmallBg.png';
import infinitySmall from '../lessons_img/infinitySmallBg.png';
import moranonSmall from '../lessons_img/moranonSmallBg.png';
import myrthyrSmall from '../lessons_img/myrthyrSmallBg.png';
import serbiaFlag from '../img/serbia.png';
import sloveniaFlag from '../img/slovenia.png';
import romaniaFlag from '../img/romania.png';
import czechFlag from '../img/czech.png';
import qu from '../img/qu.png';
import { useQuery } from 'react-query';
import { decrypt } from 'n-krypta';

function Item(props) {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisable, setShowLessonBtn] = useState('none');
    const [disabledBtnClass, setDisableBtnClass] = useState('disabledBtnMaxp');

    useEffect(() => {
        const encryptedObject = localStorage.getItem("userData");
        const encryptedObjectCh = localStorage.getItem("userNextChapter");

        if (encryptedObjectCh) {
            const secret = localStorage.getItem("hash");
            const userData = decrypt(encryptedObject, secret);
            const userNextChapter = decrypt(encryptedObjectCh, secret);

            if (userNextChapter.lessonNumber >= props.id) {
                setShowLessonBtn('');
                setDisableBtnClass('');
            }

            if (props.id === 1 && userData.userLessonId === 0) {
                setShowLessonBtn('');
                setDisableBtnClass('');
            }
        } else if (props.id === 1) {
            setShowLessonBtn('');
            setDisableBtnClass('');
        }
    }, []);

    let lessonCardImg = '';
    let lessonPlusPoint = '';
    const planetName = props.planet;
    const lessonMainPage = '/lesson-chapters' + '/' + planetName;

    if (props.small_img === 'alzubraSmall.png') { lessonCardImg = alzubraSmall; lessonPlusPoint = '+300 XP'; }
    if (props.small_img === 'najimaSmall.png') { lessonCardImg = najimaSmall; lessonPlusPoint = '+400 XP'; }
    if (props.small_img === 'esterSmall.png') { lessonCardImg = esterSmall; lessonPlusPoint = '+500 XP'; }
    if (props.small_img === 'arsifSmall.png') { lessonCardImg = arsifSmall; lessonPlusPoint = '+600 XP'; }
    if (props.small_img === 'infinitySmall.png') { lessonCardImg = infinitySmall; lessonPlusPoint = '+700 XP'; }
    if (props.small_img === 'moranonSmall.png') { lessonCardImg = moranonSmall; lessonPlusPoint = '+800 XP'; }
    if (props.small_img === 'myrthyrSmall.png') { lessonCardImg = myrthyrSmall; lessonPlusPoint = '+900 XP'; }

    let cardTitle = '';
    let cardDescription = '';

    if (props.title.length > 24) { cardTitle = props.title.substr(0, props.title.lastIndexOf(' ', 24)) + '...' } else { cardTitle = props.title; }

    if (props.description.length > 100) { cardDescription = props.description.substr(0, props.description.lastIndexOf(' ', 99)) + '...' } else { cardDescription = props.description; }

    return (
        <div className='lessonCard'>
            <Row className='lessonTitleCardRow'>
                <Col className='lessonConstelationCol' xs={7}>
                    <p className='cardHeaderDescription'>PISCES CONSTELATION</p>
                    <h4 className='cardHeaderTitle'>{props.planet}</h4>
                    <span className='plusPoints'>{lessonPlusPoint}</span>
                </Col>
                <Col className='cardImgCol' xs={5}>
                    <img src={lessonCardImg} alt='' />
                </Col>
            </Row>
            <p>Lesson {props.lessonNumber}</p>
            <h4 className='lessonCardTitle'>{cardTitle}</h4>
            <p className='cardDescriptionText'>{cardDescription}</p>
            <div className={"buttonWrapperMaxp lessonsHomeBtn " + disabledBtnClass}
                onClick={() => navigate(lessonMainPage)}
                style={{ pointerEvents: btnDisable }}
            >
                <div className={"button2Maxp " + disabledBtnClass}>
                    START EXPEDITION
                </div>
            </div>
        </div>
    );
}

function Home() {

    const location = useLocation();
    const navigate = useNavigate();

    // if (location.state === "startIntro") {
    //     console.log(location.state)
    // }

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    function bonusPointsSecSignUp() {
        document.getElementsByClassName('signupBtn')[0].click();
    }

    const [items, setItems] = useState([]);

    let cardLessons = [];

    const { isLoading, error, data } = useQuery('homePageLessons', () =>
        fetch('https://maxp.quadrixsoft.com/api/lessons.php')
            .then(res => res.json())
            .then((res) => {
                cardLessons = res.lessons.map((item, index) => {
                    return <Item key={index}
                        id={item.id}
                        constalationId={item.constalationId}
                        planet={item.planet}
                        title={item.title}
                        subTitle={item.subTitle}
                        description={item.description}
                        lessonNumber={item.lessonNumber}
                        featured_image={item.featured_image}
                        small_img={item.small_img}
                        points={item.points} />
                });
                setItems(cardLessons);
            }))

    if (isLoading) return <Spinner className='sectionSpinner' animation="border" />
    if (error) return <div className='sectionError'><h2>An error has occurred: {error.message}<br />Please try to reload again on the bottom button.</h2><Button className='defButton' onClick={() => window.location.reload(false)}>RELOAD</Button></div>

    // Lessons Box slider //testimonialsRow
    function btnClickL() {
        var box = $("#lessonRow");

        var x = ((box.width() / 3)) - box.scrollLeft();
        box.animate({
            scrollLeft: -x + 50,
        })
    };

    function btnClickR() {
        var box = $("#lessonRow");

        var x = ((box.width() / 3)) + box.scrollLeft();
        box.animate({
            scrollLeft: x - 50,
        })
    };

    function testbtnClickL() {
        var box = $("#testimonialsRow");

        var x = ((box.width() / 3)) - box.scrollLeft();
        console.log(x)
        box.animate({
            scrollLeft: -x - 8,
        })
    };

    function testbtnClickR() {
        var box = $("#testimonialsRow");

        var x = ((box.width() / 3)) + box.scrollLeft();
        box.animate({
            scrollLeft: x + 8,
        })
    };
    // END Lessons Box slider //

    return (
        <>
            <div className="Home">
                <section className='spaceSec '>
                    <Row className='spaceRow container'>
                        <Col className='heroTxtCol'>
                            <h1 className='spaceTitle'>Best Online<br />
                                Course For Your<br />
                                Self Development
                            </h1>
                            <p className='spaceTxt'>Learn the basics of ideation, creativity, budgeting, and becoming a team player. Entrepreneurship competence level 1</p>
                            <div className="buttonWrapperMaxp" onClick={() => navigate("/course")}>
                                <div className="button2Maxp">
                                    START YOUR JOURNEY
                                </div>
                            </div>
                        </Col>
                        <Col className='heroImgCol'>
                            <img src={astronaut} alt="" />
                        </Col>
                    </Row>
                </section>
                <section className='maxpVideoSec'>
                    <Row className='videoRow'>
                        <Col>
                            <h2>Learn more about MAXP Courses</h2>
                            <iframe width="900" height="500" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        </Col>
                    </Row>
                </section>
                <section className='lessonsSec'>
                    <Row className='lessonsRow'>
                        <Col md={2}></Col>
                        <Col md={3} className="plr0 secCol">
                            <p className='welcomeTxt'>WELCOME TO PISCES CONSTELATION</p>
                            <h2>Start your Learning Journey Today!</h2>
                            <p className='lessonsTxt'>Set your navigation for the first planet and<br /> discover your potential</p>
                        </Col>
                        <Col md={7} className="plr0 lessonsScrollBoxs" id='lessonRow'>
                            {items}
                        </Col>
                        <div className='arrowDiv'>
                            <div className='arrowDivCh'>
                                <div className='arrow-left arrow' onClick={btnClickL}><i className="bi bi-chevron-left"></i></div>
                                <div className='arrow-right arrow' onClick={btnClickR}><i className="bi bi-chevron-right"></i></div>
                            </div>
                        </div>
                    </Row>
                    <div className='upgradeHeroRow'>
                        <Row className='container'>
                            <Col className='heroImgCol2'>
                                <img src={cillingAstronaut} alt="" />
                            </Col>
                            <Col>
                                <div className='explorationBoxDiv'>
                                    <p className='welcomeTxt'>EXPLORATION OF THE NEW CONSTELLATION BEGINS SOON</p>
                                    <h2>Upgrade your Pilot and prepare for the Journey</h2>
                                    <p className='lessonsTxt'>Are you ready to claim your title as the best idea maker in the EU?</p>
                                    <Row className='lessonsInfoRow'>
                                        <Col>
                                            <p>7</p>
                                            <p>Lessons</p>
                                        </Col>
                                        <Col>
                                            <p>70</p>
                                            <p>Quiz Questions</p>
                                        </Col>
                                        <Col>
                                            <div className='plusPoints'>+4500 XP</div>
                                        </Col>
                                    </Row>
                                    <div className="buttonWrapperMaxp" onClick={() => navigate("/course")}>
                                        <div className="button2Maxp">
                                            START YOUR JOURNEY
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className='bonusPointsSec'>
                    <Row className='bonusPointsRow container'>
                        <Col className='bonusPointsCol1'>
                            <div className='plusPoints'>+50 XP</div>
                            <img src={bonusPoints} alt="" />
                        </Col>
                        <Col>
                            <h2>Register today and get an extra 50 XP</h2>
                            <p>Sometimes all you need is to start. If you show courage and willingness to start navigating your spaceship through constellations today, we will reward you with a bonus for your journey.</p>
                            <div className="buttonWrapperMaxp" onClick={bonusPointsSecSignUp}>
                                <div className="button2Maxp">
                                    SIGN UP
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className='faqSec'>
                    <Row className='faqRow container'>
                        <Col>
                            <h2>Frequently Asked<br />
                                Questions</h2>
                            <p className='faqTxt'>Are you encountering challenges in your navigation through MAXP?<br />
                                Here you will find a way through.</p>
                            <div className="buttonWrapperMaxp" onClick={() => navigate("/faq")}>
                                <div className="button2Maxp">
                                    SEE ALL
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Accordion defaultActiveKey="" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='firstAcc'>What do MAXP Courses include?</Accordion.Header>
                                    <Accordion.Body>
                                        MAXP Universe will lead you through galaxies, constellations, and planets that will show and prepare you in a game-like setting for your career of the future.<br /><br />
                                        Currently, we're focusing on entrepreneurs, but we'll be adding more options soon. In the MAXP Universe, each career is represented by a galaxy. Within each galaxy, you'll find various courses, which are like constellations. Each course is made up of lessons, or planets. These planets contain a combination of educational content, videos, texts, and hands-on activities to help you fully understand and prepare for your future career.
                                        Get ready to embark on an exciting journey through the MAXP Universe!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How do I take a MAXP course?</Accordion.Header>
                                    <Accordion.Body>
                                        No login is required to visit the first planet Alzubra of the Pisces constellation of Galaxy Entrepreneur I. For further planets, constellations, and galaxies, login will be needed.<br /><br />
                                        After you enroll in MAXP you can start roaming the entire universe and find the galaxies that interest you. You have all the time in the world to complete it that is really up to you. You can also start whenever you want.<br /><br />
                                        Start enjoying the content and don't forget that an important part of your experience is also the MAXP community.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Troubleshooting Login Issues</Accordion.Header>
                                    <Accordion.Body>
                                        If you need help logging into your MAXP account, we can help!<br />
                                        Learn how to sign up for a MAXP account!<br />
                                        To start your learning experience now, we can explain how you sign up for MAXP, and gain access on a desktop or a laptop.<br /><br />
                                        <p style={{ textDecoration: 'underline' }}>Sign up with an email and password</p>
                                        <ol>
                                            <li>Click Sign up at the top right of your desktop or laptop browser. Enter your first name, last name, email address, and unique password (between 8 and 70 characters)</li>
                                            <li>Click Sign up</li>
                                        </ol>
                                        <p style={{ textDecoration: 'underline' }}> Log in with an email and password</p>
                                        <ol>
                                            <li>Click Log in at the top right of your desktop or laptop browser</li>
                                            <li>Enter the credentials you used to sign up</li>
                                            <li>Click Log in</li>
                                        </ol>
                                        <p style={{ textDecoration: 'underline' }}>Login with Facebook or Google</p>
                                        <ol>
                                            <li>If you have previously signed up for Udemy using Facebook or Google option, click login at the top right of your browser.</li>
                                            <li>Select Continue with Facebook or Google.</li>
                                            <li>Follow the prompts to complete the sign-in process.</li>
                                        </ol>
                                        <p style={{ textDecoration: 'underline' }}>How to log out of your MAXP account</p>
                                        <ol>
                                            <li>Move your cursor to your initials or profile image at the top right of the page</li>
                                            <li>Click Log out in the dropdown menu</li>
                                        </ol>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className='lastAcc'>Forgot your password?</Accordion.Header>
                                    <Accordion.Body>
                                        <ol>
                                            <li>Click Forgot password under the ‘Password’ box</li>
                                            <li>Enter your email address</li>
                                            <li>Click Reset password</li>
                                            <li>You can see the Check your inbox pop-up</li>
                                            <li>If you don’t receive an email, click the Resend Email button</li>
                                            <li>In your email click on the link provided</li>
                                            <li>Reset your Password page will open</li>
                                            <li>Enter a new password then confirm the new password</li>
                                            <li>Click on Change Password</li>
                                        </ol>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </section>
                <section className='testimonialsSec'>
                    <CardGroup id='testimonialsRow' className='testimonialsRow container'>
                        <Card>
                            <Card.Body className='testimonCol'>
                                <img className='quPng' src={qu} alt='' />
                                <p className='testimonText'>I took the course and was impressed by the unique approach to learning. The graphics are top-notch and kept me engaged the entire time.</p>
                                <h4 className='testimonAutor'><img src={serbiaFlag} alt='' /> Aleksandar</h4>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className='testimonCol'>
                                <img className='quPng' src={qu} alt='' />
                                <p className='testimonText'>As a student, I found the course content to be both informative and entertaining. The animations added a fun element to the learning experience.</p>
                                <h4 className='testimonAutor'><img src={sloveniaFlag} alt='' /> Anja</h4>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className='testimonCol'>
                                <img className='quPng' src={qu} alt='' />
                                <p className='testimonText'>I was skeptical about online courses, but this one exceeded my expectations. The realistic images and easy-to-follow content made it an enjoyable experience.</p>
                                <h4 className='testimonAutor'><img src={czechFlag} alt='' /> Tomas</h4>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className='testimonCol'>
                                <img className='quPng' src={qu} alt='' />
                                <p className='testimonText'>The well-organized platform made navigating the course a breeze. The gamified aspect added a competitive edge that kept me motivated throughout.</p>
                                <h4 className='testimonAutor'><img src={romaniaFlag} alt='' /> Gabriela</h4>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className='testimonCol'>
                                <img className='quPng' src={qu} alt='' />
                                <p className='testimonText'>The dark background and gamified learning path caught my attention immediately. I was not disappointed. It was both educational and enjoyable.</p>
                                <h4 className='testimonAutor'><img src={serbiaFlag} alt='' /> Nikola</h4>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className='testimonCol'>
                                <img className='quPng' src={qu} alt='' />
                                <p className='testimonText'>The realistic images and well-organized content made this online course a great experience. I would take it again in a heartbeat. </p>
                                <h4 className='testimonAutor'><img src={czechFlag} alt='' /> Jana</h4>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                    <div className='arrowDivTest'>
                        <div className='arrowDivCh'>
                            <div className='arrow-left arrow' onClick={testbtnClickL}><i className="bi bi-chevron-left"></i></div>
                            <div className='arrow-right arrow' onClick={testbtnClickR}><i className="bi bi-chevron-right"></i></div>
                        </div>
                    </div>
                </section>
                <section className='erasmusSec'>
                    <Row className='erasmusRow container'>
                        <Col>
                            <img src={erasmusFlag} alt="" />
                        </Col>
                    </Row>
                </section>
            </div>
        </>
    );
}

export default Home;