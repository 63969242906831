import React, { useState } from "react";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import "../css/header.css";
import logo4 from '../img/logo4.png';
import { useNavigate, NavLink, Link, useLocation } from 'react-router-dom';
import axios from "axios";
import { useQuery } from "react-query";
import LoginModal from "../modals/LoginModal";
import LoggedInModal from "../modals/LoggedInModal";
import Resend from "../modals/Resend";
import SignUpModal from "../modals/SignUpModal";
import { useEffect } from "react";
import CookieModal from '../modals/CookieModal';
import Cookies from "js-cookie";

function Header(props) {

    let location = useLocation();
    let navigate = useNavigate();

    // Form Select - Select language
    const [val, setVal] = useState();

    // LOGIN - REGISTRATION PopUp modal state
    const [showLog, setShowLog] = useState(false);

    const handleCloseLog = () => setShowLog(false);
    const handleShowLog = () => setShowLog(true);

    const [showReg, setShowReg] = useState(false);

    const handleCloseReg = () => setShowReg(false);
    const handleShowReg = () => setShowReg(true);
    // End LOGIN - REGISTRATION PopUp modal state

    //RESEND PopUp modal
    const [showResend, setShowResend] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const handleCloseResend = () => setShowResend(false);
    const handleShowResend = () => setShowResend(true);
    //END RESEND PopUp modal

    //LOGGEDIN PopUp modal
    const [showLoggedin, setShowLoggedin] = useState(false);

    const handleCloseLoggedin = () => setShowLoggedin(false);
    const handleShowLoggedin = () => setShowLoggedin(true);
    //END LOGGEDIN PopUp modal

    //COOKIE PopUp modal
    const [showCookie, setShowCookie] = useState(false);

    const handleCloseCookie = () => setShowCookie(false);
    const handleShowCookie = () => setShowCookie(true);
    //END COOKIE PopUp modal

    // Scroll Header
    useEffect(() => {
        window.onscroll = function () { scrollHeader() };
        function scrollHeader() {
            if (document.documentElement.scrollTop >= 150) {
                document.querySelector('.mainHeader').classList.add('scroll');
                return;
            } else {
                document.querySelector('.mainHeader').classList.remove('scroll');
            }
        }
    }, []);
    //END Scroll Header

    // Get user IP address
    const [ipData, setIpData] = useState('');

    useEffect(() => {
        //const { isLoading, error, data } = useQuery('getIP', () =>
        axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=bbf3ea16fba64d85a39c414e10629820', {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((callback) => {
            setIpData(callback.data);

            setTimeout(function () {
                const ipDataCookie = Cookies.get('ipData');
                if (!ipDataCookie) {
                    handleShowCookie();
                }
            }, 2000);
        });
    }, [location]);
    // END Get user IP address


    // setTimeout(function () {
    //     localStorage.setItem("ipData", JSON.stringify(callback.data));
    //     const ipDataGet = localStorage.getItem("ipData");
    //     const ipData = JSON.parse(ipDataGet);
    //     console.log(ipData)
    // }, 3000);

    return (
        <>
            {/* HEADER */}
            <Navbar className="mainHeader" expand="lg">
                <Container className="headerContainer">
                    <Link to='/'>
                        <img src={logo4} alt="MAXP - Home" className="d-inline-block align-top" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink className={({ isActive }) => (isActive ? "link-active nav-link" : "nav-link")} to={"/course"}>Course</NavLink>
                            <NavLink className={({ isActive }) => (isActive ? "link-active nav-link" : "nav-link")} to={"/community"}>Community</NavLink>
                            <NavLink className={({ isActive }) => (isActive ? "link-active nav-link" : "nav-link")} to={"/faq"}>FAQ</NavLink>
                        </Nav>
                        <Form.Select value={val} onChange={(e) => setVal(e.target.value)}>
                            <option className="langOption" value="1">English (US)</option>
                            <option className="langOption" value="2">English2 (US)</option>
                            <option className="langOption" value="3">English3 (US)</option>
                        </Form.Select >
                        <div className="buttonWrapperMaxp loginBtn" onClick={handleShowLog}>
                            <div className="buttonSimple-3Maxp">
                                LOGIN
                            </div>
                        </div>
                        <div className="buttonWrapperMaxp signupBtn" onClick={handleShowReg}>
                            <div className="button2Maxp">
                                SIGN UP
                            </div>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* END HEADER */}

            {/* LOGIN PopUp modal */}
            <LoginModal
                show={showLog}
                close={handleCloseLog}
                onSignUp={handleShowReg}
                onSuccess={() => {
                    handleShowLoggedin();
                    setTimeout(function () {
                        navigate("/user-dashboard", { state: "spacePort" });
                        handleCloseLoggedin();
                        props.loggedInUser();
                    }, 3000);
                }} />
            {/* END LOGIN PopUp modal */}

            {/* REGISTRATION PopUp modal */}
            <SignUpModal className="loginPopup regPopup"
                show={showReg}
                close={handleCloseReg}
                onLogin={handleShowLog}
                onSuccess={(email) => {
                    handleShowResend();
                    setUserEmail(email);
                }}
                ipData={ipData}
            />
            {/* END REGISTRATION PopUp modal */}

            {/* RESEND PopUp modal */}
            <Resend
                email={userEmail}
                show={showResend}
                close={handleCloseResend}
            />
            {/* END RESEND PopUp modal */}

            {/* LOGGEDIN TRUE PopUp modal */}
            <LoggedInModal
                show={showLoggedin}
                close={handleCloseLoggedin}
            />
            {/* END LOGGEDIN TRUE PopUp modal */}
            {/*COOKIE PopUp modal*/}
            <CookieModal
                show={showCookie}
                close={handleCloseCookie}
                ipData={ipData}
            />
            {/*END COOKIE PopUp modal*/}
        </ >

    );
}

export default Header;