import { decrypt } from 'n-krypta';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Intro.css';
import introHeader from '../img/introHeader.jpg';

function Intro() {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <div className='introPage'>
                <div className='container'>
                    <div className='introBox'>
                        <img src={introHeader} alt='' />
                        <h4 className='introBoxTitle'>Welcome to the MAXP Entrepreneur I. course!</h4>
                        <p className='introBoxTxt'>If that's alright we'll call you pilots! Why? Because we will be exploring galaxies and constellations together.
                            <br /><br />
                            In this course, you will be the pilot of a spaceship that represents your entrepreneurial skills. Your mission is to navigate through various lessons, represented by planets, and complete the course. The entire journey represents your career path in the MAXP universe.
                            <br /><br />
                            To begin, you don't need to register. However, to continue your journey through the course, you will need to create an account and complete a short assessment test. This will allow you to track your progress and see what you have learned.
                            <br /><br />
                            Why are we building spaceships? Because entrepreneurship is about taking control of your life and being responsible for getting things done. In today's world, it's important to have entrepreneurial skills – to be self-aware and self-directed, to identify and solve problems in innovative ways.
                            <br /><br />
                            That's why we created this course – to help you learn more about yourself and your piloting skills. We'll cover how to recognize and overcome your barriers, as well as how to effectively communicate and present your ideas. And don't worry, we won't neglect the importance of resources – you may be surprised by how many you already have at your disposal.
                            <br /><br />
                            Are you ready pilots? Let the fun begin!</p>
                        <div className="buttonWrapperMaxp" onClick={() => navigate('/')}>
                            <div className="button2Maxp">
                                START EXPLORING UNIVERSE
                                <i className="bi bi-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;