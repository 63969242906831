import { Accordion, ListGroup } from 'react-bootstrap';
import '../css/Faq.css';

function TechnicalQuestionsFaq() {

    return (
        <>
            <Accordion className='faqAccordian' defaultActiveKey="" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Troubleshooting Login Issues</Accordion.Header>
                    <Accordion.Body>
                        If you need help logging into your MAXP account, we can help!<br />
                        Learn how to sign up for a MAXP account!<br />
                        To start your learning experience now, we can explain how you sign up for MAXP, and gain access on a desktop or a laptop.<br /><br />
                        <p style={{ textDecoration: 'underline' }}>Sign up with an email and password</p>
                        <ol>
                            <li>Click Sign up at the top right of your desktop or laptop browser. Enter your first name, last name, email address, and unique password (between 8 and 70 characters)</li>
                            <li>Click Sign up</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}> Log in with an email and password</p>
                        <ol>
                            <li>Click Log in at the top right of your desktop or laptop browser</li>
                            <li>Enter the credentials you used to sign up</li>
                            <li>Click Log in</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>Login with Facebook or Google</p>
                        <ol>
                            <li>If you have previously signed up for Udemy using Facebook or Google option, click login at the top right of your browser.</li>
                            <li>Select Continue with Facebook or Google.</li>
                            <li>Follow the prompts to complete the sign-in process.</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>How to log out of your MAXP account</p>
                        <ol>
                            <li>Move your cursor to your initials or profile image at the top right of the page</li>
                            <li>Click Log out in the dropdown menu</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>Forgot password</p>
                        <ol>
                            <li>Click Forgot password under the ‘Password’ box</li>
                            <li>Enter your email address</li>
                            <li>Click Reset password</li>
                            <li>You can see the Check your inbox pop-up</li>
                            <li>If you don’t receive an email, click the Resend Email button</li>
                            <li>In your email click on the link provided</li>
                            <li>Reset your Password page will open</li>
                            <li>Enter a new password then confirm the new password</li>
                            <li>Click on Change Password</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How to Troubleshoot Audio & Video Issues (on a Browser)</Accordion.Header>
                    <Accordion.Body>
                        If you are having problems with viewing a video or hearing audio while attempting to watch a course on a browser, here are several ways to troubleshoot and resolve these problems.
                        <br /><br />
                        <p style={{ textDecoration: 'underline' }} >Check your internet connection</p>
                        <ol>
                            <li>Check your internet connection, a minimum speed of 5 Mbps is required to watch any video in the MAXP course</li>
                            <li>Try watching another lecture or course (if you are already signed in try The course preview feature)</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How to Download Your Certificate of Completion (on a Browser)</Accordion.Header>
                    <Accordion.Body>
                        <ol>
                            <li>When all the curriculum items of a course have been completed, at the bottom of the last lesson you will see the button ‘Congratulations: you have earned the Certificate’.</li>
                            <li>When you click on that button you will see the preview of your Diploma.</li>
                            <li>Click on the ‘Download’ button to download it to your computer.</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>I finished my course but I still can’t access my certificate of completion. How come?</p>
                        In order to receive your certificate of completion, each curriculum item must be marked as complete. Double-check the course curriculum in the course player to make sure each lecture and course item has a checkmark beside it and is marked as complete.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>How to change the name on your certificate of completion</Accordion.Header>
                    <Accordion.Body>
                        Certificates of completion are generated using whatever name you entered when you created your Udemy account. Please follow the steps below if you need to change the certificate’s name.
                        <br /><br />
                        <ol>
                            <li>Go to the User Dashboard page</li>
                            <li>Click on the Edit profile button</li>
                            <li>Change the wrong information</li>
                            <li>Click the Update button</li>
                            <li>Go to the last lesson page</li>
                            <li>Generate the Certificate again</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>How to change the language of your certificate of completion</p>
                        Certificates of completion are generated in the same language that is set for your account. If you need to change the language of your certificate,  please follow these steps:
                        <br /><br />
                        <ol>
                            <li>While accessing MAXP via a web browser, navigate to your certificate and click on Update your certificate as shown above.</li>
                            <li>Select the language you wish to change your certificate to. A list of the languages we support for accounts can be viewed here.</li>
                            <li>Click Save Changes.</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>MAXP Features</Accordion.Header>
                    <Accordion.Body>
                        On the MAXP Platform of courses here are the following features:
                        <br /><br />
                        <ol>
                            <li>Video Introduction</li>
                            <li>Text Lectures</li>
                            <li>Quizzes</li>
                            <li>Practices</li>
                            <li>Download Certificates of Completion</li>
                            <li>Student feedback Review</li>
                            <li>See feedback Review</li>
                            <li>Competition experience</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>System Requirements</Accordion.Header>
                    <Accordion.Body>
                        <p style={{ textDecoration: 'underline' }}>Minimum system requirements</p>
                        <ol>
                            <li>Browser: The latest Chrome, Firefox, Safari, Edge, or Opera for desktop/laptop</li>
                            <li>A broadband connection with a minimum speed of 5 Mbps</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>PC specific requirements</p>
                        <ol>
                            <li>Platform: Windows 8.1 or higher with the latest updates installed</li>
                            <li>RAM: 4GB or more</li>
                            <li>Video: Graphics output capability</li>
                            <li>Sound: Sound output capability</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>Mac specific requirements</p>
                        <ol>
                            <li>Platform: Mac OS X 10.12 or higher with the latest updates installed</li>
                            <li>RAM: 4GB or more</li>
                            <li>Video: Graphics output capability</li>
                            <li>Sound: Sound output capability</li>
                        </ol>
                        <p style={{ textDecoration: 'underline' }}>Creating and Editing Your Profile</p>
                        By entering the MAXP website every user can create a profile by signing in and completing the information about themselves. Information that the user can input are:
                        <ul style={{ border: 'none' }}>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Email</li>
                            <li>Password</li>
                        </ul><br />
                        <p style={{ textDecoration: 'underline' }}>How to Edit Your Profile</p>
                        To edit your profile, move the cursor to your profile image or your initials at the top right of the page. In the drop-down menu click Edit profile option, and you will be taken to the User Dashboard page - section Edit profile.
                        To change the information you can Edit your first name, and last name, upload a photo and then click the Update button.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>How to Change Your Account’s Email Address (on a Browser)</Accordion.Header>
                    <Accordion.Body>
                        MAXP Course does not have the possibility to change the email address in the Edit profile page section. To change an email address you must sign in with a different email address.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>How to Check Your Account’s Email Address or if You Have Additional, MAXP Accounts</Accordion.Header>
                    <Accordion.Body>
                        To check your account’s email address you can:
                        <br /><br />
                        <ol>
                            <li>Go to the User Dashboard page</li>
                            <li>In the About section, you can see the email address you entered to sign in.</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>How to Change Your Language Preferences</Accordion.Header>
                    <Accordion.Body>
                        To change the Language Preferences go to the top right hand side of the page. The language button is next to the Login button.
                        Click on the EN drop-down and pick the preferred language.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>How to Resolve Multi-factor Authentication (MFA) Issues With Your Account</Accordion.Header>
                    <Accordion.Body>
                        MAXP course has multi-factor Authentication (MFA) over email.
                        <br /><br />
                        <ol>
                            <li>After signing in you will receive an email with a link.</li>
                            <li>Click on an email and you will be redirected to the MAXP website</li>
                            <li>If you don’t receive an email, click the Resend Email button</li>
                            <li>In your email click on the link</li>
                            <li>Reset your Password page will open</li>
                            <li>Enter a new password and confirm the new password</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Can I correct my answers after I get feedback?</Accordion.Header>
                    <Accordion.Body>
                        After submitting the answer you are not able to change the answers given.
                        <br /><br />
                        <p style={{ textDecoration: 'underline' }}>Common reasons for mistaking that an account is compromised </p>
                        <br />
                        I can't login: is it possible you’ve forgotten your password? Or that you’re entering the wrong email? You can initiate a password reset as explained in the question above.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default TechnicalQuestionsFaq;