import { decrypt, encrypt } from 'n-krypta';
import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonAlzubra.css';
import alzubraHeaderImg from '../lessons_img/lessonAlzubra_1/alzubraSmallBg.png';
import lessonImg1 from '../lessons_img/lessonAlzubra_1/img1.jpg';
import lessonImg2 from '../lessons_img/lessonAlzubra_1/img2.jpg';
import lessonImg3 from '../lessons_img/lessonAlzubra_1/img3.jpg';
import lessonImg4 from '../lessons_img/lessonAlzubra_1/img4.jpg';
import lessonImg5 from '../lessons_img/lessonAlzubra_1/img5.jpg';
import lessonImg6 from '../lessons_img/lessonAlzubra_1/img6.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import SelfAssesmentTest1 from '../../modals/SelfAssesmentTest1';
import $ from 'jquery';
import axios from 'axios';

function LessonsAlzubra_1() {

    const location = useLocation();
    const navigate = useNavigate();

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice, setShowLessonBtnPractice] = useState('none');
    const [disabledBtnPractice, setDisableBtnPractice] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);
    //Self Assesment Test PopUp
    const [showSat, setShowSat] = useState(false);

    const handleCloseSat = () => setShowSat(false);
    const handleShowSat = () => setShowSat(true);
    //END Self Assesment Test PopUp

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {

        if (!encryptedObject) {
            handleCloseSat();
        } else {
            const encryptedObjectCh = localStorage.getItem("userNextChapter");
            const secret = localStorage.getItem("hash");
            const decryptedUserData = decrypt(encryptedObject, secret);
            const decryptedNextChap = decrypt(encryptedObjectCh, secret);
            setUserData(decryptedUserData);
        }
    }, []);

    const [practice1A_1, setPractice1A_1] = useState('');
    const [practice1A_2, setPractice1A_2] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    useEffect(() => {
        const unregisteredUser = localStorage.getItem("unregisteredUserData");

        if (!encryptedObject && !unregisteredUser) {
            handleCloseSat();
        } else {

            if (userData.userLessonId >= 1 && userData.userPracticeId >= 1) {
                setShowLessonBtnNext('');
                setDisableBtnNext('');
            } else {
                setShowLessonBtnNext('none');
                setDisableBtnNext('disabledBtn');
            }

            switch (userData.selfAssTest) {
                case '0':
                    console.log("DATA: " + userData.selfAssTest)
                    setTimeout(function () {
                        handleShowSat();
                    }, 1000);
                    break;
                case '1':
                    handleCloseSat();
                    break;
                case '2':
                    handleCloseSat();
                    break;
            }
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '1';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    function submit(event) {
        event.preventDefault();
        //event.stopPropagation();
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = location.pathname.split('-')[2];
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('.practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1A_1, practice1A_2];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setShowLessonBtnPractice('none');
                setDisableBtnPractice('disabledBtn');
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='lessonAlzubraPage'>
                <div className='lessonAlzubraHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Alzubra' >Alzubra</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={alzubraHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO ALZUBRA</p>
                    <h2 className='lessHeaderTitle'>Know Yourself and
                        work on yourself</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <h4 className='lessTitle'>Welcome pilot! We are glad you are here!</h4>
                        <p className='lessTxt'>The first stop in the Pisces constellation will be a planet called Alzubra. There are many objects in the universe but the most important celestial body is you! Because you are leading the direction.
                            We'll focus on what kind of pilot you are or you want to be, what your strengths and weaknesses are, where you find the motivation to fly, or why you feel afraid to take off.</p>
                        <img className='lessImg' src={lessonImg1} alt='' />
                        <h4 className='lessTitle'>Know yourself</h4>
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>To know thyself is the beginning of wisdom.</h4>
                                <p>Socrates</p>
                            </div>
                        </div>
                        <h4 className='lessSubtitle'>Know yourself before you wreck yourself!</h4>
                        <p className='lessTxt'>When you truly know yourself you have the power, the fuel for your spaceship and you are basically unstoppable.</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Starting any project at any stage of your pilot life requires knowing yourself. If you know who you are, your strengths, and what you enjoy, you are more likely to tackle an idea that is best suited to your skills and interests and is less sensitive to your shortcomings.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>When you know yourself you'll be attracting fellow pilots and the right flight crew to be with you on this journey through the universe. Together you can create amazing things. You should surround yourself with people that complement your strengths and fill your gaps.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Building a spaceship requires confidence in yourself. As a pilot, you will have no place and no one to hide behind. Knowing yourself is the key to confidence. Being authentic and genuine gets the best from others too. To be an effective and respected pilot they must see that you like who you are.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Make better spaceship decisions by playing to your strengths. Know when to say no without feeling guilty. Knowing your limits and not taking on tasks that you can't deliver or are not important to you is a way how to fly and exist in the universe.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Remember pilots, you won't improve if you don't know what needs fixing. Here please be careful to not get too stuck focusing on what's lacking. Always look at things as a challenge. For Example- “I can't speak that language”. Great, what an opportunity to learn. Also, you will never know it all. Those who say they do, don't anyway. Every pilot and every spaceship needs continuous improvement.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Understanding yourself will help you set the right priorities for self-improvement, including working on your health, balancing your life with obligations, responsibilities, and relaxation, changing bad habits, and joining peer groups.</p>
                            </div>
                            <div className='lessTaskBox'>
                                <div className='lessTaskDiv'>
                                    <i className="bi bi-list-task"></i>
                                    <p className='lessTxt'>Task: get to know yourself better - Pilots there are several ways to get to know yourself. It's really important to be self-aware and ask yourself these questions.</p>
                                </div>
                                <Accordion defaultActiveKey="" flush className='lessAcc'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className='lessTaskAcc'>Category goals and fears</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>What are my short-term goals?</li>
                                                <li>What are my long-term goals?</li>
                                                <li>What am I worried about?</li>
                                                <li>What new activities am I interested in or willing to try?</li>
                                                <li>What do I like to do for fun?</li>
                                                <li>What am I passionate about?</li>
                                                <li>If I wasn’t afraid, I would</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header className='lessTaskAcc'>Category strengths and weaknesses</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>What are my strengths?</li>
                                                <li>What do I know I should work on?</li>
                                                <li>What do I like about myself?</li>
                                                <li>What is my biggest criticism of myself?</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header className='lessTaskAcc'>Category values and beliefs</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>What matters most to me?</li>
                                                <li>Who is my role model and why?</li>
                                                <li>What are my values?</li>
                                                <li>What do I believe in (could be politics, religion, or social issues)?</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header className='lessTaskAcc'>Me/ Pilots Category</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>What is my proudest accomplishment?</li>
                                                <li>What is my biggest failure?</li>
                                                <li>Am I a night owl or an early bird? How can I arrange my life to better suit this part of my nature?</li>
                                                <li>Am I an introvert or an extrovert? Am I energized being around others or being by myself?</li>
                                                <li>What am I grateful for?</li>
                                                <li>I know I’m stressed when I</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className='lessAccNote'>
                                    <p>You don't have to answer all of these at the same time. Take your time so you can explore the answer. This task is not a race. These questions are here mainly for you the pilots. Discover yourself and be aware that it's a process. It will take thinking, talking, writing, and doing. Keeping a journal along the way helps in allowing you to get to know yourself too.</p>
                                </div>
                            </div>
                        </div>
                        <h4 className='lessSubtitle'>Barriers</h4>
                        <p className='lessTxt'>To know yourself also means to focus on what possible barriers you have. Now it's time to name the things that are holding you back from flying. Everyone has barriers that they are trying to deal with. Here are a few barriers pilots have in common.</p>
                        <img className='lessImg' src={lessonImg2} alt='' />
                        <h4 className='lessSubtitle'>Fear</h4>
                        <p className='lessTxt'>Famous fear. Our natural, powerful, and primitive human emotion. How do we overcome fear? There are a few ways, but the most important one is exposure. Once you repeatedly expose yourself to fear, it will lead to familiarity, which can ultimately reduce your fear response. In essence, by making fear more familiar to your life experiences, you start to slowly minimize the fear response. As you know, humans would not live long without fear and other human instincts. For you as a pilot, it is important to ask yourself what fears you have, how fear affects you, and how to befriend them.</p>
                        <img className='lessImg' src={lessonImg3} alt='' />
                        <h4 className='lessSubtitle'>Lack of time</h4>
                        <p className='lessTxt'>Time management can be one of the greatest barriers. Luckily, while building your spaceship you will learn how to prioritize things that both make you happy and are important to your future.
                            A few solutions here is to be true to yourself while making a to-do list. Set your personal goals and then start prioritizing. Need a break? Take it. I can only finish three chapters instead of five in a week. No worries. Don't take too much on your shoulders and don't forget to relax. Multitasking is great sometimes, but everybody needs a break.</p>
                        <h4 className='lessSubtitle'>Lack of experience</h4>
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>There's no such thing as lack of confidence. It is the lack of experience that dominates your thoughts.</h4>
                                <p>Anoir Ou-Chad</p>
                            </div>
                        </div>
                        <p className='lessTxt'>There is no other way to solve this problem than to put yourself out there and go for the experience. Experience comes with time. Basically, every day if we are open to acknowledging it.
                            The lack of experience can be solved by seeking advice from someone experienced, who was dealing with the same situation.</p>
                        <img className='lessImg' src={lessonImg4} alt='' />
                        <h4 className='lessSubtitle'>Lack of money</h4>
                        <p className='lessTxt'>Lack of money as a barrier could seem like that's the end. But remember if there is a will, there is a way. There are many investors, grants, and platforms that can support your visions and ideas.
                            It is also important to remember that you don't need money for your first spaceship design. What you need is to come up with an idea and figure out how to word it. Then verify the idea with your target group and ask for advice. All these steps can be done without money. Of course, at some stage you might need money to start designing your spaceship, but definitely not during the whole process. Don't let the lack of money stop you from creating.</p>
                        <img className='lessImg' src={lessonImg5} alt='' />
                        <h4 className='lessSubtitle'>Lack of knowledge</h4>
                        <p className='lessTxt'>The barrier of lack of knowledge can be terrifying. But you as a pilot hold all the chances to change that. Even when you have the feeling that the school system is against you, don't forget that you have so many other options on how to reach knowledge outside of the classroom. This is the process you should never stop. Wherever you are, always seek knowledge and ask for more information. Curiosity and knowledge can create amazing things together.</p>
                        <img className='lessImg' src={lessonImg6} alt='' />
                        <h4 className='lessSubtitle'>Lack of Luck</h4>
                        <p className='lessTxt'>Every project needs a bit of luck to succeed. But you can meet good fortune halfway, right? Dr. James H. Austing describes four kinds of luck:<br /><br />

                            The first one is Blind luck. That's pure luck, a fortune that we experience as a miracle, something that shows up completely out of the blue. There is no way to get blind luck on your side, but when it strikes - make sure you don't squander it. If you win a lottery, invest the money in your spaceship rather than in sports cars or yachts.
                            <br /><br />
                            The second one is Luck from motion and it comes from constant action. Luck comes from hard work and persistence. The more spaceship plans and designs you create, the more people you engage with - the better the chance that the right investor will notice you.
                            <br /><br />
                            The third one is Luck in Preparation. Luck comes from being prepared to act on an opportunity or having the ability to spot an opportunity. For example, if you know how to fly a spaceship you know what works and what doesn't.
                            <br /><br />
                            The last one is The Luck Unique To You. As you edge closer to pilot status, you’ll make a name for yourself because of how you operate. Your special and distinctive traits will help your reputation to grow. Maybe your personality is friendly and great to work with. Or, perhaps you can be bossy but you always get the job done. The luck unique to you basically reflects yourself and your investment in your designs and constructions.</p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 1.A</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>As discussed above, now it's time for our first practice. Answer these questions:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyAlz'>
                            <Form name='Practice 1.A' onSubmit={submit} practiceid='1'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>What is your proudest accomplishment and why?</li>
                                        </ul>
                                        <Form.Control inputques='What is your proudest accomplishment and why?' id='setPractice1A_1' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '192px', resize: 'none' }} required onChange={(e) => {
                                            setPractice1A_1(e.target.value); setShowLessonBtnPractice(''); setDisableBtnPractice('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv' style={{ marginBottom: '0' }}>
                                        <ul>
                                            <li className='practiceQuestion'>Choose one of the mentioned barriers and tell us – how have you successfully overcome that barrier in the past?</li>
                                        </ul>
                                        <Form.Control inputques='Choose one of the mentioned barriers and tell us – how have you successfully overcome that barrier in the past?' id='setPractice1A_2' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '192px', resize: 'none' }} required onChange={(e) => {
                                            setPractice1A_2(e.target.value); setShowLessonBtnPractice(''); setDisableBtnPractice('');
                                        }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac1A' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice} onClick={() => { document.getElementById('submitPrac1A').click() }} style={{ pointerEvents: btnDisablePractice }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonAlzubraNextCh'>
                        <div className='lessonContainer'>
                            <img src={alzubraHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessAlzNextChTitle'>First Chapter of Alzubra</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Alzubra-2')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Alzubra-2'}>
                                                <p className='chapterNum '>Chapter 2</p>
                                                <h4 className='chapterTitle '>HOW DO YOU LEARN AND WHEN IS IT OVER?</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Alzubra-2'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <SelfAssesmentTest1
                show={showSat}
                close={handleCloseSat}
                userId={userData.id}
            />
        </>
    );
}

export default LessonsAlzubra_1;