import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonMoranon.css';
import moranonHeaderImg from '../lessons_img/lessonMoranon_1/moranonSmallBg.png';
import picture1a from '../lessons_img/lessonMoranon_1/picture1a.jpg';
import picture2a from '../lessons_img/lessonMoranon_1/picture2a.jpg';
import picture3a from '../lessons_img/lessonMoranon_1/picture3a.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import { Col, Form, Row } from 'react-bootstrap';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';

function LessonMoranon_1() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 5 && userData.userPracticeId >= 16) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '5';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '5';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '16') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    return (
        <>
            <div className='lessonMoranonPage'>
                <div className='lessonMoranonHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Moranon' >Moranon</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={moranonHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO MORANON</p>
                    <h2 className='lessHeaderTitle'>Work with others</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <p className='lessTxt'>Hello pilot, you have just landed on the planet Moranor. It's a big planet covered in clouds. But once you explore the environment of this planet the sky will definitely clear up. We will focus on finding resources whether it will be financial support, skill, information, or experience. All of these are helping us transform ideas into reality.</p>
                        <h4 className="lessTitle">Identifying resources</h4>
                        <img src={picture1a} className='lessImg' alt='' />
                        <p className='lessTxt'>What resources exist? There are various types of resources that we already have, we can gain. Here are a few of them</p>
                        <h4 className='lessSubtitle'>Types of resources</h4>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>People</h4>People are probably the most important resource and therefore you should be surrounded by the ones that motivate you and you can learn from each other, cooperate and create together. People can be replaced by technology in some routines but the creativity, experiences, and wisdom of people cannot.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Skills</h4>Each of us has some talents, everyone is talented, somebody is a brainiac, somebody can create miracles with their bare hands, somebody pays attention to detail and others are impossibly creative. Everyone is good at something. Make sure you know what you are good at and try to get the best at it.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Experience</h4>It is hard to skip the years to gain more experience, it is something that you gain over time personally, but you can get some by partnering with someone you trust that has the experience or attending a course, or an event that can propel you ahead of your peers.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Information</h4>Nowadays, information can make you a powerful ally to any project, team, group, or nation. Just be careful not to abuse it, you have to be always mindful of how you got the information. Can it become public, or should it stay private? Especially with private things, make sure you are not hurting anyone, and always imagine how you would feel if someone would have the same information about you. What would you want them to do? When it comes to working with academic information, always consider - who wrote the study and who paid for it? Why? What is their intention? Always try to think critically about any information you receive.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Money</h4>It is said that cash is king and that money rules the world, but it's certainly not everything. It is harder to make money than to spend it and therefore you have to think twice about what you invest your money in - is it going to be a concert, a night out with your friends, a new game, an educational course?</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className="lessTxt"><h4 className='lessSubtitle'>Time</h4>Is the most precious resource we have. And good time management is the best way to not waste it. Time management is a very smart technique of organizing, planning, and exercising conscious control over your value of time. Remember to use your value of time wisely as time is what we want the most but what we use the worst. Try to do more in less time, make your work fulfilling and meaningful,  try to end overthinking and indecisiveness, and most importantly remember to rest!</p>
                            </div>
                            <iframe style={{ marginBottom: "0" }} width="100%" height="423" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <img src={picture2a} className='lessImg' alt='' />
                        <p className='lessTxt'>You already have some resources on your own and they vary person by person. Life is not fair and it's the same with resources. But it doesn't matter what you start with, you can always aim and gain more. In each of the 6 categories we discussed, You can actively influence your outcomes. Three of them are the easiest to start with - people, time, and skills.
                            <br /><br />
                            Once you know the people you have around you are the right people that do not drag you down and are helping you to build your skills, then it's way easier to get any other resource. They can help you to find money through a better-paid job, or to develop your own project sooner than you would normally think. Same with experience and information.
                            <br /><br />
                            These resources are interconnected and it is only up to you when you start. The sooner you realize that and start working on yourself, the sooner you will have more skills, more experience, more people, information, and money than others that start later in their life.</p>
                        <img src={picture3a} className='lessImg' alt='' />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 5.A</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear pilot, now it's time to put theory into practice. Let's start with people.</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMoranon mb32'>
                            <Form name='Practice 5.A' onSubmit={submit} practiceid='16' id='16'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">What 2 positions do you aspire to be in? (whether as an owner, or employee) where would you find  two people that are in those positions and what 2 questions would you ask them?</li>
                                        </ul>
                                        <Form.Control inputques="What 2 positions do you aspire to be in? (whether as an owner, or employee) where would you find  two people that are in those positions and what 2 questions would you ask them?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">How would you approach them? Please draft your message in 300 characters including spaces.</li>
                                        </ul>
                                        <Form.Control inputques="How would you approach them? Please draft your message in 300 characters including spaces." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac5A' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac5A').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonMoranonNextCh'>
                        <div className='lessonContainer'>
                            <img src={moranonHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessMoranonNextChTitle'>First Chapter of Moranon</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Moranon-2')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Moranon-2'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>BUDGETING</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Moranon-2'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonMoranon_1;