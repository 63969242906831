import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Modal, NavDropdown } from "react-bootstrap";
import "../css/userHeader.css";
import "../css/main.css";
import logo4 from '../img/logo4.png';
import woodenMedal from '../img/woodenMedal.png';
import silverMedal from '../img/silverMedal.png';
import goldMedal from '../img/goldMedal.png';
import points from '../img/points.png';
import $ from 'jquery';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { decrypt } from "n-krypta";

function UserHeader(props) {

    const [userData, setUserData] = useState({});

    // LOCAL STORAGE USER DATA
    useEffect(() => {
        const encryptedObject = localStorage.getItem("userData");
        const encryptedHash = localStorage.getItem("hash");
        const decryptedString = decrypt(encryptedObject, encryptedHash);
        setUserData(decryptedString);
    }, []);
    //END LOCAL STORAGE USER DATA

    // const userFirstNameIni = userData.firstName.charAt(0);
    // const userLastNameIni = userData.lastName.charAt(0);

    // User Medal
    let userMedal = '';

    if (userData.userPoints < 163) {
        userMedal = woodenMedal;
    } else if (userData.userPoints >= 163 && userData.userPoints <= 324) {
        userMedal = silverMedal;
    } else if (userData.userPoints >= 325) {
        userMedal = goldMedal;
    }
    // END User Medal

    // User avatar
    // const [userAvatarImgTag, setUserAvatarImgTag] = useState('');

    // useEffect(() => {
    //     if (userData.userAvatar === '') {
    //         const userFirstNameIni = userData.firstName.charAt(0);
    //         const userLastNameIni = userData.lastName.charAt(0);
    //         setUserAvatarImgTag(<span className="userAvatarTxt">{userFirstNameIni + userLastNameIni}</span>);
    //     } else {
    //         setUserAvatarImgTag(<img className="userAvatarImg" src={userData.userAvatar} alt="Avatar" />);
    //     }
    // }, [userData]);


    if (userData.userAvatar === '') {
        const userFirstNameIni = userData.firstName.charAt(0);
        const userLastNameIni = userData.lastName.charAt(0);
        $('#userEdit').text(userFirstNameIni + userLastNameIni);
    } else {
        $('#userEdit').css({
            'background-image': 'url(' + userData.userAvatar + ')',
            'background-size': 'cover',
            'background-position': 'center center',
            'padding': '0',
            'border-radius': '50%',
            'width': '40px',
            'height': '40px'
        });
    }
    // END User avatar

    // Dropdown in header
    function dropDownClose() {
        $('.nav-item.dropdown').removeClass('show');
        $('.dropdown-toggle.nav-link').attr('aria-expanded', false);
        $('.dropdown-menu').removeClass('show');
        $('.userPoints  .headerDropdown.nav-item.dropdown').click(); //click to activate for next dropdown
    }
    // END Dropdown in header

    // Scroll Header
    $(document).scroll(function () {
        var value = $(document).scrollTop();

        if (value >= 150) {
            $(".userHeader").addClass("scroll");
            return;
        } else {
            $(".userHeader").removeClass("scroll");
        }
    });
    // END Scroll Header

    const cheDown = <i className="bi bi-chevron-down"></i>;

    return (
        <>
            {/* USERHEADER */}
            <Navbar className="userHeader" expand="lg">
                <Container className="headerContainer">
                    <Link to={{ pathname: "/" }} state={{ userData }}>
                        <img src={logo4} alt="MAXP - Home" className="d-inline-block align-top" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto userPoints">
                            <div className="userMedalDiv"><img src={userMedal} alt='' /> <span>Medal</span></div>
                            <div className="userPointsDiv"><img src={points} alt='' /><span>{userData.userPoints} Earned Points</span></div>

                            <NavDropdown title={cheDown} className="headerDropdown buttonSimple-3Maxp headerMenuDrop" id="menuEdit">
                                <div onMouseLeave={dropDownClose}>
                                    <NavLink className={({ isActive }) => (isActive ? "link-active dropdown-item" : "link dropdown-item")} to={"/course"} state={{ userData }}>Course</NavLink>
                                    <NavLink className={({ isActive }) => (isActive ? "link-active dropdown-item" : "link dropdown-item")} to={"/community"} state={{ userData }}>Community</NavLink>
                                    <NavLink className={({ isActive }) => (isActive ? "link-active dropdown-item" : "link dropdown-item")} to={"/faq"} state={{ userData }}>FAQ</NavLink>
                                </div>
                            </NavDropdown>
                            <NavDropdown title="" className="headerDropdown userEdit" id="userEdit" >
                                <div onMouseLeave={dropDownClose}>
                                    <NavLink className={({ isActive }) => (isActive ? "link-active dropdown-item" : "link dropdown-item")} to={"/user-dashboard"} state={{ userData }}>User Dashboard</NavLink>
                                    <NavLink className={({ isActive }) => (isActive ? "link-active dropdown-item" : "link dropdown-item")} to={"/edit-profile"} state={{ userData }}>Edit Profile</NavLink>
                                    <NavDropdown.Divider />
                                    <a className="dropdown-item logOutDropDown" onClick={() => { props.logOutUser() }}>Log Out</a>
                                </div>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* END USERHEADER */}
        </>
    );
}

export default UserHeader;