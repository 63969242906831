import axios from 'axios';
import { decrypt } from 'n-krypta';
import React, { useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import logoHeader from '../img/logoCertificate.png';

function FeedbackFormModal(props) {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [userData, setUserData] = useState([]);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        setUserData(decryptedUserData);
    }, []);

    const [ans1, setAns1] = useState('');
    const [ans2, setAns2] = useState('');
    const [ans3, setAns3] = useState('');
    const [ans4, setAns4] = useState('');
    const [ans5, setAns5] = useState('');
    const [ans6, setAns6] = useState('');
    const [ans7, setAns7] = useState('');
    const [ans7a, setAns7a] = useState('');
    const [ans8, setAns8] = useState('');
    const [ans9, setAns9] = useState('');
    const [ans10, setAns10] = useState('');
    const [ans11, setAns11] = useState('');
    const [ans12, setAns12] = useState('');
    const [ans13, setAns13] = useState('');
    const [ans13a, setAns13a] = useState('');
    const [ans14, setAns14] = useState('');
    const [ans15, setAns15] = useState('');
    const [ans16, setAns16] = useState('');
    const [ans17, setAns17] = useState('');
    const [ans18, setAns18] = useState('');
    const [ans19, setAns19] = useState('');
    const [ans20, setAns20] = useState('');

    function feedbackAccClick1(e) {
        setAns7(e.target.value);
        const getAccBtnPar1 = document.querySelector('.accBtn1');
        const getAccBtn1 = getAccBtnPar1.querySelector('.accordion-button');
        getAccBtn1.click();
    }
    function feedbackAccClick2(e) {
        setAns7(e.target.value);
        const getAccBtnPar2 = document.querySelector('.accBtn2');
        const getAccBtn2 = getAccBtnPar2.querySelector('.accordion-button');
        getAccBtn2.click();
    }
    function feedbackAccClick3(e) {
        setAns13(e.target.value);
        const getAccBtnPar3 = document.querySelector('.accBtn3');
        const getAccBtn3 = getAccBtnPar3.querySelector('.accordion-button');
        getAccBtn3.click();
    }
    function feedbackAccClick4(e) {
        setAns13(e.target.value);
        const getAccBtnPar4 = document.querySelector('.accBtn4');
        const getAccBtn4 = getAccBtnPar4.querySelector('.accordion-button');
        getAccBtn4.click();
    }

    let allCheck = '';

    if (ans1 && ans2 && ans3 && ans4 && ans5 && ans6 && ans7 && ans7a && ans8 && ans9 && ans10 && ans11 && ans12 && ans13 && ans13a && ans14 && ans15 && ans16 && ans17 && ans18 && ans19 && ans20) {
        allCheck = true;
    }

    let questions = [];

    const question = document.getElementsByClassName('feedbackBoxQuestion');

    for (let i = 0; i < question.length; i++) {
        questions.push(question[i].textContent);
    }

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        let answers = [ans1, ans2, ans3, ans4, ans5, ans6, ans7, ans7a, ans8, ans9, ans10, ans11, ans12, ans13, ans13a, ans14, ans15, ans16, ans17, ans18, ans19, ans20];

        axios.post('https://maxp.quadrixsoft.com/api/feedbackForm.php', { 'userId': userData.id, 'userFirstName': userData.firstName, 'userLastName': userData.lastName, 'questions': questions, 'answers': answers }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'Feedback form updated.') {
                navigate('/feedback-info');
            } else {
                console.log(res.data.message);
            }
        });

    }

    function getSubmitButtonClassName1() {
        return allCheck ? 'buttonWrapperMaxp' : 'buttonWrapperMaxp disabledBtn';
    }
    function getSubmitButtonClassName2() {
        return allCheck ? 'button2Maxp' : 'button2Maxp disabledBtn';
    }

    return (
        <>
            <Modal className='feedbackModal' show={props.show} onHide={props.close}>
                <Form name='Feedback Form' onSubmit={submit}>
                    <Modal.Header className='feedBackHeader'>
                        <img src={logoHeader} alt='' />
                        <h4 className='feedBackHeaderTitle'>MAXP FEEDBACK FORM</h4>
                        <p className='feedBackHeaderTxt'>Your opinion is important to us so please answer these questions so that we can continue to grow together and conquer new constellations.</p>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>How would you rate your overall experience using the MAXP learning platform?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Excellent' type='radio' label='Excellent' name='1' onClick={(e) => { setAns1(e.target.value) }} />
                                    <Form.Check value='Good' type='radio' label='Good' name='1' onClick={(e) => { setAns1(e.target.value) }} />
                                    <Form.Check value='Fair' type='radio' label='Fair' name='1' onClick={(e) => { setAns1(e.target.value) }} />
                                    <Form.Check value='Poor' type='radio' label='Poor' name='1' onClick={(e) => { setAns1(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>How likely are you to recommend MAXP to a friend or classmate?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Extremely likely' type='radio' label='Extremely likely' name='2' onClick={(e) => { setAns2(e.target.value) }} />
                                    <Form.Check value='Likely' type='radio' label='Likely' name='2' onClick={(e) => { setAns2(e.target.value) }} />
                                    <Form.Check value='Somewhat likely' type='radio' label='Somewhat likely' name='2' onClick={(e) => { setAns2(e.target.value) }} />
                                    <Form.Check value='Not likely' type='radio' label='Not likely' name='2' onClick={(e) => { setAns2(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className='feddbackFormBox '>
                            <h4 className='feedbackBoxQuestion'>What did you like most about MAXP?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio p16'>
                                    <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={((e) => { setAns3(e.target.value) })} />
                                </div>
                            </div>
                        </div>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>How easy was it to navigate MAXP and find what you were looking for?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Extremely easy' type='radio' label='Extremely easy' name='4' onClick={(e) => setAns4(e.target.value)} />
                                    <Form.Check value='Easy' type='radio' label='Easy' name='4' onClick={(e) => setAns4(e.target.value)} />
                                    <Form.Check value='Somewhat easy' type='radio' label='Somewhat easy' name='4' onClick={(e) => setAns4(e.target.value)} />
                                    <Form.Check value='Difficult' type='radio' label='Difficult' name='4' onClick={(e) => setAns4(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>How would you rate the quality of the MAXP content?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Excellent' type='radio' label='Excellent' name='5' onClick={(e) => setAns5(e.target.value)} />
                                    <Form.Check value='Good' type='radio' label='Good' name='5' onClick={(e) => setAns5(e.target.value)} />
                                    <Form.Check value='Fair' type='radio' label='Fair' name='5' onClick={(e) => setAns5(e.target.value)} />
                                    <Form.Check value='Poor' type='radio' label='Poor' name='5' onClick={(e) => setAns5(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>How useful were the MAXP materials in understanding the course topics?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Extremely useful' type='radio' label='Extremely useful' name='6' onClick={(e) => setAns6(e.target.value)} />
                                    <Form.Check value='Very useful' type='radio' label='Very useful' name='6' onClick={(e) => setAns6(e.target.value)} />
                                    <Form.Check value='Somewhat useful' type='radio' label='Somewhat useful' name='6' onClick={(e) => setAns6(e.target.value)} />
                                    <Form.Check value='Not so useful' type='radio' label='Not so useful' name='6' onClick={(e) => setAns6(e.target.value)} />
                                    <Form.Check value='Not at all useful' type='radio' label='Not at all useful' name='6' onClick={(e) => setAns6(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>Were there any moments when you felt frustrated accessing the online courses?</h4>
                            <div className='feedbackBoxAnswer'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Yes' type='radio' label='Yes' name='7' onClick={feedbackAccClick1} />
                                    <Form.Check value='No' className='m0' type='radio' label='No' name='7' onClick={feedbackAccClick2} />
                                </div>
                                <Accordion className='' defaultActiveKey="" flush>
                                    <Accordion.Item eventKey="0" className='accBtn1'>
                                        <Accordion.Header></Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li className='feedbackBoxQuestion'>Describe the moments when you felt frustrated</li>
                                            </ul>
                                            <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={(e) => setAns7a(e.target.value)} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className='accBtn2'>
                                        <Accordion.Header></Accordion.Header>
                                        <Accordion.Body>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <ul>
                                    <li className='feedbackBoxQuestion'>What did you dislike about MAXP?</li>
                                </ul>
                                <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={(e) => setAns8(e.target.value)} />
                                <ul>
                                    <li className='feedbackBoxQuestion'>What, if anything, would you suggest to improve the platform's ease of use?</li>
                                </ul>
                                <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={(e) => setAns9(e.target.value)} />
                                <ul>
                                    <li className='feedbackBoxQuestion'>What, if anything, would you suggest to improve the content on the platform?</li>
                                </ul>
                                <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={(e) => setAns10(e.target.value)} />
                                <ul>
                                    <li className='feedbackBoxQuestion'>Is there anything you would like to see improved or added to MAXP in the future?</li>
                                </ul>
                                <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={(e) => setAns11(e.target.value)} />
                                <ul>
                                    <li className='feedbackBoxQuestion'>Is there anything else you'd like to share about your experience with MAXP?</li>
                                </ul>
                                <Form.Control as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} onChange={(e) => setAns12(e.target.value)} />
                            </div>
                        </div>
                        <div className='feddbackFormBox'>
                            <h4 className='feedbackBoxQuestion'>If the price wasn’t an issue, would you buy this product?</h4>
                            <div className='feedbackBoxAnswer feedbackBoxRadioDiv'>
                                <div className='feedbackBoxRadio'>
                                    <Form.Check value='Yes' type='radio' label='Yes' name='8' onClick={feedbackAccClick3} />
                                    <Form.Check value='No' className='m0' type='radio' label='No' name='8' onClick={feedbackAccClick4} />
                                </div>
                                <Accordion className='' defaultActiveKey="" flush>
                                    <Accordion.Item eventKey="0" className='accBtn3'>
                                        <Accordion.Header></Accordion.Header>
                                        <Accordion.Body>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>At what price would you consider the product to be so cheap, that you’d suspect it must be a poor quality one?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='1 - 5 EUR' type='radio' label='1 - 5 EUR' name='9' onClick={(e) => setAns13a(e.target.value)} />
                                                        <Form.Check value='6 - 10 EUR' type='radio' label='6 - 10 EUR' name='9' onClick={(e) => setAns13a(e.target.value)} />
                                                        <Form.Check value='11 - 20 EUR' type='radio' label='11 - 20 EUR' name='9' onClick={(e) => setAns13a(e.target.value)} />
                                                        <Form.Check value='21 - 30 EUR' type='radio' label='21 - 30 EUR' name='9' onClick={(e) => setAns13a(e.target.value)} />
                                                        <Form.Check value='More than 30 EUR' type='radio' label='More than 30 EUR' name='9' onClick={(e) => setAns13a(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>At what price would you consider this product to be a great deal?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='1 - 5 EUR' type='radio' label='1 - 5 EUR' name='10' onClick={(e) => setAns14(e.target.value)} />
                                                        <Form.Check value='6 - 10 EUR' type='radio' label='6 - 10 EUR' name='10' onClick={(e) => setAns14(e.target.value)} />
                                                        <Form.Check value='11 - 20 EUR' type='radio' label='11 - 20 EUR' name='10' onClick={(e) => setAns14(e.target.value)} />
                                                        <Form.Check value='21 - 30 EUR' type='radio' label='21 - 30 EUR' name='10' onClick={(e) => setAns14(e.target.value)} />
                                                        <Form.Check value='More than 30 EUR' type='radio' label='More than 30 EUR' name='10' onClick={(e) => setAns14(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>What would be an acceptable price that would reflect the real value of this product to you?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='1 - 5 EUR' type='radio' label='1 - 5 EUR' name='11' onClick={(e) => setAns15(e.target.value)} />
                                                        <Form.Check value='6 - 10 EUR' type='radio' label='6 - 10 EUR' name='11' onClick={(e) => setAns15(e.target.value)} />
                                                        <Form.Check value='11 - 20 EUR' type='radio' label='11 - 20 EUR' name='11' onClick={(e) => setAns15(e.target.value)} />
                                                        <Form.Check value='21 - 30 EUR' type='radio' label='21 - 30 EUR' name='11' onClick={(e) => setAns15(e.target.value)} />
                                                        <Form.Check value='More than 30 EUR' type='radio' label='More than 30 EUR' name='11' onClick={(e) => setAns15(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>At what price would you consider this product to be expensive, and would start having doubts about whether to buy it because of its price?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='1 - 5 EUR' type='radio' label='1 - 5 EUR' name='12' onClick={(e) => setAns16(e.target.value)} />
                                                        <Form.Check value='6 - 10 EUR' type='radio' label='6 - 10 EUR' name='12' onClick={(e) => setAns16(e.target.value)} />
                                                        <Form.Check value='11 - 20 EUR' type='radio' label='11 - 20 EUR' name='12' onClick={(e) => setAns16(e.target.value)} />
                                                        <Form.Check value='21 - 30 EUR' type='radio' label='21 - 30 EUR' name='12' onClick={(e) => setAns16(e.target.value)} />
                                                        <Form.Check value='More than 30 EUR' type='radio' label='More than 30 EUR' name='12' onClick={(e) => setAns16(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>At what price would you no longer be willing to buy this product, because you would consider it to be priced too high?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='1 - 5 EUR' type='radio' label='1 - 5 EUR' name='13' onClick={(e) => setAns17(e.target.value)} />
                                                        <Form.Check value='6 - 10 EUR' type='radio' label='6 - 10 EUR' name='13' onClick={(e) => setAns17(e.target.value)} />
                                                        <Form.Check value='11 - 20 EUR' type='radio' label='11 - 20 EUR' name='13' onClick={(e) => setAns17(e.target.value)} />
                                                        <Form.Check value='21 - 30 EUR' type='radio' label='21 - 30 EUR' name='13' onClick={(e) => setAns17(e.target.value)} />
                                                        <Form.Check value='More than 30 EUR' type='radio' label='More than 30 EUR' name='13' onClick={(e) => setAns17(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>What are the chances that you buy this product for 9 EUR a month?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='0%' type='radio' label='0%' name='14' onClick={(e) => setAns18(e.target.value)} />
                                                        <Form.Check value='25%' type='radio' label='25%' name='14' onClick={(e) => setAns18(e.target.value)} />
                                                        <Form.Check value='50%' type='radio' label='50%' name='14' onClick={(e) => setAns18(e.target.value)} />
                                                        <Form.Check value='75%' type='radio' label='75%' name='14' onClick={(e) => setAns18(e.target.value)} />
                                                        <Form.Check value='100%' type='radio' label='100%' name='14' onClick={(e) => setAns18(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>What are the chances that you buy this product for 19 EUR a month?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='0%' type='radio' label='0%' name='15' onClick={(e) => setAns19(e.target.value)} />
                                                        <Form.Check value='25%' type='radio' label='25%' name='15' onClick={(e) => setAns19(e.target.value)} />
                                                        <Form.Check value='50%' type='radio' label='50%' name='15' onClick={(e) => setAns19(e.target.value)} />
                                                        <Form.Check value='75%' type='radio' label='75%' name='15' onClick={(e) => setAns19(e.target.value)} />
                                                        <Form.Check value='100%' type='radio' label='100%' name='15' onClick={(e) => setAns19(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='feedbackRadioSec'>
                                                <div className='p16'>
                                                    <ul>
                                                        <li className='feedbackBoxQuestion'>What are the chances that you buy this product for 29 EUR a month?</li>
                                                    </ul>
                                                    <div className='feedbackBoxRadio'>
                                                        <Form.Check value='0%' type='radio' label='0%' name='16' onClick={(e) => setAns20(e.target.value)} />
                                                        <Form.Check value='25%' type='radio' label='25%' name='16' onClick={(e) => setAns20(e.target.value)} />
                                                        <Form.Check value='50%' type='radio' label='50%' name='16' onClick={(e) => setAns20(e.target.value)} />
                                                        <Form.Check value='75%' type='radio' label='75%' name='16' onClick={(e) => setAns20(e.target.value)} />
                                                        <Form.Check value='100%' type='radio' label='100%' name='16' onClick={(e) => setAns20(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className='accBtn4'>
                                        <Accordion.Header></Accordion.Header>
                                        <Accordion.Body>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button id='submitFeedbackForm' type='submit' hidden>s</button>
                        <div className={getSubmitButtonClassName1()} onClick={() => { document.getElementById('submitFeedbackForm').click() }} style={{ pointerEvents: allCheck ? '' : 'none' }}>
                            <div className={getSubmitButtonClassName2()} >
                                SUBMIT ANSWERS
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default FeedbackFormModal;