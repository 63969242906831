import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import esterHeaderImg from '../lessons_img/lessonEster_1/esterSmallBg.png';
import esterMedal from '../lessons_img/lessonEster_1/esterMedal.png';
import '../lessons_css/LessonEster.css';
import picture1 from '../lessons_img/lessonEster_1/picture1.jpg';
import picture2 from '../lessons_img/lessonEster_1/picture2.jpg';
import picture3 from '../lessons_img/lessonEster_1/picture3.jpg';
import picture4 from '../lessons_img/lessonEster_1/picture4.jpg';
import picture5 from '../lessons_img/lessonEster_1/picture5.jpg';
import picture6 from '../lessons_img/lessonEster_1/picture6.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import clockIco from '../lessons_img/lessonEster_1/clockIco.png';
import { Accordion, Col, Form, Row } from "react-bootstrap";
import $ from 'jquery';
import { decrypt, encrypt } from "n-krypta";
import axios from "axios";
import ModuleSpecificQuestionsModal from '../../modals/ModuleSpecificQuestionsModal';

function LessonEster_1() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [btnDisablePractice3, setShowLessonBtnPractice3] = useState('none');
    const [disabledBtnPractice3, setDisableBtnPractice3] = useState('disabledBtn');
    const [btnDisablePractice4, setShowLessonBtnPractice4] = useState('none');
    const [disabledBtnPractice4, setDisableBtnPractice4] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [showMsq, setShowMsq] = useState(false);

    const handleCloseMsq = () => setShowMsq(false);
    const handleShowMsq = () => setShowMsq(true);

    function buttonUrl() {
        if (userData.modulSpecQuestion === 2) {
            handleShowMsq();
        } else {
            navigate('/lesson-Arsirf-1');
        }
    }

    const [spanClickVal, setSpanClickVal] = useState('');
    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [practice3, setPractice3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);
    const [quizAnswer1, setQuizAnswer1] = useState('');
    const [quizAnswer2, setQuizAnswer2] = useState('');
    const [quizAnswer3, setQuizAnswer3] = useState('');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 3 && userData.userPracticeId >= 10 && userData.quizTotal >= 2) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '3';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '3';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2, practice3];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '8') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
                if (practiceid === '9') {
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                }
                if (practiceid === '10') {
                    setShowLessonBtnPractice3('none');
                    setDisableBtnPractice3('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    //QUIZ SUBMIT
    function submitQuiz(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const quizid = event.target.getAttribute('quizid');
        const quizTitle = event.target.getAttribute('name');
        const lessonNumber = '3';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const quizQuestion = $('#' + formId + ' .quizQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [quizAnswer1, quizAnswer2, quizAnswer3];

        axios.post('https://maxp.quadrixsoft.com/api/usersQuiz.php', { 'userId': userData.id, 'quizid': quizid, 'quizTitle': quizTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'quizQuestion': quizQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (quizid === '2') {
                    setShowLessonBtnPractice4('none');
                    setDisableBtnPractice4('disabledBtn');
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END QUIZ SUBMIT

    function accHeaderClick() {
        const idForm = document.getElementById('9');
        idForm.querySelector('.accordion-button').click();
    }

    return (
        <>
            <div className='lessonEsterPage'>
                <div className='lessonEsterHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Ester' >Ester</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={esterHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO ESTER</p>
                    <h2 className='lessHeaderTitle'>Look for Opportunities and test Your Ideas</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <p className="lessTxt">Dear pilot,<br />
                            You have reached the planet Ester, where you will become familiar with creativity, thinking divergently, and applying your new skills. We are going to discuss concepts of convergent thinking, divergent thinking, creativity, idea, and opportunity. All these are necessary steps to navigate your spaceship throughout the universe.</p>
                        <h4 className="lessTitle">Creativity and thinking different</h4>
                        <h4 className="lessSubtitle">Convergent vs. divergent thinking</h4>
                        <img src={picture1} className="lessImg" alt="" />
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Convergent thinking is fact-based and so it occurs when a pilot gathers facts, evidence, or experiences from a variety of sources to solve a problem. This type of thinking is best suited for logical tasks with one right answer. Such as math problems like 5+5, and multiple choices tests (with one right answer). In school, we often are tested for correct answers and therefore convergent thinking comes to us as second nature.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>Divergent thinking is the opposite of convergent thinking. It generates unique solutions and sees various possibilities in response to a question or a problem. It's called thinking outside of the box and involves creativity. Divergent thinking attempts to free you from the old ways of doing things, take them apart and look at them in new ways, and mix things together that do not belong. Basically, it stirs up the brain. </p>
                            </div>
                        </div>
                        <p className="lessTxt">The goal of both types of thinking is to find the best solution to any homework, task, idea, or challenge.
                            For more information about divergent and convergent thinking, visit the Harvard University video and learn more.</p>
                        <iframe style={{ marginBottom: "32px" }} width="100%" height="423" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        <h4 className="lessSubtitle">Creativity as a way to go</h4>
                        <img src={picture2} className="lessImg" alt="" />
                        <p className="lessTxt">Creativity is anything that involves representing emotions or using learned skills to create a certain outcome. It means being able to come up with your own ideas.
                            <br /><br />
                            Creativity is necessary for making innovations, and for finding new ways. This is a feature that makes us human beings irreplaceable. No machine, robot, or even a humanoid will ever be able to do that.
                            <br /><br />
                            In order to be creative, you need to be able to view things in new ways or from a different perspective. Therefore think differently and be able to generate new possibilities or alternatives.</p>
                        <h4 className="lessSubtitle">Change is good. Mistakes are great</h4>
                        <img src={picture3} className="lessImg" alt="" />
                        <p className="lessTxt">There is nothing permanent in the life of a pilot except change. Change is inevitable, change is good and change is needed. If everything remains the same, things would become quite dull, and monotonous.
                            <br /><br />
                            Pilot, be ready to change yourself and your spaceship anytime. It will allow you and your project/plans to move forward, be trendy and react to the latest happenings.
                            <br /><br />
                            Change break routines and allow you to think out of the box, and that's what we are focusing on in this chapter. Also, the world is changing every day, every second, and we and our spaceship have to be flexible. </p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={clockIco} alt='' />
                                <p className='lessTxt m0'>Convergent thinking is fact-based and so it occurs when a pilot gathers facts, evidence, or experiences from a variety of sources to solve a problem. This type of thinking is best suited for logical tasks with one right answer. Such as math problems like 5+5, and multiple choices tests (with one right answer). In school, we often are tested for correct answers and therefore convergent thinking comes to us as second nature.</p>
                            </div>
                        </div>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 3.A</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear pilot, it's time to put divergent thinking into action with this challenge. </p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyEster mb32'>
                            <Form name='Practice 3.A' onSubmit={submit} practiceid='8' id='8'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <ol>
                                            <li>Set a timer for three minutes.</li>
                                            <li>Look around and find the object that is closest to you (If you can't find anything, use a hanger for this exercise).</li>
                                            <li>Start the timer.</li>
                                            <li>Write down as many uses for that object as you can think of.</li>
                                        </ol>
                                        <ul>
                                            <li className="practiceQuestion">Be as silly or “out there” as you would like.  Write down every idea, even if you think it’s bad.</li>
                                        </ul>
                                        <span style={{ color: "#0E0C1F", fontSize: "18px" }}>Rules:</span>
                                        <ul>
                                            <li>Use limited provided information and write down what you came up with. </li>
                                            <li>Upload your outcome and give feedback to others.</li>
                                        </ul>
                                        <Form.Control inputques="Be as silly or “out there” as you would like.  Write down every idea, even if you think it’s bad." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac3A' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac3A').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <h4 className="lessTitle">Ideas and opportunities</h4>
                        <img src={picture4} className="lessImg" alt="" />
                        <p className="lessTxt">The idea is just a beginning and it does not determine anyone's success, what matters is how you think about it and what you are willing to do with it.
                            <br /><br />
                            We can say that Idea is just something that has no value unless you make something out of it. An opportunity is an idea that can be implemented, for which resources are available.
                            <br /><br />
                            Take the idea and lay out the basics - from the design to realization. Think through the resources required, ask questions, ask opinions and you never know what can turn into great opportunities. An idea becomes an opportunity when you can act on it.</p>
                        <div className='lessTaskBox'>
                            <div className='lessTaskDiv'>
                                <i className="bi bi-list-task"></i>
                                <p className='lessTxt'>Spaceship ideas are the first steps but it's the opportunity that makes the difference. Ideally, try to have both. It's all about bringing value to specific people and addressing some needs/issues. So ask yourself these questions :
                                    <br /><br />
                                    How would you describe the value your idea brings?
                                    What is a need and what is an issue you could be focusing on?</p>
                            </div>
                            <div className='lessAccNote'>
                                <p>You don't have to answer all of these at the same time. Take your time so you can explore the answer. This task is not a race. These questions are here mainly for you the pilots. Discover yourself and be aware that it's a process. It will take thinking, talking, writing, and doing. Keeping a journal along the way helps in allowing you to get to know yourself too.</p>
                            </div>
                        </div>
                        <img src={picture5} className="lessImg" alt="" />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 3.B</h4>
                                    </div>
                                    <p className='lessPracticeTxt'></p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyEster mb32'>
                            <Form name='Practice 3.B' onSubmit={submit} practiceid='9' id='9'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb24'>
                                        <ul>
                                            <li className="">Write down your idea from Lesson 2 or pick one of the 3 motives that we provide for you and use it to create a new one.</li>
                                        </ul>
                                        <Accordion defaultActiveKey="0" flush className='lessAcc'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='lessTaskAcc practiceQuestion'>{spanClickVal}</Accordion.Header>
                                                <Accordion.Body>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); accHeaderClick(); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }}>Youth club/movement</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); accHeaderClick(); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }}>Farmers market</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); accHeaderClick(); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }}>Technology</span>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Form.Control inputques="accInput" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">Describe what your idea is trying to accomplish. Who is your idea for? Don't forget that nothing is for everyone.</li>
                                        </ul>
                                        <Form.Control inputques="Describe what your idea is trying to accomplish. Who is your idea for? Don't forget that nothing is for everyone." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => setPractice2(e.target.value)} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac3B' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitPrac3B').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <h4 className="lessSubtitle">Connecting ideas and critical thinking</h4>
                        <p className="lessTxt">The idea is just a beginning and what follows is in your hands. Always remember: The whole process is simple when you accept these things -  you can never  be right all the time, and making mistakes is okay for you to keep learning and improve.</p>
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>A smart man makes a mistake, learns from it, and never makes that mistake again.</h4>
                                <p>Roy H. Williams</p>
                            </div>
                        </div>
                        <p className="lessTxt">Stories from others can be helpful too. There is no easier way than to learn from others' mistakes. Take inspirationI from others' mistakes but don't forget to stay true to who you are.</p>
                        <h4 className="lessSubtitle">Always research and call into question</h4>
                        <img src={picture6} className="lessImg" alt="" />
                        <p className="lessTxt">Ideas are amazing and inspiring but sometimes it might happen that you are not the first one that came up with that idea but you might be the one to put them into practice.
                            <br /><br />
                            Always try to research and learn from other people's experiences. Even though your spaceship idea can be pretty cool, don't glorify it. Use critical thinking when evaluating information found online.
                            <br /><br />
                            Remember to be creative, so even if you are not the first one to come up with the exact idea, think about how you can do things differently. You are a pilot after all! </p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 3.C</h4>
                                    </div>
                                    <p className='lessPracticeTxt'> Take the idea from 3.B and try to look it up online. </p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyEster mb32'>
                            <Form name='Practice 3.C' onSubmit={submit} practiceid='10' id='10'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb24'>
                                        <ul>
                                            <li className="practiceQuestion">What are 2 things that you learned through your research?</li>
                                        </ul>
                                        <Form.Control inputques="What are 2 things that you learned through your research?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb24'>
                                        <ul>
                                            <li className="practiceQuestion">How would you change your idea after doing your research?</li>
                                        </ul>
                                        <Form.Control inputques="How would you change your idea after doing your research?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">How did you approach the research?</li>
                                        </ul>
                                        <Form.Control inputques="How did you approach the research?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac3C' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice3} onClick={() => { document.getElementById('submitPrac3C').click() }} style={{ pointerEvents: btnDisablePractice3 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice3} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className='lessPracticeHeader headerQuiz'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-mortarboard"></i>
                                        <h4 className='lessPracticeTitle'>Ester Quiz</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear Pilot, practice makes perfect so here is a little quiz:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyEster mb32'>
                            <Form name='Ester Quiz' onSubmit={submitQuiz} quizid='2' id='2'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv'>
                                            <ul>
                                                <li className='quizQuestion'>Thinking that is best suited for logic tasks and focuses on finding one well-defined solution.</li>
                                            </ul>
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Divergent' value='Divergent' required onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Convergent' value='Convergent' required onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv br0'>
                                            <ul>
                                                <li className='quizQuestion'>What characterizes divergent thinkers?</li>
                                            </ul>
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='They Think creatively and in context.' value='They Think creatively and in context.' required onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='They Look for the one correct solution.' value='They Look for the one correct solution.' required onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='None of the above answers is correct.' value='None of the above answers is correct.' required onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv bbr'>
                                            <ul>
                                                <li className='quizQuestion'>Which of the following is not a measure of divergent thinking?</li>
                                            </ul>
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='Open-ended questions' value='Open-ended questions' required onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='Test with one right answer' value='Test with one right answer' required onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox m0' type='radio' label='Essays' value='Essays' required onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice4(''); setDisableBtnPractice4(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitEsterQuiz' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice4} onClick={() => { document.getElementById('submitEsterQuiz').click() }} style={{ pointerEvents: btnDisablePractice4 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice4} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Ester you should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Distinguish between convergent and divergent thinking</li>
                                    <li>Distinguish between idea and opportunity and how they are connected</li>
                                    <li>Always be ready to question your own spaceship, change it if needed</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://www.csun.edu/' target='_blank'>Asana</a>
                                        <a className='lessResourcesUrl' href='https://www.csun.edu/' target='_blank'>csun.edu</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecEster'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={esterHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Ester</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col>
                                        <h4 className='lessFin'>3/3</h4>
                                        <p className='lessP'>Practice</p>
                                    </Col>
                                    <Col>
                                        <h4 className='lessFin'>1/1</h4>
                                        <p className='lessP'>Quiz</p>
                                    </Col>
                                    <Col>
                                        <img className='alzubraMedal' src={esterMedal} alt='' />
                                        <p className='lessP'>Ester Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={buttonUrl} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <p className='chapterNum '>Visit Arsirf</p>
                                                <h4 className='chapterTitle '>WORK WITH OTHERS</h4>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <i className="bi bi-arrow-right"></i>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModuleSpecificQuestionsModal
                show={showMsq}
                close={handleCloseMsq}
            />
        </>
    );
}

export default LessonEster_1;