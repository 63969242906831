import axios from "axios";
import { decrypt, encrypt } from "n-krypta";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Modal, Row, ToggleButton } from "react-bootstrap";
import placeholderImg from '../lessons_img/placeholderImg.png';
import alzubraSatBg from '../lessons_template_img/alzubraSatBg.jpg';
import $ from 'jquery';
import '../css/SelfAssesmentTest.css';
import { useNavigate } from "react-router-dom";


function SelfAssesmentTest1(props) {

    const navigate = useNavigate();

    const alzubraSatBgImg = { backgroundImage: "url(" + alzubraSatBg + ")", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" };

    //Self Assesment Test PopUp
    const [showSat, setShowSat] = useState(false);

    const handleCloseSat = () => setShowSat(false);
    const handleShowSat = () => setShowSat(true);
    //END Self Assesment Test PopUp

    const [userData, setUserData] = useState([]);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userAvatarImgTag, setUserAvatarImgTag] = useState('');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
        setUserFirstName(decryptedUserData.firstName);
        setUserLastName(decryptedUserData.lastName);
    }, [encryptedObject]);

    useEffect(() => {
        if (userData.userAvatar === '') {
            const userFirstNameIni = userFirstName.charAt(0);
            const userLastNameIni = userLastName.charAt(0);
            setUserAvatarImgTag(<span className="userAvatarTxt m0">{userFirstNameIni + userLastNameIni}</span>);
        } else {
            setUserAvatarImgTag(<img className="satPopupHeaderImg" src={userData.userAvatar} alt="Avatar" />);
        }

        switch (userData.selfAssTest) {
            case '1':
                handleShowSat();
                break;
            case '2':
                handleCloseSat();
                break;
        }
    }, [userData]);

    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }
    const [hour, minutes] = [
        today.getHours(),
        today.getMinutes()
    ];
    const HH = twoDigitPad(hour);
    const Min = twoDigitPad(minutes);

    function twoDigitPad(num) {
        return num < 10 ? "0" + num : num;
    }

    const satDateAndTime = dd + "/" + mm + "/" + yyyy + "/" + HH + ":" + Min;

    const userId = props.userId;
    const testId = '2';
    let questions = [];

    const question = document.getElementsByClassName('question');

    for (let i = 0; i < question.length; i++) {
        questions.push(question[i].textContent);
    }

    //Radio Buttons
    const [radioValue1, setRadioValue1] = useState('');
    const [radioValue2, setRadioValue2] = useState('');
    const [radioValue3, setRadioValue3] = useState('');
    const [radioValue4, setRadioValue4] = useState('');
    const [radioValue5, setRadioValue5] = useState('');
    const [radioValue6, setRadioValue6] = useState('');
    const [radioValue7, setRadioValue7] = useState('');
    const [radioValue8, setRadioValue8] = useState('');
    const [radioValue9, setRadioValue9] = useState('');
    const [textArea, setTextArea] = useState('');

    let allCheck = '';

    function answersCheck() {
        if (radioValue1 && radioValue2 && radioValue3 && radioValue4 && radioValue5 && radioValue6 && radioValue7 && radioValue8 && radioValue9 && textArea) {
            allCheck = true;
        }
    }

    const radios = [
        { name: '1', value: '1' },
        { name: '2', value: '2' },
        { name: '3', value: '3' },
        { name: '4', value: '4' },
        { name: '5', value: '5' },
        { name: '6', value: '6' },
        { name: '7', value: '7' },
        { name: '8', value: '8' },
        { name: '9', value: '9' },
        { name: '10', value: '10' },
    ];
    //END Radio Buttons

    function close() {
        props.close();
    }

    function submitAnswers() {

        let answers = [radioValue1, textArea, radioValue2, radioValue3, radioValue4, radioValue5, radioValue6, radioValue7, radioValue8, radioValue9];

        axios.post('https://maxp.quadrixsoft.com/api/selfAssesmentTest.php', { 'userId': userId, 'testId': testId, 'questions': questions, 'answers': answers, 'satDateAndTime': satDateAndTime }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Self Assesment Test is updated.') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                navigate('/future-journeys');
            }
        });
        //props.close();
    }

    function getSubmitButtonClassName1() {
        return allCheck ? 'buttonWrapperMaxp' : 'buttonWrapperMaxp disabledBtn';
    }
    function getSubmitButtonClassName2() {
        return allCheck ? 'button2Maxp' : 'button2Maxp disabledBtn';
    }

    return (
        <>
            <Modal className='satPopup' show={props.show} onHide={close} backdrop="static" keyboard={false} size="xl">
                <Modal.Header className='satPopupHeader' style={alzubraSatBgImg}>
                    <Row className='satPopupHeaderRow'>
                        <Col sm={3}>
                            {userAvatarImgTag}
                        </Col>
                        <Col sm={9}>
                            <h4 className='satPopupHeaderTitle'>Self Assesment Test</h4>
                            <p className='satPopupHeaderTxt'>Get ready to launch into the MAXP Universe! But first, take the self-assessment test to ensure you’re fully prepared for takeoff. It’s the perfect way to set yourself up for success and maximize your learning experience.</p>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body className="satModalBody">
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Self-awareness</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1-10, evaluate how well you know your own strengths and weaknesses right now?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio1-${idx}`}
                                    type="radio"
                                    name="radio1"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue1 === radio.value}
                                    onChange={(e) => setRadioValue1(e.currentTarget.value)}
                                    className="toggleBtns"
                                    onClick={answersCheck()}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Motivation</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1-10, evaluate how well you know your own If one of your friends had an idea for an after-school project, how motivated would you be to get involved?</p>
                        <textarea className="satBodyTextarea" placeholder="Your Answer" onChange={(e) => { setTextArea(e.currentTarget.value); answersCheck() }} />
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Leadership</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how confident would you be to start an after-school project yourself?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio2-${idx}`}
                                    type="radio"
                                    name="radio2"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue2 === radio.value}
                                    onChange={(e) => { setRadioValue2(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Confidence to be a change agent</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how confident are you that you have what it takes to effect a change in your community?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio3-${idx}`}
                                    type="radio"
                                    name="radio3"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue3 === radio.value}
                                    onChange={(e) => { setRadioValue3(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Creativity/Divergent thinking</h4>
                        </div>
                        <p className='satBodyTxt question'>If there was a problem in your community you cared about, how confident are you in your ability to brainstorm lots of solutions to that issue on a scale of 1 to 10?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio4-${idx}`}
                                    type="radio"
                                    name="radio4"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue4 === radio.value}
                                    onChange={(e) => { setRadioValue4(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Critical thinking</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how do you evaluate your ability to do research on real-life problems in order to find the best solution?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio5-${idx}`}
                                    type="radio"
                                    name="radio5"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue5 === radio.value}
                                    onChange={(e) => { setRadioValue5(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Communication&amp;Ability to fiind support through networking</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how do you evaluate your ability to persuade your peers to support an idea or a project that you believe in ?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio6-${idx}`}
                                    type="radio"
                                    name="radio6"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue6 === radio.value}
                                    onChange={(e) => { setRadioValue6(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Ability to plan</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how do you evaluate your ability to plan the steps necessary to get an idea off the ground?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio7-${idx}`}
                                    type="radio"
                                    name="radio7"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue7 === radio.value}
                                    onChange={(e) => { setRadioValue7(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Budget</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how do you evaluate your ability to budget your allowance/ income so that you make the best use of your money?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio8-${idx}`}
                                    type="radio"
                                    name="radio8"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue8 === radio.value}
                                    onChange={(e) => { setRadioValue8(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                    <section className='satBodySection'>
                        <div className='satBodyTitle'>
                            <h4>Present yourself with confidence</h4>
                        </div>
                        <p className='satBodyTxt question'>On a scale of 1 to 10, how confident are you in your ability to present an idea in a persuasive manner?</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio9-${idx}`}
                                    type="radio"
                                    name="radio9"
                                    variant="outline-primary"
                                    value={radio.value}
                                    checked={radioValue9 === radio.value}
                                    onChange={(e) => { setRadioValue9(e.currentTarget.value); answersCheck() }}
                                    className="toggleBtns"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </section>
                </Modal.Body>
                <Modal.Footer className="satModalFooter">
                    <div className={getSubmitButtonClassName1()} onClick={() => { submitAnswers(); props.close() }} style={{ pointerEvents: allCheck ? '' : 'none' }}>
                        <div className={getSubmitButtonClassName2()}>
                            Submit Answers
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SelfAssesmentTest1;