import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonMyrthyr.css';
import myrthyrHeaderImg from '../lessons_img/lessonMyrthyr_1/myrthyrSmallBg.png';
import picture1c from '../lessons_img/lessonMyrthyr_3/picture1c.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';

function LessonMyrthyr_3() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [practice1, setPractice1] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 6 && userData.userPracticeId >= 21) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '21') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    return (
        <>
            <div className='lessonMyrthyrPage'>
                <div className='lessonMyrthyrHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Myrthyr' >Myrthyr</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 3</span>
                    </div>
                    <img src={myrthyrHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO MYRTHYR</p>
                    <h2 className='lessHeaderTitle'>Develop a plan</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 3
                        </div>
                        <h4 className='lessTitle'>Smart Goals</h4>
                        <img src={picture1c} className='lessImg' alt='' />
                        <p className='lessTxt'>The SMART in SMART goals stands for Specific, Measurable, Achievable, Relevant, and Time-Bound. These parameters will be very helpful and in other words - SMART goals are acronyms that pilots can use to guide their spaceship and set their goals.</p>
                        <div className='lessLettersBox'>
                            <h4 className='lessLetterIni'>S&nbsp;<h4 className='lessLettesTitle'> - Specific</h4></h4>
                            <p className='lessTxt'>The goal should be clear and specific so that it's easy to understand and work towards. Avoid vague and broad goals. </p>
                            <h4 className='lessLetterIni'>M&nbsp;<h4 className='lessLettesTitle'> - Measurable</h4></h4>
                            <p className='lessTxt'>The progress towards the goal should be measurable so that you can track your progress and know when you've achieved it.</p>
                            <h4 className='lessLetterIni'>A&nbsp;<h4 className='lessLettesTitle'> - Achievable</h4></h4>
                            <p className='lessTxt'>The goal should be achievable, meaning that it's realistic given your current resources and constraints. </p>
                            <h4 className='lessLetterIni'>R&nbsp;<h4 className='lessLettesTitle'> - Relevant</h4></h4>
                            <p className='lessTxt'>The goal should be relevant to your overall objectives and priorities. Also aligned with your values.</p>
                            <h4 className='lessLetterIni'>T&nbsp;<h4 className='lessLettesTitle'> - Time-bound</h4></h4>
                            <p className='lessTxt m0'>The goal should have a specific timeline for completion so that you can stay on track and meet deadlines. It's important to have a clear time horizon.
                                Using the SMART framework can help you set goals that are more likely to be achieved, as it ensures that your goals are well-defined and realistic.</p>
                        </div>
                        <p className='lessTxt'>SMART goals can be used within all aspects of your life. Here are some practical examples:</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>I will earn an A grade in my language course by attending all lectures, participating in class discussions, completing all assignments on time, and seeking help from the instructor or a tutor if needed, within the next semester.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>I will improve my coding skills by taking an online course, completing at least two coding projects, and seeking feedback from a mentor, within the next six months, in order to qualify for a job interview at my favorite company.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>I will increase my daily water intake to at least 8 glasses per day by carrying a water bottle with me at all times, setting reminders on my phone, and tracking my progress using a hydration app, within the next month, in order to improve my overall health and well-being.</p>
                            </div>
                        </div>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 6.C</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Write all your SMART goals for yourself, school achievements, success in the MAXP universe, fitness, or sport using this framework:
                                        <br /><br />
                                        I will ___ (specific and actionable verb) ___ (specific area or task) by___ (specific steps or actions) ___ (time frame) in order to ___ (overall objective or desired outcome).
                                        <br /><br />
                                        An examples of a SMART goal statement might look like this:
                                        <br /><br />
                                        I will run a 5k race in under 30 minutes by following a training plan that includes three runs per week, increasing my distance and speed gradually, and incorporating strength training and stretching into my workouts, within the next four months.
                                        <br /><br />
                                        I will complete the MAXP constellation by actively participating in all course activities, seeking help when needed in the MAXP community, and regularly reviewing the material, within the next month.</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMyrthyr mb32'>
                            <Form name='Practice 6.C' onSubmit={submit} practiceid='21' id='21'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className='practiceQuestion'>What is your SMART goal?</li>
                                        </ul>
                                        <Form.Control inputques="What is your SMART goal?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac6C' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac6C').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonMyrthyrNextCh'>
                        <div className='lessonContainer'>
                            <img src={myrthyrHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessMyrthyrNextChTitle'>Third Chapter of Myrthyr</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Myrthyr-4')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Myrthyr-4'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>ADAPT, EVOLVE, SURVIVE</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Myrthyr-4'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonMyrthyr_3;