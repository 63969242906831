import { Col, Row } from 'react-bootstrap';
import '../css/Faq.css';

function DownloadFaq() {

    return (
        <>
            <Row className='downloadFaqRow'>
                <Col className='p0'>
                    <div className='downloadFaqCol'>
                        <i className="bi bi-file-pdf"></i>
                    </div>
                    <div className='downloadFaqColDiv'>
                        <i className="bi bi-cloud-download"></i>
                        <p className='downloadFaqColTxt'>Material</p>
                    </div>
                </Col>
                <Col className='p0'>
                    <div className='downloadFaqCol'>
                        <i className="bi bi-file-pdf"></i>
                    </div>
                    <div className='downloadFaqColDiv'>
                        <i className="bi bi-cloud-download"></i>
                        <p className='downloadFaqColTxt'>Material</p>
                    </div>
                </Col>
                <Col className='p0'>
                    <div className='downloadFaqCol'>
                        <i className="bi bi-file-word"></i>
                    </div>
                    <div className='downloadFaqColDiv'>
                        <i className="bi bi-cloud-download"></i>
                        <p className='downloadFaqColTxt'>Material</p>
                    </div>
                </Col>
                <Col className='p0'>
                    <div className='downloadFaqCol'>
                        <i className="bi bi-filetype-jpg"></i>
                    </div>
                    <div className='downloadFaqColDiv'>
                        <i className="bi bi-cloud-download"></i>
                        <p className='downloadFaqColTxt'>Material</p>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default DownloadFaq;