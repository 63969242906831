import { decrypt } from "n-krypta";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/main.css";
import "../css/UserDashboard.css";
import { Tab } from "bootstrap";
import mailIco from "../img/mailIco.png";
import memberIcoIco from "../img/memberIco.png";
import levelIco from "../img/levelIco.png";
import medalIco from "../img/medalIco.png";
import pointsIco from "../img/pointsIco.png";
import PiscesConstalationTab from "../app_elements/piscesConstalationTab";
import ElevatorPitchTab from '../app_elements/elevatorPitchTab';
import axios from "axios";
import SelfAssesmentTestTab from '../app_elements/selfAssesmentTestTab';


function UserDashboard() {

    const navigate = useNavigate();
    const location = useLocation();

    let activeKey = "";

    if (location.state === "spacePort") {
        activeKey = "spacePort";
    } else {
        activeKey = "about";
    }

    const [userData, setUserData] = useState([]);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        if (encryptedObject === null) {
            navigate("/");
        } else {
            const secret = localStorage.getItem("hash");
            const decryptedUserData = decrypt(encryptedObject, secret);
            setUserData(decryptedUserData);
            setUserFirstName(decryptedUserData.firstName);
            setUserLastName(decryptedUserData.lastName);
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [userAvatarImgTag, setUserAvatarImgTag] = useState('');

    useEffect(() => {
        if (userData.userAvatar === '') {
            const userFirstNameIni = userFirstName.charAt(0);
            const userLastNameIni = userLastName.charAt(0);
            setUserAvatarImgTag(<span className="userAvatarTxt">{userFirstNameIni + userLastNameIni}</span>);
        } else {
            setUserAvatarImgTag(<img className="userAvatarImg" src={userData.userAvatar} alt="Avatar" />);
        }
    }, [userData]);

    useEffect(() => {
        axios.post('https://maxp.quadrixsoft.com/api/elevatorPitch.php', { 'userId': userData.id, 'apiCall': 'checkUser' }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'User already exists!') {

                if (res.data.user.elevatorPitch && res.data.user.video && res.data.user.pitchReflection) {
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                } else {
                    setShowLessonBtnNext('none');
                    setDisableBtnNext('disabledBtn');
                }
            }

        }).catch((error) => {
            console.log(error);
        });
    }, [userData]);

    return (
        <>
            <div className="userDashboard">
                <Container>
                    <div className="userContentDiv">
                        <Row className="">
                            <Col md={3} className="plr0 userAvatarCol">
                                {userAvatarImgTag}
                            </Col>
                            <Col className="plr0 userAvatarCol">
                                <div className="">
                                    <span className="userPointsHeader">{userData.userPoints} XP</span>
                                    <h2 className="userName">{userData.firstName} {userData.lastName}</h2>
                                    <p className="userLocation"><img src={userData.countryFlag} className="countryFlag" alt="" /> {userData.userLocation}</p>
                                    <a href="#" className="userCrewHeader">Crew 001</a>
                                    <Link className="editProfile" to='/edit-profile'><i className="bi bi-pencil editProfilPencil"></i>Edit Profile</Link>
                                </div>
                            </Col>
                            <Col className="linkCol">
                                <div className='dashboardBreadcrumbs'>
                                    <Link to='/' className="userDashboardBreadcrumbsBack">
                                        <i className="bi bi-arrow-left"></i><span>Back to Home</span>
                                    </Link>
                                </div>
                                <div className={"buttonWrapperMaxp boundBtnDash " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Link to='/boundary' className={"buttonSimple-3Maxp " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <i className="bi bi-trophy"></i>Achievement
                                    </Link>
                                </div>
                                <div className="buttonWrapperMaxp piscesConsBtn">
                                    <Link to='/course' className="button2Maxp">
                                        Go to Pisces Constalation
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <div className='dashboardTabs'>
                            <Tabs
                                defaultActiveKey={activeKey}
                                id="uncontrolled-tab-example"
                                className=""
                            >
                                <Tab eventKey="about" title="About">
                                    <div className="userDataDiv">
                                        <i className="bi bi-person"></i>
                                        <p>Pilot Name: {userData.firstName} {userData.lastName}</p>
                                    </div>
                                    <div className="userDataDiv">
                                        <i className="bi bi-envelope"></i>
                                        <p>Pilot's Email: <a className="userEmailDashboard" href={"mailto:" + userData.email}>{userData.email}</a></p>
                                    </div>
                                    <div className="userDataDiv">
                                        <i className="bi bi-people"></i>
                                        <p>Crew Member: <a className="userCrewDashboard" href={"#"}>Crew 001</a></p>
                                    </div>
                                    <div className="userDataDiv">
                                        <i className="bi bi-calendar"></i>
                                        <p>Crew Member since: {userData.registrationDate}</p>
                                    </div>
                                    <div className="userDataDiv">
                                        <i className="bi bi-calendar"></i>
                                        <p>Discovered Constellations: <span className="disConstSpan">Pisces Constellation</span></p>
                                    </div>
                                    <div className="userDataDiv">
                                        <i className="bi bi-award"></i>
                                        <p className="userMedal">Medal: {userData.userMedal}</p>
                                    </div>
                                    <div className="userDataDiv">
                                        <i className="bi bi-gear-wide"></i>
                                        <p>All Earned Badges: {userData.userLevel}</p>
                                    </div>
                                    <div className="userDataDiv userPoint">
                                        <i className="bi bi-star"></i>
                                        <p>All Earned XP Points: <span className="earnedPointsSpan">{userData.userPoints}</span></p>
                                    </div>
                                </Tab>
                                <Tab eventKey="spacePort" title="Space Port" className="tabProgressBar">
                                    <PiscesConstalationTab />
                                </Tab>
                                <Tab eventKey="pilot" title="Pilot Review">

                                </Tab>
                                <Tab eventKey="assTest" title="Self Assesment Test">
                                    <SelfAssesmentTestTab />
                                </Tab>
                                <Tab eventKey="leaderboard" title="Leaderboard">

                                </Tab>
                                <Tab eventKey="elevatorPitch" title="Elevator Pitch">
                                    <ElevatorPitchTab />
                                </Tab>
                                <Tab eventKey="crewPairing" title="Crew Pairing">

                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default UserDashboard;