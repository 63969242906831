import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../css/ModuleSpecificQuestionsModal.css';
import likeIco from '../img/likeIco.png';

function ModuleSpecQuesThxModal(props) {

    const navigate = useNavigate();

    return (
        <>
            <Modal show={props.show} onHide={props.close} className='modulSqThx' size='lg' >
                <Modal.Body>
                    <img src={likeIco} alt='' />
                    <h4 className='modulSqThxTitle'>Thank you pilot, your opinion means a lot to us</h4>
                    <Link className='buttonWrapperMaxp' to={props.nextLessonUrl}>
                        <div className='button2Maxp' >
                            GO TO NEXT PLANET
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </Link>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModuleSpecQuesThxModal;