import React, { useEffect, useState } from 'react';
import Footer from './app_elements/footer';
import Router from './Router';
import './css/App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import UserHeader from './app_elements/userHeader';
import Header from './app_elements/header';
import { useLocation, useNavigate } from 'react-router-dom';

function App() {

  const queryClient = new QueryClient();
  let navigate = useNavigate();
  let location = useLocation();

  const [userHeader, setUserHeader] = useState(false);
  const [header, setHeader] = useState(false);

  const handleCloseUserHeader = () => setUserHeader(false);
  const handleShowUserHeader = () => setUserHeader(
    <UserHeader
      logOutUser={() => {
        handleCloseUserHeader();
        handleShowHeader();
        navigate('/');
        navigate(0);
        localStorage.clear();
      }}
    />
  );
  const handleCloseHeader = () => setHeader(false);
  const handleShowHeader = () => setHeader(
    <Header
      loggedInUser={() => {
        handleCloseHeader();
        handleShowUserHeader();
      }}
    />
  );

  useEffect(() => {
    const loggedInUser = localStorage.getItem('userData');

    if (loggedInUser) {
      handleCloseHeader();
      handleShowUserHeader();
    } else {
      handleCloseUserHeader();
      handleShowHeader();
    }
  }, [location]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {header}
        {userHeader}
        <Router />
        <Footer />
      </QueryClientProvider>
    </div>
  );

}

export default App;