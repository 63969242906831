import { decrypt } from 'n-krypta';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import '../css/Boundary.css';
import celebIco from '../img/celeb.png';
import woodenMedal from '../img/woodenMedal.png';
import silverMedal from '../img/silverMedal.png';
import goldMedal from '../img/goldMedal.png';
import alzubraBadge from '../img/alzubraBadge.png';
import najimaBadge from '../img/najimaBadge.png';
import esterBadge from '../img/esterBadge.png';
import arsirfBadge from '../img/arsirfBadge.png';
import moranonBadge from '../img/moranonBadge.png';
import myrthyrBadge from '../img/myrthyrBadge.png';
import infinityBadge from '../img/infinityBadge.png';
import discordBtn from '../img/discordBtn.png';
import facebookBtn from '../img/facebookBtn.png';
import linkedInBtn from '../img/linkedInBtn.png';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import logoCertificate from '../img/logoCertificate.png';
import EUIconTxt from '../img/EUIconTxt.png';
import nfvapLogo from '../img/nfvapLogo.png';
import we4youLogo from '../img/we4youLogo.png';
import rediLogo from '../img/rediLogo.png';
import novelicLogo from '../img/novelicLogo.png';
import SelfAssesmentTest2 from '../modals/SelfAssesmentTest2';


function Boundary() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [userData, setUserData] = useState([]);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userAvatarImgTag, setUserAvatarImgTag] = useState('');
    const [userMedal, setUserMedal] = useState('');
    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    //Self Assesment Test PopUp
    const [showSat, setShowSat] = useState(false);

    const handleCloseSat = () => setShowSat(false);
    const handleShowSat = () => setShowSat(true);
    //END Self Assesment Test PopUp

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
        setUserFirstName(decryptedUserData.firstName);
        setUserLastName(decryptedUserData.lastName);
    }, [encryptedObject]);

    useEffect(() => {

        if (userData.userAvatar === '') {
            const userFirstNameIni = userFirstName.charAt(0);
            const userLastNameIni = userLastName.charAt(0);
            setUserAvatarImgTag(<span className="userAvatarTxt m0">{userFirstNameIni + userLastNameIni}</span>);
        } else {
            setUserAvatarImgTag(<img className="userAvatarImg m0" src={userData.userAvatar} alt="Avatar" />);
        }

        if (userData.selfAssTest === '1') {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        }

        switch (userData.userMedal) {
            case 'wooden':
                setUserMedal(woodenMedal);
                break;
            case 'silver':
                setUserMedal(silverMedal);
                break;
            case 'gold':
                setUserMedal(goldMedal);
                break;
        }
    }, [userData]);

    const printRef = React.useRef();

    function showCertificate() {
        handleShow();
    }

    function startSelfAssTest() {
        if (userData.selfAssTest === '1') {
            setTimeout(function () {
                handleShowSat();
            }, 1000);
        } else {
            handleCloseSat();
        }
    }

    const downloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element, { backgroundColor: '#281C54', width: '1200', height: '1667' });
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            unit: "in",
            format: [8.27, 11.5]
        });
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    }

    return (
        <>
            <div className='boundaryPage'>
                <div className=' container '>
                    <div className='boundaryFrameDiv' id='contentForPdf'>
                        <div className='boundaryUserAvatar'>
                            {userAvatarImgTag}
                        </div>
                        <p className='boundUserName'>{userFirstName + ' ' + userLastName}</p>
                        <div className='boundCelebDiv'>
                            <img className='celebIco1' src={celebIco} alt='' />
                            <h2 className='boundCelebTitle'>Congratulations!</h2>
                            <img className='celebIco2' src={celebIco} alt='' />
                        </div>
                        <p className='boundUnderCelebTxt'>on finishing your first journey within the MAXP universe.</p>
                        <div className='boundUserAchiDiv'>
                            <h4 className='boundAchiTitle'>your achievement</h4>
                            <Row className='boundAchiRow'>
                                <Col>
                                    <img src={userMedal} alt='' />
                                    <span className='boundAchiMedalTxt'>{userData.userMedal} Medal</span>
                                </Col>
                                <Col>
                                    <span className='boundAchiMedalNum'>86</span>
                                    <span className='boundAchiMedalTxt'>Completed<br />Questions</span>
                                </Col>
                                <Col>
                                    <span className='boundAchiMedalNum'>20</span>
                                    <span className='boundAchiMedalTxt'>Completed<br />Practices</span>
                                </Col>
                                <Col>
                                    <span className='boundAchiMedalPoints'>{userData.userPoints} XP</span>
                                </Col>
                            </Row>
                            <Row className='boundAchiBadgeDiv'>
                                <Col>
                                    <img src={alzubraBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Alzubra Badge</span>
                                </Col>
                                <Col>
                                    <img src={najimaBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Najima Badge</span>
                                </Col>
                                <Col>
                                    <img src={esterBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Ester Badge</span>
                                </Col>
                                <Col>
                                    <img src={arsirfBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Arsirf Badge</span>
                                </Col>
                                <Col>
                                    <img src={moranonBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Moranon Badge</span>
                                </Col>
                                <Col>
                                    <img src={myrthyrBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Myrthyr Badge</span>
                                </Col>
                                <Col>
                                    <img src={infinityBadge} alt='' />
                                    <span className='boundAchiMedalTxt'>Infinity Badge</span>
                                </Col>
                            </Row>
                        </div>
                        <p className='boundBottomTxt'>Together we reached the final destination in this constellation.
                            All the planets were fun, weren't they? And You have been an amazing company.
                            <br /><br />
                            You've earned your medals and flown through all the planets in this galaxy, gaining valuable insights about yourself, your barriers, and how to present yourself, communicate, and express your ideas. Your final mission was to send a strong signal – an elevator pitch video – to the far corners of the universe. We hope you found all the information helpful.
                            <br /><br />
                            We're offering you a chance to improve your score if you're interested. Take the final assessment test to let us evaluate your progress and see how much you've grown throughout your journey.
                            <br /><br />
                            Why strive for a better score? Because only the gold medalists will be invited to compete in the European championship with their current or new elevator pitch.
                            <br /><br />
                            So, pilots, are you up for the challenge?</p>
                        <div className="buttonWrapperMaxp mr24" onClick={showCertificate} data-html2canvas-ignore>
                            <div className="buttonSimple-3Maxp">
                                DOWNLOAD DIPLOMA <i className="bi bi-cloud-download"></i>
                            </div>
                        </div>
                        <div className={"buttonWrapperMaxp " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }} data-html2canvas-ignore onClick={startSelfAssTest}>
                            <div className={"button2Maxp " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                GO TO SELF ASSESSMENT TEST <i className="bi bi-chevron-right"></i>
                            </div>
                        </div>
                        <div className='boundShareBtn' data-html2canvas-ignore>
                            <h4 className='elevPitchSocialTitle'>Share Achievement with other Pilots</h4>
                            <div className='elevPitchSocialBtn'>
                                <a href='#'>
                                    <img src={discordBtn} alt='' />
                                </a>
                                <a href='#'>
                                    <img src={facebookBtn} alt='' />
                                </a>
                                <a href='#'>
                                    <img src={linkedInBtn} alt='' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal className='certificatModal' size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div ref={printRef}>
                            <div className='certificateTemp'>
                                <div className='certificatUserData'>
                                    {userAvatarImgTag}
                                    <h4 className='boundCertPilot'>PILOT</h4>
                                    <h4 className='boundCertUserName'>{userFirstName + ' ' + userLastName}</h4>
                                    <div className='boundCertUserCountry'>
                                        <img src={userData.countryFlag} alt='flag' />
                                        <h4 className='boundCertCity'>{userData.userLocation}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="buttonWrapperMaxp" onClick={downloadPdf} data-html2canvas-ignore>
                            <div className="buttonSimple-3Maxp">
                                DOWNLOAD DIPLOMA <i className="bi bi-cloud-download"></i>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div >
            <SelfAssesmentTest2
                show={showSat}
                close={handleCloseSat}
                userId={userData.id}
            />
        </>
    );
}

export default Boundary;