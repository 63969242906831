import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonMoranon.css';
import moranonHeaderImg from '../lessons_img/lessonMoranon_1/moranonSmallBg.png';
import picture1b from '../lessons_img/lessonMoranon_2/picture1b.jpg';
import picture2b from '../lessons_img/lessonMoranon_2/picture2b.jpg';
import picture3b from '../lessons_img/lessonMoranon_2/picture3b.jpg';
import picture4b from '../lessons_img/lessonMoranon_2/picture4b.jpg';
import placeHoldImg from '../lessons_img/lessonArsirf_3/placeHoldImg.png';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import moranonMedal from '../lessons_img/lessonMoranon_2/moranonMedal.png';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';
import ModuleSpecificQuestionsModal from '../../modals/ModuleSpecificQuestionsModal';

function LessonMoranon_2() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [btnDisablePractice3, setShowLessonBtnPractice3] = useState('none');
    const [disabledBtnPractice3, setDisableBtnPractice3] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [showMsq, setShowMsq] = useState(false);

    const handleCloseMsq = () => setShowMsq(false);
    const handleShowMsq = () => setShowMsq(true);

    function buttonUrl() {
        if (userData.modulSpecQuestion === 4) {
            handleShowMsq();
        } else {
            navigate('/lesson-Myrthyr-1');
        }
    }

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [quizAnswer1, setQuizAnswer1] = useState('');
    const [quizAnswer2, setQuizAnswer2] = useState('');
    const [quizAnswer3, setQuizAnswer3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 5 && userData.userPracticeId >= 18 && userData.quizTotal >= 4) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '5';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '5';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '17') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
                if (practiceid === '18') {
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    //QUIZ SUBMIT
    function submitQuiz(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const quizid = event.target.getAttribute('quizid');
        const quizTitle = event.target.getAttribute('name');
        const lessonNumber = '5';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const quizQuestion = $('#' + formId + ' .quizQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [quizAnswer1, quizAnswer2, quizAnswer3];

        axios.post('https://maxp.quadrixsoft.com/api/usersQuiz.php', { 'userId': userData.id, 'quizid': quizid, 'quizTitle': quizTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'quizQuestion': quizQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (quizid === '4') {
                    setShowLessonBtnPractice3('none');
                    setDisableBtnPractice3('disabledBtn');
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END QUIZ SUBMIT

    return (
        <>
            <div className='lessonMoranonPage'>
                <div className='lessonMoranonHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Moranon' >Moranon</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 2</span>
                    </div>
                    <img src={moranonHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO MORANON</p>
                    <h2 className='lessHeaderTitle'>Work with others</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 2
                        </div>
                        <h4 className='lessTitle'>Budgeting,- You, 3Fs and others institution</h4>
                        <img src={picture1b} className='lessImg' alt='' />
                        <p className='lessTxt'>The tricky one of the 6 above-mentioned resources is money. Money will be everywhere, in your personal life, in your family life, and in your work life, so it is important to learn its value and work with it - that skill is called financial literacy.</p>
                        <div className='lessBoxTxtImg'>
                            <div className='lessBoxTxtImgSec'>
                                <h4 className='lessBoxTxtImgSecTitle'>50-20-30 Budget Rule</h4>
                            </div>
                            <Row className='lessBoxTxtImgSecRow m0 bor-none'>
                                <Col md={6} className='p0'>
                                    <img src={placeHoldImg} className='lessBoxTxtImgSecImg' alt='' />
                                </Col>
                                <Col md={6}>
                                    <p className='lessTxt m0'>The basics of budgeting are essential for your personal and work life.
                                        <br /><br />
                                        Elizabeth Warren popularized the so-called "50/20/30 budget rule" (sometimes labeled "50-30-20") in her book. The 50-20-30 budget rule is an intuitive and simple plan.
                                        The rule states that you should spend up to 50% of your after-tax income on needs and obligations (food, rent, utilities, etc).</p>
                                </Col>
                            </Row>
                            <p className='lessTxt m0'>The remaining half should be split up between 20% to investments, savings, and debt repayment and 30% to everything else that you might want (cinema, new clothes). The rule is a template that is intended to help individuals manage their money and save for emergencies and retirement. Life should be enjoyed, and it is not recommended to live like a Spartan, but having a plan and sticking to it will allow you to cover your expenses, and save for retirement, all at the same time doing the activities that make you happy.</p>
                        </div>
                        <img src={picture2b} className='lessImg' alt='' />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 5.B</h4>
                                    </div>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMoranon mb32'>
                            <Form name='Practice 5.B' onSubmit={submit} practiceid='17' id='17'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Can you write down your finances describing your last month with the rule 50-30-20?  Did you achieve it, or was your percentage off?</li>
                                        </ul>
                                        <Form.Control inputques="Can you write down your finances describing your last month with the rule 50-30-20?  Did you achieve it, or was your percentage off?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac5B' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac5B').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <h4 className='lessSubtitle'>Where to get money? 3F and more</h4>
                        <img src={picture3b} className='lessImg' alt='' />
                        <p className='lessTxt'>Let’s say you want to create an event, start with your spaceship or go for a trip around the world. Realize these ventures could cost some money and usually, you might not have it all. So the question that pops up is - Where are you going to get the money?
                            <br /><br />
                            Well, there is you, 3 F - family, friends, fools, and other institutions. Read more below </p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>You, pilot</h4>The first place you should look at is always your pockets and your own funds. Always try to be financially independent.
                                    <br /><br />
                                    Whenever you cannot afford something, there is 3F you can go to first.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>The family</h4>Could be the second source of money. Your family members know you and trust you and you can also negotiate with them better deals than you would get from a Bank. Or for example in exchange for work in the family they will contribute to your plan.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Friends</h4>are the third option and the situation here could get more complicated. Some may not trust you as much or they might start looking for some advantage for themself instead. Obviously, not every friend would do this, just when it comes to money from friends, be more cautious.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Fools</h4>are people who can buy your idea before the whole project is ready to launch and they are not in one of the above-mentioned categories. You can find them throught fundraisers, crowdfunding campaigns, and public social media groups. Don't get confused by the category name, they are not fools.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'><h4 className='lessSubtitle'>Other Institutions</h4>Such as Banks, Angel Investors, Venture capital firms, government programs, etc. These might be a bit harder to “convince” but it's definitely possible. You'll need a concrete plan as their bottom line will be to make more money by investing in you.</p>
                            </div>
                        </div>
                        <img src={picture4b} className='lessImg' alt='' />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 5.C</h4>
                                    </div>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMoranon mb32'>
                            <Form name='Practice 5.C' onSubmit={submit} practiceid='18' id='18'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Think about the idea that you had in Lesson 2 and Lesson 3. Pick one and write down where you would look for money to make the idea a reality.</li>
                                        </ul>
                                        <Form.Control inputques="Think about the idea that you had in Lesson 2 and Lesson 3. Pick one and write down where you would look for money to make the idea a reality." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Think of two things that would intrigue the fools and others to invest in you and your idea.</li>
                                        </ul>
                                        <Form.Control inputques="Think of two things that would intrigue the fools and others to invest in you and your idea." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac5C' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitPrac5C').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className='lessPracticeHeader headerQuiz'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-mortarboard"></i>
                                        <h4 className='lessPracticeTitle'>Moranon Quiz</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear Pilot, practice makes perfect so here is a little quiz:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMoranon mb32'>
                            <Form name='Moranon Quiz' onSubmit={submitQuiz} quizid='4' id='4'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv'>
                                            <ul>
                                                <li className='quizQuestion'>What is not part of the 3F?</li>
                                            </ul>
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Friends' value='Friends' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Fool' value='Fool' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Family' value='Family' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Financial literacy' value='Financial literacy' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv br0'>
                                            <ul>
                                                <li className='quizQuestion'>What is the first place you should look for resources for your spaceship? </li>
                                            </ul>
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='Own pocket/own resources' value='Own pocket/own resources' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='3F' value='3F' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='Crime' value='Crime' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv bbr'>
                                            <ul>
                                                <li className='quizQuestion'>What is time management? </li>
                                            </ul>
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='Type of procrastination' value='Type of procrastination' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='The technique of organizing and planning your own time' value='The technique of organizing and planning your own time' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='Doing less in more time' value='Doing less in more time' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox m0' type='radio' label='Having no control over your time' value='Having no control over your time' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitMoranonQuiz' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice3} onClick={() => { document.getElementById('submitMoranonQuiz').click() }} style={{ pointerEvents: btnDisablePractice3 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice3} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Moranon you should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Understand the concept of budgeting and its importance</li>
                                    <li>Understand the concept of 3F</li>
                                    <li>Explain what resources are important for the spaceship and personal growth</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://www.investopedia.com/ask/answers/022916/what-502030-budget-rule.asp' target='_blank' >investopedia.com</a>
                                        <a className='lessResourcesUrl' href='https://www.investopedia.com/budgeting-calculator-5101411' target='_blank'>investopedia.com/budgeting-calculator</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecMoranon'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={moranonHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Moranon</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col>
                                        <h4 className='lessFin'>3/3</h4>
                                        <p className='lessP'>Practice</p>
                                    </Col>
                                    <Col>
                                        <h4 className='lessFin'>1/1</h4>
                                        <p className='lessP'>Quiz</p>
                                    </Col>
                                    <Col>
                                        <img className='alzubraMedal' src={moranonMedal} alt='' />
                                        <p className='lessP'>Moranon Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={buttonUrl} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <p className='chapterNum '>Visit Myrthyr</p>
                                                <h4 className='chapterTitle '>DEVELOPE A PLAN</h4>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <i className="bi bi-arrow-right"></i>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModuleSpecificQuestionsModal
                show={showMsq}
                close={handleCloseMsq}
            />
        </>
    );
}

export default LessonMoranon_2;