import '../lessons_css/LessonAlzubra.css';
import alzubraHeaderImg from '../lessons_img/lessonAlzubra_1/alzubraSmallBg.png';
import lessonImg1a from '../lessons_img/lessonAlzubra_2/img1a.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import alzubraMedal from '../lessons_img/lessonAlzubra_2/alzubraMedal.png';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';
import ModuleSpecificQuestionsModal from '../../modals/ModuleSpecificQuestionsModal';

function LessonsAlzubra_2() {

    const location = useLocation();
    const navigate = useNavigate();

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    //Self Assesment Test PopUp
    const [showSat, setShowSat] = useState(false);

    const handleCloseSat = () => setShowSat(false);
    const handleShowSat = () => setShowSat(true);
    //END Self Assesment Test PopUp

    const [showMsq, setShowMsq] = useState(false);

    const handleCloseMsq = () => setShowMsq(false);
    const handleShowMsq = () => setShowMsq(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {

        if (!encryptedObject) {
            handleShowSat();
        } else {
            const secret = localStorage.getItem("hash");
            const decryptedUserData = decrypt(encryptedObject, secret);
            setUserData(decryptedUserData);
        }
    }, []);

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [practice3, setPractice3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    useEffect(() => {
        const unregisteredUser = localStorage.getItem("unregisteredUserData");

        if (!encryptedObject && !unregisteredUser) {
            handleShowSat();
        } else {

            switch (userData.selfAssTest) {
                case '0':
                    setTimeout(function () {
                        handleShowSat();
                    }, 1000);
                    break;
                case '1':
                    handleCloseSat();
                    break;
                case '2':
                    handleCloseSat();
                    break;
            }

            if (userData.userLessonId >= 1 && userData.userPracticeId >= 3) {
                setShowLessonBtnNext('');
                setDisableBtnNext('');
            } else {
                setShowLessonBtnNext('none');
                setDisableBtnNext('disabledBtn');
            }
        }
    }, [userData]);

    function buttonUrl() {
        if (userData.modulSpecQuestion === 0) {
            handleShowMsq();
        } else {
            navigate('/lesson-Najima-1');
        }
    }

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '1';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '1';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2, practice3];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '2') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }

                if (practiceid === '3') {
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='lessonAlzubraPage'>
                <div className='lessonAlzubraHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Alzubra' >Alzubra</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 2</span>
                    </div>
                    <img src={alzubraHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO ALZUBRA</p>
                    <h2 className='lessHeaderTitle'>Know Yourself and
                        work on yourself</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 2
                        </div>
                        <h4 className='lessTitle'>How do you learn and when is it over?</h4>
                        <img className='lessImg' src={lessonImg1a} alt='' />
                        <p className='lessTxt'>In this chapter, we will focus on learning strategies, because learning is one of the best ways to overcome most of the barriers mentioned in the previous chapter.</p>
                        <h4 className='lessSubtitle'>Learning and learning styles</h4>
                        <p className='lessTxt'>Knowing yourself is difficult and has many parts; however, self-discovery is a really important process, where you also need to discover your most effective way to learn.
                            <br /><br />
                            The process of learning in pilot lives never stops. In a world where we are overwhelmed by the amount of accessible information and education, it's really important to start choosing.
                            <br /><br />
                            Everyone learns differently, some learn through reading, some through visuals, and some from listening, you have to find the right means for you. It is important to know who you are and how to combine it. These are 7 common learning styles:</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Aural learners - prefer using sound and music to learn</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Verbal learners - learn best through speech and writing</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Physical learners - do best using a "hands-on" approach</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Visual learners - prefer learning using pictures and images</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Logical learners - learn best through reasoning (tend to do well in math)</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Solitary learners - do best through self-paced studying</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>Social learners - like to learn in groups with others</p>
                            </div>
                        </div>
                        <p className='lessTxt'>Of those styles, only verbal learners are well served in the traditional style of education where the teacher stands at the front of the classroom and explains concepts.
                            <br /><br />
                            Even though the situation in education is changing, you have the chance to find out for yourself what style of education works best and quickest for you. Don't be afraid to try a new style or to mix some of those above. Every pilot learns differently.
                            <br /><br />
                            Pilots, stay curious, ask and keep on learning. That's the only way to enjoy flying in this universe.</p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 1.B</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>So, now it's time to take things into your hands, pilot, and find out about yourself by asking the right questions. That leads us to another practice.<br />
                                        Answer these questions honestly :</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyAlz'>
                            <Form name='Practice 1.B' onSubmit={submit} practiceid='2' id='2'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>What learning style works for you? Aural, Verbal, physical, visual, logical, solitary, social, perhaps a combination of a few of the mentioned styles?</li>
                                        </ul>
                                        <Form.Control inputques='What learning style works for you? Aural, Verbal, physical, visual, logical, solitary, social, perhaps a combination of a few of the mentioned styles?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '192px', resize: 'none' }} required onChange={(e) => {
                                            setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv' style={{ marginBottom: '0' }}>
                                        <ul>
                                            <li className='practiceQuestion'>How did you study for your last school test?</li>
                                        </ul>
                                        <Form.Control inputques='How did you study for your last school test?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '192px', resize: 'none' }} required onChange={(e) => {
                                            setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i>
                                        <span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac1B' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac1B').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <h4 className='lessSubtitle'>You are responsible for your education and learning,
                            not anyone else.</h4>
                        <p className='lessTxt'>Sometimes we might feel like the odds are against us, but take responsibility and own it. Teachers and educators aren't responsible for the students learning, their responsibility is to create the opportunity for students to learn.</p>
                        <div className='lessQuote'>
                            <i className="bi bi-quote"></i>
                            <div className='lessQuoteRdiv'>
                                <h4>The beautiful thing about learning is that no one can take it away from you.</h4>
                                <p>B.B. King</p>
                            </div>
                        </div>
                        <p className='lessTxt'>The fact is that you - the pilot, are responsible for your education and therefore your personal growth. You are not doing it for anyone else, you shall be your own motivation. Sometimes you might be even surprised that learning is fun, especially if you connect education with your hobbies and the areas that are interesting to you.</p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 1.C</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>It's time for another practice. Think about your hobbies and answer the following questions.</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyAlz'>
                            <Form name='Practice 1.C' onSubmit={submit} practiceid='3' id='3'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>What is your favorite hobby?</li>
                                        </ul>
                                        <Form.Control inputques='What is your favorite hobby?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>What did you learn through your hobby and how did you learn it?</li>
                                        </ul>
                                        <Form.Control inputques='What did you learn through your hobby and how did you learn it?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice2(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv' style={{ marginBottom: '0' }}>
                                        <ul>
                                            <li className='practiceQuestion'>Compare and contrast your learning process for your hobby and school assignments. Is there a difference in your approach? Can your preferred method be used for future academic assignments?</li>
                                        </ul>
                                        <Form.Control inputques='Compare and contrast your learning process for your hobby and school assignments. Is there a difference in your approach? Can your preferred method be used for future academic assignments?' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice3(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2('');
                                        }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac1C' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitPrac1C').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Alzubra you
                                    should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Know yourself better - be more self-aware, know your strengths, weaknesses and be able to name them</li>
                                    <li>Find fun parts in learning and see education as an opportunity, not as something you have to do.</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://apa.org'>apa.org</a>
                                        <a className='lessResourcesUrl' href='https://verywellmind.com'>verywellmind.com</a>
                                        <a className='lessResourcesUrl' href='https://whattheythink.com'>whattheythink.com</a>
                                        <a className='lessResourcesUrl' href='https://apa.org/news/press/releases'>apa.org/news/press/releases</a>
                                        <a className='lessResourcesUrl' href='https://wealest.com'>wealest.com</a>
                                        <a className='lessResourcesUrl' href='https://psychcentral.com'>psychcentral.com</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecAlzubra'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={alzubraHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Alzubra</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col>
                                        <h4 className='lessFin'>3/3</h4>
                                        <p className='lessP'>Practice</p>
                                    </Col>
                                    <Col>
                                        <img className='alzubraMedal' src={alzubraMedal} alt='' />
                                        <p className='lessP'>Alzubra Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={buttonUrl} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <p className='chapterNum '>Visit Najima</p>
                                                <h4 className='chapterTitle '>BE IN TRENDY</h4>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <i className="bi bi-arrow-right"></i>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ModuleSpecificQuestionsModal
                show={showMsq}
                close={handleCloseMsq}
            />
        </>
    );
}

export default LessonsAlzubra_2;