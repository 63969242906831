import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonArsirf.css';
import arsirfHeaderImg from '../lessons_img/lessonArsirf_1/arsirfSmallBg.png';
import arsirfMedal from '../lessons_img/lessonArsirf_3/arsirfMedal.png';
import picture1c from '../lessons_img/lessonArsirf_3/picture1c.jpg';
import picture2c from '../lessons_img/lessonArsirf_3/picture2c.jpg';
import placeHoldImg from '../lessons_img/lessonArsirf_3/placeHoldImg.png';
import boxIco1 from '../lessons_img/lessonArsirf_3/g2330.png';
import boxIco2 from '../lessons_img/lessonArsirf_3/g1462.png';
import boxIco3 from '../lessons_img/lessonArsirf_3/g1245.png';
import boxIco4 from '../lessons_img/lessonArsirf_3/g3445.png';
import { Col, Form, Row } from 'react-bootstrap';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';
import ModuleSpecificQuestionsModal from '../../modals/ModuleSpecificQuestionsModal';

function LessonArsirf_3() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [showMsq, setShowMsq] = useState(false);

    const handleCloseMsq = () => setShowMsq(false);
    const handleShowMsq = () => setShowMsq(true);

    function buttonUrl() {
        if (userData.modulSpecQuestion === 3) {
            handleShowMsq();
        } else {
            navigate('/lesson-Moranon-1');
        }
    }

    const [practice1, setPractice1] = useState('');
    const [quizAnswer1, setQuizAnswer1] = useState('');
    const [quizAnswer2, setQuizAnswer2] = useState('');
    const [quizAnswer3, setQuizAnswer3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 4 && userData.userPracticeId >= 15 && userData.quizTotal >= 3) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '15') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    //QUIZ SUBMIT
    function submitQuiz(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const quizid = event.target.getAttribute('quizid');
        const quizTitle = event.target.getAttribute('name');
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const quizQuestion = $('#' + formId + ' .quizQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [quizAnswer1, quizAnswer2, quizAnswer3];

        axios.post('https://maxp.quadrixsoft.com/api/usersQuiz.php', { 'userId': userData.id, 'quizid': quizid, 'quizTitle': quizTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'quizQuestion': quizQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (quizid === '3') {
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                    setShowLessonBtnNext('');
                    setDisableBtnNext('');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END QUIZ SUBMIT

    return (
        <>
            <div className='lessonArsirfPage'>
                <div className='lessonArsirfHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Arsirf' >Arsirf</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 3</span>
                    </div>
                    <img src={arsirfHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO ARSIRF</p>
                    <h2 className='lessHeaderTitle'>Work with others</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 3
                        </div>
                        <h4 className="lessTitle">Leadership</h4>
                        <img src={picture1c} className='lessImg' alt='' />
                        <p className='lessTxt'>Every project needs a bit of leadership, that is the ability of an individual (or a group) to influence and guide. Leadership is far more than just making smart decisions. Designing a spaceship or starting something new requires courage, charisma and great leadership to persuade those around you to join your team or believe in your vision.
                            <br /><br />
                            When leading a spaceship, you should begin by asking yourself, "What kind of pilot am I?" "What kind of leadership does my team require?" "What leadership style should I use to achieve my goal?" These are essential self-diagnosis questions that you should be asking when taking on a project. </p>
                        <h4 className='lessSubtitle'>Know your capabilities</h4>
                        <p className='lessTxt'>As we spoke about in Lesson 1, To know yourself is essential and it's important, to be aware of your leadership skills and capabilities.
                            <br /><br />
                            Pilot, before you can fly and lead, you need to know yourself first. We already acknowledge that, but repetition and practice make perfect.  For every pilot with leadership skills, It's important to understand the motivations of different people around you and the dynamic that occurs between personalities.</p>
                        <div className='lessBoxTxtImg'>
                            <h4 className='lessSubtitle lessBoxTxtImg_Title'>Let us introduce you to 4 types of leadership roles:</h4>
                            <div className='lessBoxTxtImgSec'>
                                <img src={boxIco1} className='lessBoxTxtImgImg' alt='' />
                                <h4 className='lessBoxTxtImgSecTitle'>The Analyst</h4>
                            </div>
                            <Row className='lessBoxTxtImgSecRow'>
                                <Col md={6} className='p0'>
                                    <img src={placeHoldImg} className='lessBoxTxtImgSecImg' alt='' />
                                </Col>
                                <Col md={6}>
                                    <p className='lessTxt m0'>The analyst type is independent, open-minded, and strong-willed. They use their imaginations in solutions and look at things from an objective perspective.
                                        <br /><br />
                                        Analysts are thriving in technical fields embracing rationality. With their excellent strategic thinkers, they can be socially difficult however this personality type could lead to more productivity and organization in the workplace.</p>
                                </Col>
                            </Row>
                            <div className='lessBoxTxtImgSec'>
                                <img src={boxIco2} className='lessBoxTxtImgImg' alt='' />
                                <h4 className='lessBoxTxtImgSecTitle'>The Diplomat</h4>
                            </div>
                            <Row className='lessBoxTxtImgSecRow'>
                                <Col md={6} className='p0'>
                                    <p className='lessTxt m0'>The diplomat is warm, empathetic, tactful, and uses the right cues.
                                        <br /><br />
                                        They have a strong supportive influence on office
                                        politics and harmony.
                                        <br /><br />
                                        When the Diplomat is asked to make a difficult decision, this type relies on cold rationality.</p>
                                </Col>
                                <Col md={6} className='p0'>
                                    <img src={placeHoldImg} className='lessBoxTxtImgSecImg floatR' alt='' />
                                </Col>
                            </Row>
                            <div className='lessBoxTxtImgSec'>
                                <img src={boxIco3} className='lessBoxTxtImgImg' alt='' />
                                <h4 className='lessBoxTxtImgSecTitle'>The Sentinel</h4>
                            </div>
                            <Row className='lessBoxTxtImgSecRow'>
                                <Col md={6} className='p0'>
                                    <img src={placeHoldImg} className='lessBoxTxtImgSecImg' alt='' />
                                </Col>
                                <Col md={6}>
                                    <p className='lessTxt m0'>This personality type is hard-working and precise. They create stability in the workplace and excel in logistics and administrative roles.
                                        <br /><br />
                                        The Sentinels' personalities do not run away from difficult tasks and stick to the plans that can make them sometimes uncompromising.
                                        <br /><br />
                                        Sentinel as leaders aim to protect and serve can be too strict about rules and protocols.</p>
                                </Col>
                            </Row>
                            <div className='lessBoxTxtImgSec'>
                                <img src={boxIco4} className='lessBoxTxtImgImg' alt='' />
                                <h4 className='lessBoxTxtImgSecTitle'>The Explorer</h4>
                            </div>
                            <Row className='lessBoxTxtImgSecRow m0 bor-none'>
                                <Col md={6} className='p0'>
                                    <p className='lessTxt m0'>The masters of tools (learning, mastering)  and techniques.
                                        <br /><br />
                                        They are quick on their feet, pragmatic and spontaneous therefore they can be irreplaceable in crises but push towards taking on big risks.
                                        <br /><br />
                                        As leaders, explorers are quick to find solutions and can drive an organization in a new direction. </p>
                                </Col>
                                <Col md={6} className='p0'>
                                    <img src={placeHoldImg} className='lessBoxTxtImgSecImg floatR' alt='' />
                                </Col>
                            </Row>
                        </div>
                        <img src={picture2c} className='lessImg' alt='' />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 4.E</h4>
                                    </div>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyArsirf mb32'>
                            <Form name='Practice 4.E' onSubmit={submit} practiceid='15' id='15'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Try to think about the best leaders in history and pinpoint their tactics and mistakes. Write down who is the best leader according to you, why did you pick them and what you think is their dominant leadership role. </li>
                                        </ul>
                                        <Form.Control inputques="Try to think about the best leaders in history and pinpoint their tactics and mistakes. Write down who is the best leader according to you, why did you pick them and what you think is their dominant leadership role. " as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac4E' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac4E').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className='lessPracticeHeader headerQuiz'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-mortarboard"></i>
                                        <h4 className='lessPracticeTitle'>Arsirf Quiz</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear Pilot, practice makes perfect so here is a little quiz:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyArsirf mb32'>
                            <Form name='Arsirf Quiz' onSubmit={submitQuiz} quizid='3' id='3'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv'>
                                            <ul>
                                                <li className='quizQuestion'>“Assertive communicators have a natural sense of self-esteem and do not engage in passive or aggressive communication”.</li>
                                            </ul>
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='checkbox' label='True' value='True' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='checkbox' label='False' value='False' onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv br0'>
                                            <ul>
                                                <li className='quizQuestion'>Networking is the action/process of interacting with others to exchange information and develop professional/social contacts. </li>
                                            </ul>
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='checkbox' label='True' value='True' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='checkbox' label='False' value='False' onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv bbr'>
                                            <ul>
                                                <li className='quizQuestion'>What are the qualities of The Analyst type of Leadership role?</li>
                                            </ul>
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='checkbox' label='Independent, open-minded, and strong-willed' value='Independent, open-minded, and strong-willed' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='checkbox' label='Warm, emphatic' value='Warm, emphatic' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox m0' type='checkbox' label='Spontaneous' value='Spontaneous' onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitArsirfQuiz' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitArsirfQuiz').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Arsirf you should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Identify your communication style and how you employ it in your daily interactions.</li>
                                    <li>Familiarize yourself with different communication techniques that will guide you in relating with others.</li>
                                    <li>Recognize the significance of networking and apply the basic networking guidelines at your next networking event.</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://www.ideo.org/perspective/the-material-effects-of-stories' target='_blank' >Ideo</a>
                                        <a className='lessResourcesUrl' href='https://www.valamis.com/hub/communication-styles#types' target='_blank'>valamis.com</a>
                                        <a className='lessResourcesUrl' href='https://changecreator.com/how-social-entrepreneurs-must-communicate-for-maximum-impact/' target='_blank'>changecreator.com</a>
                                        <a className='lessResourcesUrl' href='https://www.16personalities.com/personality-types' target='_blank'> 16personalities.com</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecArsirf'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={arsirfHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Arsirf</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col>
                                        <h4 className='lessFin'>5/5</h4>
                                        <p className='lessP'>Practice</p>
                                    </Col>
                                    <Col>
                                        <h4 className='lessFin'>1/1</h4>
                                        <p className='lessP'>Quiz</p>
                                    </Col>
                                    <Col>
                                        <img className='alzubraMedal' src={arsirfMedal} alt='' />
                                        <p className='lessP'>Arsirf Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={buttonUrl} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <p className='chapterNum '>Visit Moranon</p>
                                                <h4 className='chapterTitle '>FINDING RESOURCES</h4>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <i className="bi bi-arrow-right"></i>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModuleSpecificQuestionsModal
                show={showMsq}
                close={handleCloseMsq}
            />
        </>
    );
}

export default LessonArsirf_3;