import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonMyrthyr.css';
import myrthyrHeaderImg from '../lessons_img/lessonMyrthyr_1/myrthyrSmallBg.png';
import picture1b from '../lessons_img/lessonMyrthyr_2/picture1b.jpg';
import picture2b from '../lessons_img/lessonMyrthyr_2/picture2b.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import swotIco from '../lessons_img/lessonMyrthyr_2/swotIco.png';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';

function LessonMyrthyr_2() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [spanClickVal, setSpanClickVal] = useState('');
    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [practice3, setPractice3] = useState('');
    const [practice4, setPractice4] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 6 && userData.userPracticeId >= 20) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '6';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2, practice3, practice4];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '20') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    return (
        <>
            <div className='lessonMyrthyrPage'>
                <div className='lessonMyrthyrHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Myrthyr' >Myrthyr</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 2</span>
                    </div>
                    <img src={myrthyrHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO MYRTHYR</p>
                    <h2 className='lessHeaderTitle'>Develop a plan</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 2
                        </div>
                        <h4 className='lessTitle'>SWOT analysis  - a secret code that will help you plan better</h4>
                        <img src={picture1b} className='lessImg' alt='' />
                        <p className='lessTxt'>The SWOT analysis (stands for Strengths, Weaknesses, Opportunities, and Threats) is a framework used to evaluate a position or situation. It can also be understood as a process in which the pilot identifies the internal and external factors that affect the spaceship, as well as current and future potential.
                            <br /><br />
                            Use this tool as a guide and inspiration. </p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Strengths</h4>Strengths describe what a spaceship excels at and what separates it from others: a strong pilot, unique technology, and so on. It also describes internal capabilities and positive features of an organization.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Weaknesses</h4>Weaknesses stop spaceships and pilots from performing at their optimum level. In every spaceship plan, there are areas where the pilot needs to improve - a weak spaceship plan, lack of capital, and so on. In other words, weaknesses are internal factors or constraints which might impede or hinder the performance of the spaceship.
                                    <br /><br />
                                    Therefore, strengths and weaknesses are internal elements.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Opportunities</h4>Opportunities in the SWOT analysis refer to favorable external factors that could give a spaceship a competitive advantage or simply just more options to fly further.  Opportunities are external factors through which spaceships can exploit their advantages.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Threats</h4>Threats refer to factors that have the potential to harm a spaceship. It deals with negative factors external to the spaceship, which can hinder or delay the achievable goals. As such, opportunities and threats are viewed as environmental factors.
                                    <br /><br />
                                    So, pilot, SWOT analysis is a strategic planning technique that provides assessment tools. Don't forget to be honest and don't sugarcoat yourself while doing yours.</p>
                            </div>
                        </div>
                        <div className='lessSwotTable'>
                            <div className='swotTitleDiv'>
                                <img src={swotIco} alt='' />
                                <h4 className='lessSubtitle'>SWOT Table</h4>
                            </div>
                            <Row className='lessSwotTableRow'>
                                <Col className='lessSwotTableCol1'>
                                    <h4 className='lessSubtitle'>Strengths</h4>
                                    <ul>
                                        <li>What is our competitive advantage?</li>
                                        <li>What resources do we have? </li>
                                        <li>What products are performing well?</li>
                                    </ul>
                                </Col>
                                <Col className='lessSwotTableCol2'>
                                    <h4 className='lessSubtitle'>Weaknesses</h4>
                                    <ul>
                                        <li>Where can we improve? </li>
                                        <li>What products are underperforming? </li>
                                        <li>Where are we lacking resources?</li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className='lessSwotTableRow lessSwotTableRow2'>
                                <Col className='lessSwotTableCol1'>
                                    <h4 className='lessSubtitle'>Threats</h4>
                                    <ul>
                                        <li>What new regulations threaten operations?</li>
                                        <li>What do our competitors do well? </li>
                                        <li>What consumer trends threaten business?</li>
                                    </ul>
                                </Col>
                                <Col className='lessSwotTableCol2'>
                                    <h4 className='lessSubtitle'>Opportunities</h4>
                                    <ul>
                                        <li>What technology can we use to improve operations? </li>
                                        <li>Can we expand our core operations?  </li>
                                        <li>What new market segments can we explore?</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        <img src={picture2b} className='lessImg' alt='' />
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 6.B</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Based on what you learned about yourself in Lesson 1 on the planet Alzubra, can you complete a SWOT analysis of yourself? Please fill in the provided framework with your strengths, weaknesses, opportunities, and threats.</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyMyrthyr mb32'>
                            <Form name='Practice 6.B' onSubmit={submit} practiceid='20' id='20'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className='practiceQuestion'>Strenghts</li>
                                        </ul>
                                        <Form.Control inputques="Strenghts" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className='practiceQuestion'>Weaknesses</li>
                                        </ul>
                                        <Form.Control inputques="Weaknesses" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className='practiceQuestion'>Opportunities</li>
                                        </ul>
                                        <Form.Control inputques="Opportunities" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice3(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className='practiceQuestion'>Threats</li>
                                        </ul>
                                        <Form.Control inputques="Threats" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice4(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac6B' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac6B').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonMyrthyrNextCh'>
                        <div className='lessonContainer'>
                            <img src={myrthyrHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessMyrthyrNextChTitle'>Second Chapter of Myrthyr</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Myrthyr-3')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Myrthyr-3'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>SMART GOALS</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Myrthyr-3'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonMyrthyr_2;