import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arsirfHeaderImg from '../lessons_img/lessonArsirf_1/arsirfSmallBg.png';
import '../lessons_css/LessonArsirf.css';
import picture1a from '../lessons_img/lessonArsirf_1/picture1a.jpg';
import picture2a from '../lessons_img/lessonArsirf_1/picture2a.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import { Col, Form, Row } from "react-bootstrap";
import { useEffect } from "react";
import $ from 'jquery';
import { decrypt, encrypt } from "n-krypta";
import axios from "axios";


function LessonArsirf_1() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 4 && userData.userPracticeId >= 12) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res.data.userPractice)
                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '4';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '11') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
                if (practiceid === '12') {
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='lessonArsirfPage'>
                <div className='lessonArsirfHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Arsirf' >Arsirf</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 1</span>
                    </div>
                    <img src={arsirfHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO ARSIRF</p>
                    <h2 className='lessHeaderTitle'>Work with others</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 1
                        </div>
                        <h4 className="lessTitle">The power of Communicating</h4>
                        <img src={picture1a} className='lessImg' alt='' />
                        <p className="lessTxt">Welcome to the fourth planet Arsirf. We will reveal the power of communication. We, pilots, are social creatures and it's important to be able to communicate and cooperate with others. Communication is power and it matters how you use it. Communication can shine a spotlight on problems or solutions, provide feedback and bring people together to collaborate.
                            <br /><br />
                            This planet will show you various tools, values, and guiding principles for communicating, networking, and leadership.</p>
                        <h4 className="lessSubtitle">What is your communication style? </h4>
                        <p className="lessTxt">Communication is an important part of our daily lives. The art of relating to others is dependent on your communication skills, so it's crucial that you become aware of how you relate to others. Pilot, remember - It's a skill, so even if you might have the feeling of not being 100% adapt at communicating, practice makes perfect.<br />
                            Here are some examples of communication styles:</p>
                        <iframe style={{ marginBottom: "32px" }} width="100%" height="423" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>The Assertive style of communication is strong in their beliefs but avoids humiliating others in the conversation. Assertive communicators have a natural sense of self-esteem and do not engage in passive or aggressive communication.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>The Aggressive style of communication can be hostile and threatening, and it stems from a desire to win at all costs. An aggressive communicator acts as if their contribution to the conversation is more important than anyone else's, and the content of their message is frequently lost due to the tone with which they deliver it.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>The Passive style of communication is also known as a submissive communicator and as the "people-pleaser" personality type. This style of communication is self-effacing, conflict-avoidant, and relaxed. Passive communicators tend to take a back seat and let others, who are more assertive or aggressive, take the lead.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>The Passive-Aggressive style of communication, as the name implies, combines elements of both passive and aggressive communication styles. A passive-aggressive communicator appears sweet and easygoing on the outside, but they are filled with rage and resentment. This comes out in the form of sarcasm, patronizing behavior, starting rumors, or gossiping.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>The Manipulative style of communication employs cunning, deception, and influence to manipulate the outcome of the conversation. Manipulative communicators rarely say what they mean; instead, they bury their true intentions beneath layers of sophistry in order to get their way without the other person realizing it.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className="lessTxt m0">The Manipulative style of communication employs cunning, deception, and influence to manipulate the outcome of the conversation. Manipulative communicators rarely say what they mean; instead, they bury their true intentions beneath layers of sophistry in order to get their way without the other person realizing it.</p>
                            </div>
                        </div>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 4.A</h4>
                                    </div>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyArsirf mb32'>
                            <Form name='Practice 4.A' onSubmit={submit} practiceid='11' id='11'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c mb32'>
                                        <ul>
                                            <li className="practiceQuestion">Based on the communication styles mentioned above, write what type of communicator you think you are. Then ask your family or friends if your perception was right. Write down what they said about your communication style</li>
                                        </ul>
                                        <Form.Control inputques="Based on the communication styles mentioned above, write what type of communicator you think you are. Then ask your family or friends if your perception was right. Write down what they said about your communication style" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">Which of those communication styles do you consider most effective and why?</li>
                                        </ul>
                                        <Form.Control inputques="Which of those communication styles do you consider most effective and why?" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac4A' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac4A').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <h4 className="lessSubtitle">Forget everything you knew about speaking</h4>
                        <img src={picture2a} className="lessImg" alt='' />
                        <p className="lessTxt">Do you want to approach someone who can help you with your spaceship whether it is your parents, teacher, friends, investors, or anyone else? You should be able to relate to them and empathize with their needs and interests. It's also important to overcome the digital and generational divides and include everyone.<br />
                            Here is a list of key communication techniques that you can keep in your bag for success!</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className="lessSubtitle">Observe the room and learn to read nonverbal cues</h4>Effective pilots are not only aware of their own gestures and facial expressions, but they can also read the room and interpret cues given by others.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className="lessSubtitle">Know your story, be yourself and understand your WHY</h4>With every spaceship, project, design, or solution to know your why (why are you doing it) is essential. It helps you to narrow down things when communicating your purpose to others. The more you are confident in your story; the more people will believe in your vision.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className="lessSubtitle">Sharpen your listening skills</h4> Many great pilots are also excellent listeners, capable of capturing the needs and insights of others. Listening well is the key to effective communication. It is important to give attention to others and listen.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'><h4 className="lessSubtitle">Timing</h4> Make sure that the people you are working with are up to the task; remember to always read the room. Is everyone around me bored? Or perhaps they don't understand what I am saying? Seek out those small opportunities that will pique the interest of your listeners. Looking for those small windows of opportunity can lead to incredible connections and insights. So, look for the right times to send an email, schedule a meeting, or dial the investor's phone number.</p>
                            </div>
                        </div>
                        <p className="lessTxt">Pilot, now you have those communication styles and techniques to keep in mind when dealing with others. When you first start out, it takes practice and courage to overcome communication barriers; additionally, when you become a great communicator, you will easily attract people who will believe in your mission! Take a chance!</p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 4.B</h4>
                                    </div>
                                    <p className="lessPracticeTxt">Pilot, we have covered tools and techniques that you can use in communicating effectively, now let’s put that into practice.  Consider the communication techniques you just learned in the previous text.</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyArsirf mb32'>
                            <Form name='Practice 4.B' onSubmit={submit} practiceid='12' id='12'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <ul>
                                            <li className="practiceQuestion">Pick a famous person who uses the Aggressive style of communication? Explain why. In contrast, which famous person uses the Passive communication style? Highlight the differences.</li>
                                        </ul>
                                        <Form.Control inputques="Pick a famous person who uses the Aggressive style of communication? Explain why. In contrast, which famous person uses the Passive communication style? Highlight the differences." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac4B' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitPrac4B').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonArsirfNextCh'>
                        <div className='lessonContainer'>
                            <img src={arsirfHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessArsirfNextChTitle'>First Chapter of Arsirf</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Arsirf-2')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Arsirf-2'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>NETWORKING</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Arsirf-2'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonArsirf_1;