import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonNajima.css';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import najimaHeaderImg from '../lessons_img/lessonNajima_1/najimaSmallBg.png';
import najimaMedal from '../lessons_img/lessonNajima_3/najimaMedal.png';
import picture1b from '../lessons_img/lessonNajima_3/picture1b.jpg';
import picture2b from '../lessons_img/lessonNajima_3/picture2b.jpg';
import card1Ico from '../lessons_img/lessonNajima_3/g709.png';
import card2Ico from '../lessons_img/lessonNajima_3/g710.png';
import card3Ico from '../lessons_img/lessonNajima_3/g727.png';
import card4Ico from '../lessons_img/lessonNajima_3/g109.png';
import card5Ico from '../lessons_img/lessonNajima_3/g711.png';
import card6Ico from '../lessons_img/lessonNajima_3/clean-water1.png';
import card7Ico from '../lessons_img/lessonNajima_3/clean-energy1.png';
import card8Ico from '../lessons_img/lessonNajima_3/g2477.png';
import card9Ico from '../lessons_img/lessonNajima_3/g1789.png';
import card10Ico from '../lessons_img/lessonNajima_3/g457.png';
import card11Ico from '../lessons_img/lessonNajima_3/g703.png';
import card12Ico from '../lessons_img/lessonNajima_3/g819.png';
import card13Ico from '../lessons_img/lessonNajima_3/g3566.png';
import $ from 'jquery';
import { decrypt, encrypt } from 'n-krypta';
import axios from 'axios';
import ModuleSpecificQuestionsModal from '../../modals/ModuleSpecificQuestionsModal';

function LessonNajima_3() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [btnDisablePractice2, setShowLessonBtnPractice2] = useState('none');
    const [disabledBtnPractice2, setDisableBtnPractice2] = useState('disabledBtn');
    const [btnDisablePractice3, setShowLessonBtnPractice3] = useState('none');
    const [disabledBtnPractice3, setDisableBtnPractice3] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [showMsq, setShowMsq] = useState(false);

    const handleCloseMsq = () => setShowMsq(false);
    const handleShowMsq = () => setShowMsq(true);

    function buttonUrl() {
        if (userData.modulSpecQuestion === 1) {
            handleShowMsq();
        } else {
            navigate('/lesson-Ester-1');
        }
    }

    const [spanClickVal, setSpanClickVal] = useState('');
    const [practice1, setPractice1] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);
    const [quizAnswer1, setQuizAnswer1] = useState('');
    const [quizAnswer2, setQuizAnswer2] = useState('');
    const [quizAnswer3, setQuizAnswer3] = useState('');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 2 && userData.userPracticeId >= 7 && userData.quizTotal >= 1) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();

                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                        if (inputQues[i] === 'accInput') {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="accInput"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').text(res.data.userPractice[i].practiceQuestion);
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .accordion-button').click();
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    // PRACTICE SUBMIT
    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '6') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }

                if (practiceid === '7') {
                    setShowLessonBtnPractice2('none');
                    setDisableBtnPractice2('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END PRACTICE SUBMIT

    //QUIZ SUBMIT
    function submitQuiz(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const quizid = event.target.getAttribute('quizid');
        const quizTitle = event.target.getAttribute('name');
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const quizQuestion = $('#' + formId + ' .quizQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [quizAnswer1, quizAnswer2, quizAnswer3];

        axios.post('https://maxp.quadrixsoft.com/api/usersQuiz.php', { 'userId': userData.id, 'quizid': quizid, 'quizTitle': quizTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'quizQuestion': quizQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (quizid === '1') {
                    setShowLessonBtnPractice3('none');
                    setDisableBtnPractice3('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    //END QUIZ SUBMIT

    function accHeaderClick() {
        const idForm = document.getElementById('6');
        idForm.querySelector('.accordion-button').click();
    }

    return (
        <>
            <div className='lessonNajimaPage'>
                <div className='lessonNajimaHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Najima' >Najima</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 3</span>
                    </div>
                    <img src={najimaHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO NAJIMA</p>
                    <h2 className='lessHeaderTitle'>Be in and Trendy</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 3
                        </div>
                        <h4 className='lessTitle'>Solving world problems through technology</h4>
                        <img className='lessImg' src={picture1b} alt='' />
                        <p className='lessTxt'>Many problems have been solved through technology and many more will. As we already explained, it's kind of the purpose of technology.</p>
                        <h4 className='lessSubtitle'>What are the prominent world challenges?</h4>
                        <p className='lessTxt'>Humanity is facing many challenges from food and drinking water insecurity, to natural disasters, climate change, discrimination, and war.  Pilot, maybe one day you can solve one of them.</p>
                        <h4 className='lessSubtitle'>Sustainability</h4>
                        <p className='lessTxt'>Sustainability is the capacity to endure and it's very important to think about it during your designs. It is more than a word, it's an action that can be applied almost everywhere. It can even become the main purpose of your spaceship.
                            <br /><br />
                            The world is facing environmental and social crises and people are demanding that companies take responsibility and action.
                            <br /><br />
                            In September 2015 The Seventeen Sustainable Development Goals (SDGs) were presented as the UN Development Program for the next 15 years (2015-2030). The 17 sustainable  development goals (SDGs):</p>
                        <Row className='lessCardsRow'>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>1</h4>
                                    <p className='lessCardsTxt'>No <br />Poverty</p>
                                </div>
                                <img className='lessCardsIco' src={card1Ico} alt='' />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>2</h4>
                                    <p className='lessCardsTxt'>Zero <br />Hunger</p>
                                </div>
                                <img className='lessCardsIco' src={card2Ico} alt='' />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>3</h4>
                                    <p className='lessCardsTxt'>Good Health<br /> and<br /> Well-being</p>
                                </div>
                                <img className='lessCardsIco' src={card3Ico} alt='' style={{ marginTop: '35px' }} />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>4</h4>
                                    <p className='lessCardsTxt'>Quality <br /> Education</p>
                                </div>
                                <img className='lessCardsIco' src={card4Ico} alt='' style={{ marginTop: '57px' }} />
                            </Col>
                        </Row>
                        <Row className='lessCardsRow'>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>5</h4>
                                    <p className='lessCardsTxt'>Gender <br />Equality</p>
                                </div>
                                <img className='lessCardsIco' src={card5Ico} alt='' />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>6</h4>
                                    <p className='lessCardsTxt'>Clean Water<br />and Sanitation</p>
                                </div>
                                <img className='lessCardsIco' src={card6Ico} alt='' style={{ marginTop: '57px' }} />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>7</h4>
                                    <p className='lessCardsTxt'>Affordable and<br /> Clean Energy</p>
                                </div>
                                <img className='lessCardsIco' src={card7Ico} alt='' style={{ marginTop: '57px' }} />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>8</h4>
                                    <p className='lessCardsTxt'>Decent Work<br />and Economic  <br /> Growth</p>
                                </div>
                                <img className='lessCardsIco' src={card8Ico} alt='' style={{ marginTop: '35px' }} />
                            </Col>
                        </Row>
                        <Row className='lessCardsRow'>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>9</h4>
                                    <p className='lessCardsTxt'>Industry,<br /> Innovation and<br />Infrastructure</p>
                                </div>
                                <img className='lessCardsIco' src={card9Ico} alt='' />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>10</h4>
                                    <p className='lessCardsTxt'>Reduced <br />Inequality</p>
                                </div>
                                <img className='lessCardsIco' src={card10Ico} alt='' style={{ marginTop: '59px' }} />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>11</h4>
                                    <p className='lessCardsTxt'>Sustainable<br /> Cities and <br /> Communities</p>
                                </div>
                                <img className='lessCardsIco' src={card11Ico} alt='' style={{ marginTop: '38px' }} />
                            </Col>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>12</h4>
                                    <p className='lessCardsTxt'>Responsible <br />Consumption<br /> and   <br /> Production</p>
                                </div>
                                <img className='lessCardsIco' src={card12Ico} alt='' style={{ marginTop: '19px' }} />
                            </Col>
                        </Row>
                        <Row className='lessCardsRow mb32'>
                            <Col className="lessCardsCol">
                                <div className='lessCardsDiv'>
                                    <h4 className='lessCardsNum'>13</h4>
                                    <p className='lessCardsTxt'>Partnerships<br />to achieve the<br /> Goal</p>
                                </div>
                                <img className='lessCardsIco' src={card13Ico} alt='' style={{ marginTop: '19px' }} />
                            </Col>
                            <Col className="lessCardsCol-hidden"></Col>
                            <Col className="lessCardsCol-hidden"></Col>
                            <Col className="lessCardsCol-hidden"></Col>
                        </Row>
                        <p className='lessTxt'>All of these goals could inspire you in your daily actions.</p>
                        <Form name='Practice 2.C' onSubmit={submit} practiceid='6' id='6'>
                            <div className='lessPracticeHeader'>
                                <Row>
                                    <Col md={10} className='lessPracticeHeaderCol'>
                                        <div className='lessPracticeHeaderTitleDiv'>
                                            <i className="bi bi-card-checklist"></i>
                                            <h4 className='lessPracticeTitle'>Practice 2.C</h4>
                                        </div>
                                        <p className='lessPracticeTxt '>What SDGs  would you like to practice/work with as part of your entrepreneurship journey? Pick 1 out of the list and explain why.</p>
                                    </Col>
                                    <Col md={2} className='lessPracticeHeaderCol'>
                                        <div className="lessPracticePlusPoints">+XXX XP</div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='lessPracticeBodyNaj mb32'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <Accordion defaultActiveKey="0" flush className='lessAcc'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='lessTaskAcc practiceQuestion'>{spanClickVal}</Accordion.Header>
                                                <Accordion.Body>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Zero Hunger</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Good Health and Well-being</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Quality Education</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); accHeaderClick(); }}>Gender Equality</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Clean Water and Sanitation</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Affordable and Clean Energy</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Decent Work and Economic Growth</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Industry, Innovation and Infrastructure</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Reduced Inequality</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Sustainable Cities and Communities</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Responsible Consumption and Production</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Climate Action</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Life Below Water</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Life on Land</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Peace and Justice Strong Institutions</span>
                                                    <span className='lessAccTxt' onClick={(e) => { setSpanClickVal(e.target.innerHTML); setShowLessonBtnPractice1(''); setDisableBtnPractice1(''); accHeaderClick(); }}>Partnerships to achieve the Goal</span>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Form.Control inputques="accInput" as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => setPractice1(e.target.value)} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac2C' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac2C').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <img className='lessImg' src={picture2b} alt='' />
                        <h4 className='lessSubtitle'>Pilot,  Let's be trendy and have initiative!</h4>
                        <p className='lessTxt'>You can come up with a solution through technology better than anyone, it is only about your fantasy and its limitations. We have a few fun tasks here for you.
                            First, read the information about <a className='lessTxtUrl' href='https://sdgs.un.org/goals' target='_blank' >the Sustainable Development Goal</a> (”SDG”) that you picked earlier.</p>
                        <div className='lessBox'>
                            <p className='lessTxt'>Think big, start small. Remove your barriers. Anything is possible and there is nothing you cannot do! Imagine that you have unlimited amounts of money, time, and all the skills possible. We have only these three rules: </p>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>It has to be legal to create</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>It has to be sustainable in your mind</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>It has to address your picked SDG</p>
                            </div>
                        </div>
                        <Form name='Practice 2.D' onSubmit={submit} practiceid='7' id='7'>
                            <div className='lessPracticeHeader'>
                                <Row>
                                    <Col md={10} className='lessPracticeHeaderCol'>
                                        <div className='lessPracticeHeaderTitleDiv'>
                                            <i className="bi bi-card-checklist"></i>
                                            <h4 className='lessPracticeTitle'>Practice 2.D</h4>
                                        </div>
                                        <p className='lessPracticeTxt practiceQuestion'>Write down your one idea to solve a part of your picked SDG and describe in a few sentences how it is going to work and what it will solve.</p>
                                    </Col>
                                    <Col md={2} className='lessPracticeHeaderCol'>
                                        <div className="lessPracticePlusPoints">+XXX XP</div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='lessPracticeBodyNaj mb32'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <Form.Control inputques="Write down your one idea to solve a part of your picked SDG and describe in a few sentences how it is going to work and what it will solve." as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none', paddingTop: '8px' }} required onChange={(e) => { setPractice1(e.target.value); setShowLessonBtnPractice2(''); setDisableBtnPractice2(''); }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac2D' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice2} onClick={() => { document.getElementById('submitPrac2D').click() }} style={{ pointerEvents: btnDisablePractice2 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice2} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <div className='lessPracticeHeader headerQuiz'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-mortarboard"></i>
                                        <h4 className='lessPracticeTitle'>Najima Quiz</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Dear Pilot, practice makes perfect so here is a little quiz:</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyNaj mb32'>
                            <Form name='Najima Quiz' onSubmit={submitQuiz} quizid='1' id='1'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessNajimaPr2c'>
                                        <div className='lessNajimaQuizDiv'>
                                            <ul>
                                                <li className='quizQuestion'>What does SDG stand for?</li>
                                            </ul>
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Super Delicious Greens' value='Super Delicious Greens' required onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Surprising Developments Goals' value='Surprising Developments Goals' required onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group1' className='lessPracticeCheckbox' type='radio' label='Sustainable Development Goals' value='Sustainable Development Goals' required onChange={(e) => { setQuizAnswer1(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv br0'>
                                            <ul>
                                                <li className='quizQuestion'>When choosing a sustainability project you should think globally but act locally.</li>
                                            </ul>
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='True' value='True' required onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group2' className='lessPracticeCheckbox' type='radio' label='False' value='False' required onChange={(e) => { setQuizAnswer2(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                        <div className='lessNajimaQuizDiv bbr'>
                                            <ul>
                                                <li className='quizQuestion'>SDG is regarded as the global treaty of the future because the 17 SDG goals ensure that</li>
                                            </ul>
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='Climate change is faster than before.' value='Climate change is faster than before.' required onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox' type='radio' label='There are no wars and people are living in peace.' value='There are no wars and people are living in peace.' required onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                            <Form.Check name='group3' className='lessPracticeCheckbox m0' type='radio' label='The world will be inhabitable by humankind even in the 22nd century.' value='The world will be inhabitable by humankind even in the 22nd century.' required onChange={(e) => { setQuizAnswer3(e.target.value); setShowLessonBtnPractice3(''); setDisableBtnPractice3(''); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitNajimaQuiz' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice3} onClick={() => { document.getElementById('submitNajimaQuiz').click() }} style={{ pointerEvents: btnDisablePractice3 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice3} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessInfoBoxSec'>
                        <div className='lessonContainer'>
                            <div className='lessInfoBox '>
                                <h4 className='lessTitle'>After completing Najima you should be able to:</h4>
                                <ul className='lessListUl'>
                                    <li>Focus on trends and how to spot them.</li>
                                    <li>Explain concepts like trendspotting, technology and sustainability</li>
                                    <li>Express the world problems and 17 SDGs goals (ideally to put them in your spaceships plans)</li>
                                </ul>
                                <div className='lessResourcesDiv'>
                                    <h4 className='lessTitle'>Resources</h4>
                                    <div className='lessResourcesUrlDiv'>
                                        <a className='lessResourcesUrl' href='https://kabage.org'>kabage.org</a>
                                        <a className='lessResourcesUrl' href='https://verywellmind.com'>verywellmind.com</a>
                                        <a className='lessResourcesUrl' href='https://whattheythink.com'>whattheythink.com</a>
                                        <a className='lessResourcesUrl' href='https://apa.org/news/press/releases'>apa.org/news/press/releases</a>
                                        <a className='lessResourcesUrl' href='https://wealest.com'>wealest.com</a>
                                        <a className='lessResourcesUrl' href='https://psychcentral.com'>psychcentral.com</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lessNextLessBtnSecNajima'>
                        <div className='lessonContainer'>
                            <div className='lessNextLessBtnBox'>
                                <img className='alzubraHeaderImg' src={najimaHeaderImg} alt='' />
                                <p className='lessTxt'>Congratulations, you have completed</p>
                                <div className='lessPlanetCheck'>
                                    <i className="bi bi-check"></i>
                                    <h4 className='lessTitle'>Najima</h4>
                                </div>
                                <Row className='lessNextLessBtnRow'>
                                    <Col>
                                        <h4 className='lessFin'>3/3</h4>
                                        <p className='lessP'>Practice</p>
                                    </Col>
                                    <Col>
                                        <h4 className='lessFin'>1/1</h4>
                                        <p className='lessP'>Quiz</p>
                                    </Col>
                                    <Col>
                                        <img className='alzubraMedal' src={najimaMedal} alt='' />
                                        <p className='lessP'>Najima Badge</p>
                                    </Col>
                                    <Col>
                                        <span className='lessPointGreen'>XX XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={buttonUrl} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        <Row className='lessonChapterBtn lessNextChBtn'>
                                            <Col md={10}>
                                                <p className='chapterNum '>Visit Ester</p>
                                                <h4 className='chapterTitle '>LOOK FOR OPPORTUNITIES AND TEST YOUR IDEAS</h4>
                                            </Col>
                                            <Col md={2} className="btnRightCol">
                                                <i className="bi bi-arrow-right"></i>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModuleSpecificQuestionsModal
                show={showMsq}
                close={handleCloseMsq}
            />
        </>
    );
}

export default LessonNajima_3;