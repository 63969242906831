import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import '../css/ModuleSpecificQuestionsModal.css';
import { decrypt, encrypt } from "n-krypta";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ModuleSpecQuesThxModal from './ModuleSpecQuesThxModal';


function ModuleSpecificQuestionsModal(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const [showMsqThx, setShowMsqThx] = useState(false);

    const handleCloseMsqThx = () => setShowMsqThx(false);
    const handleShowMsqThx = () => setShowMsqThx(true);

    const pathname = location.pathname.split('-');
    const curentPlanetName = pathname[1];

    const [curentId, setCurentId] = useState('');
    const [curentPlanet, setCurentPlanet] = useState('');
    const [nextId, setNextId] = useState('');
    const [nextPlanet, setNextPlanet] = useState('');

    const nextLessonUrl = '/lesson-' + nextPlanet + '-1';

    axios.post('https://maxp.quadrixsoft.com/api/curentPlanet.php', { 'curentPlanet': curentPlanetName }, {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }).then((res) => {
        if (res.data.message === 'Lessons') {
            setCurentId(res.data.curentPlanet.lessonNumber);
            setCurentPlanet(res.data.curentPlanet.planet);
            setNextId(res.data.nextPlanet.lessonNumberNext);
            setNextPlanet(res.data.nextPlanet.planetNext);
        } else {
            console.log(res.data.message);
        }
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [userData, setUserData] = useState([]);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userAvatarImgTag, setUserAvatarImgTag] = useState('');

    const [ans1, setAns1] = useState('');
    const [ans2, setAns2] = useState('');
    const [ans3, setAns3] = useState('');

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        setUserData(decryptedUserData);
        setUserFirstName(decryptedUserData.firstName);
        setUserLastName(decryptedUserData.lastName);
    }, [encryptedObject]);

    useEffect(() => {
        if (userData.userAvatar === '') {
            const userFirstNameIni = userFirstName.charAt(0);
            const userLastNameIni = userLastName.charAt(0);
            setUserAvatarImgTag(<span className="userAvatarTxt m0">{userFirstNameIni + userLastNameIni}</span>);
        } else {
            setUserAvatarImgTag(<img className="modulSqHeaderAvatar" src={userData.userAvatar} alt="Avatar" />);
        }
    }, [userData]);

    let allCheck = '';

    if (ans1 && ans2 && ans3) {
        allCheck = true;
    }

    let questions = [];

    const question = document.getElementsByClassName('modulSqQuestion');

    for (let i = 0; i < question.length; i++) {
        questions.push(question[i].textContent);
    }

    function getSubmitButtonClassName1() {
        return allCheck ? 'buttonWrapperMaxp' : 'buttonWrapperMaxp disabledBtn';
    }
    function getSubmitButtonClassName2() {
        return allCheck ? 'button2Maxp' : 'button2Maxp disabledBtn';
    }

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        let answers = [ans1, ans2, ans3];

        axios.post('https://maxp.quadrixsoft.com/api/modulSqForm.php', { 'userId': userData.id, 'userFirstName': userData.firstName, 'userLastName': userData.lastName, 'lessonId': curentId, 'planet': curentPlanet, 'questions': questions, 'answers': answers }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (res.data.message === 'Modul Specific Questions form updated.') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                props.close();
                handleShowMsqThx();
            } else {
                console.log(res.data.message);
            }
        });
    }


    return (
        <>
            <Modal show={props.show} onHide={props.close} className="modulSqModal">
                <Form name='ModulSQ Form' onSubmit={submit}>
                    <Modal.Header>
                        <Row className="modulSqHeaderRow">
                            <Col md={4}>
                                {userAvatarImgTag}
                            </Col>
                            <Col md={8}>
                                <h4 className="modulSqHeaderTitle">Module Specific Questions</h4>
                                <p className="modulSqHeaderTxt">By participating in the feedback process for MAXP, you have the opportunity to make the course more engaging and effective for yourself and your peers.</p>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modulSqSec">
                            <h4 className="modulSqQuestion">Module 1 was engaging and interesting</h4>
                            <div className="modulSqAnswerBox">
                                <div className="feedbackBoxRadio">
                                    <Form.Check value='Strongly Disagree' type='radio' label='Strongly Disagree' name='1' onClick={(e) => setAns1(e.target.value)} />
                                    <Form.Check value='Disagree' type='radio' label='Disagree' name='1' onClick={(e) => setAns1(e.target.value)} />
                                    <Form.Check value='Neither Agree not Disagree' type='radio' label='Neither Agree not Disagree' name='1' onClick={(e) => setAns1(e.target.value)} />
                                    <Form.Check value='Agree' type='radio' label='Agree' name='1' onClick={(e) => setAns1(e.target.value)} />
                                    <Form.Check value='Strongly Agree' type='radio' label='Strongly Agree' name='1' onClick={(e) => setAns1(e.target.value)} />
                                    <Form.Check value='Don’t Know' type='radio' label='Don’t Know' name='1' onClick={(e) => setAns1(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="modulSqSec">
                            <h4 className="modulSqQuestion">How likely are you to apply what you learned in real life?</h4>
                            <div className="modulSqAnswerBox">
                                <div className="feedbackBoxRadio">
                                    <Form.Check value='Strongly Disagree' type='radio' label='Strongly Disagree' name='2' onClick={(e) => setAns2(e.target.value)} />
                                    <Form.Check value='Disagree' type='radio' label='Disagree' name='2' onClick={(e) => setAns2(e.target.value)} />
                                    <Form.Check value='Neither Agree not Disagree' type='radio' label='Neither Agree not Disagree' name='2' onClick={(e) => setAns2(e.target.value)} />
                                    <Form.Check value='Agree' type='radio' label='Agree' name='2' onClick={(e) => setAns2(e.target.value)} />
                                    <Form.Check value='Strongly Agree' type='radio' label='Strongly Agree' name='2' onClick={(e) => setAns2(e.target.value)} />
                                    <Form.Check value='Don’t Know' type='radio' label='Don’t Know' name='2' onClick={(e) => setAns2(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="modulSqSec">
                            <h4 className="modulSqQuestion">How likely are you to apply what you learned in real life?</h4>
                            <div className="modulSqAnswerBox">
                                <div className="feedbackBoxRadio">
                                    <Form.Check value='Easy peasy' type='radio' label='Easy peasy' name='3' onClick={(e) => setAns3(e.target.value)} />
                                    <Form.Check value='Informative' type='radio' label='Informative' name='3' onClick={(e) => setAns3(e.target.value)} />
                                    <Form.Check value='Useful' type='radio' label='Useful' name='3' onClick={(e) => setAns3(e.target.value)} />
                                    <Form.Check value='Disconnected from reality' type='radio' label='Disconnected from reality' name='3' onClick={(e) => setAns3(e.target.value)} />
                                    <Form.Check value='Boring' type='radio' label='Boring' name='3' onClick={(e) => setAns3(e.target.value)} />
                                    <Form.Check value='Hard to understand' type='radio' label='Hard to understand' name='3' onClick={(e) => setAns3(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modulSqFooter">
                        <div className="buttonWrapperMaxp" onClick={() => navigate(nextLessonUrl)} >
                            <div className="buttonSimple-3Maxp">
                                SKIP MODULE
                            </div>
                        </div>
                        <button id='submitModulSq' type='submit' hidden>s</button>
                        <div className={getSubmitButtonClassName1()} onClick={() => { document.getElementById('submitModulSq').click() }} style={{ pointerEvents: allCheck ? '' : 'none' }}>
                            <div className={getSubmitButtonClassName2()} >
                                SUBMIT ANSWERS
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModuleSpecQuesThxModal
                show={showMsqThx}
                close={handleCloseMsqThx}
                nextLessonUrl={nextLessonUrl}
            />
        </>
    );
}

export default ModuleSpecificQuestionsModal;