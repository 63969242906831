import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import FeedbackFormModal from '../modals/FeedbackFormModal';
import '../css/FutureJourneys.css';

function FutureJourneys() {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');

    //FEEDBACK FORM MODAL
    const [showFF, setShowFF] = useState(false);

    const handleCloseFF = () => setShowFF(false);
    const handleShowFF = () => setShowFF(true);
    //END FEEDBACK FORM MODAL

    return (
        <>
            <div className="futJourneyPage">
                <div className="container">
                    <div className="futJourBoard">
                        <div className="futJourBoardHeader">
                            <span className="existPoints">200 XP</span>
                            <p className="futJourSubtitle">Well done Pilot You have won extra points!  but, do not despair</p>
                            <h4 className="futJourTitle">Your Journey is not over yet!</h4>
                            <p className="futJourTxt">Pilots, we would like to support your personal growth further and therefore we are suggesting you these constellations to visit on your next journey. Some are within the same MAXP galaxy and some venture out of your comfort zone, but all of them will help to sharpen your future.</p>
                            <h4 className="futJourTitle mtb48">Future Expeditions:</h4>
                        </div>
                        <div className='futJourBoardCard1'>
                            <div className='futJourCardRight'>
                                <p className='futJourCardSubtitle'>Exploration of the VULPECULA begins soon</p>
                                <h4 className='futJourCardTitle'>Upgrade your Pilot and prepare for the Journey</h4>
                                <p className='futJourCardTxt'>Are you ready to claim your title as the best idea maker in the EU?</p>
                                <Row className='futJourCardRow'>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>7</h4>
                                        <p className='futJourCardRowTxt'>Lessons</p>
                                    </Col>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>122</h4>
                                        <p className='futJourCardRowTxt'>Practices Questions</p>
                                    </Col>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>70</h4>
                                        <p className='futJourCardRowTxt'>Quiz Questions</p>
                                    </Col>
                                    <Col>
                                        <span className='futJourCardYePoint'>+34000XP</span>
                                    </Col>
                                </Row>
                                <div className="buttonWrapperMaxp futJourCardBtn" onClick={handleShowFF}>
                                    <div className="button2Maxp">
                                        START SURVEY
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='futJourBoardCard2'>
                            <div className='futJourCardRight'>
                                <p className='futJourCardSubtitle'>Visit SAGITTARIUS</p>
                                <h4 className='futJourCardTitle'>Aircraft problems, How to solve them?</h4>
                                <p className='futJourCardTxt'>Are you ready to claim your title as the best idea maker in the EU?</p>
                                <Row className='futJourCardRow'>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>7</h4>
                                        <p className='futJourCardRowTxt'>Lessons</p>
                                    </Col>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>122</h4>
                                        <p className='futJourCardRowTxt'>Practices Questions</p>
                                    </Col>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>70</h4>
                                        <p className='futJourCardRowTxt'>Quiz Questions</p>
                                    </Col>
                                    <Col>
                                        <span className='futJourCardYePoint'>+34000XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp futJourCardBtn " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        START SURVEY
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='futJourBoardCard3'>
                            <div className='futJourCardRight'>
                                <p className='futJourCardSubtitle'>Visit SAGITTARIUS</p>
                                <h4 className='futJourCardTitle'>Aircraft problems, How to solve them?</h4>
                                <p className='futJourCardTxt'>Are you ready to claim your title as the best idea maker in the EU?</p>
                                <Row className='futJourCardRow'>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>7</h4>
                                        <p className='futJourCardRowTxt'>Lessons</p>
                                    </Col>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>122</h4>
                                        <p className='futJourCardRowTxt'>Practices Questions</p>
                                    </Col>
                                    <Col>
                                        <h4 className='futJourCardRowNum'>70</h4>
                                        <p className='futJourCardRowTxt'>Quiz Questions</p>
                                    </Col>
                                    <Col>
                                        <span className='futJourCardYePoint'>+34000XP</span>
                                    </Col>
                                </Row>
                                <div className={"buttonWrapperMaxp futJourCardBtn " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <div className={"button2Maxp " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                        START SURVEY
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FeedbackFormModal show={showFF} close={handleCloseFF} />
            </div>
        </>
    );
}

export default FutureJourneys;