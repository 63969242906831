import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import "../css/footer.css";
import logo4 from '../img/logo4.png';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";

function Item(props) {

    var planetFooter = "/lesson-chapters/" + props.planet;
    let footerTitle = '';

    if (props.title.length > 24) { footerTitle = props.title.substr(0, props.title.lastIndexOf(' ', 24)) + '...' } else { footerTitle = props.title; }

    return (
        <>
            <Link to={planetFooter} className='footerLink'>{footerTitle}</Link>
        </>
    );
}

function Footer() {

    const [items, setItems] = useState([]);

    let footerLi = [];

    const { isLoading, error, data } = useQuery('footerLi', () =>
        fetch('https://maxp.quadrixsoft.com/api/lessons.php')
            .then(res => res.json())
            .then((response) => {
                footerLi = response.lessons.map((item, index) => {
                    return <Item key={index}
                        id={item.id}
                        constalationId={item.constalationId}
                        planet={item.planet}
                        title={item.title}
                        subTitle={item.subTitle}
                        description={item.description}
                        lessonNumber={item.lessonNumber}
                        featured_image={item.featured_image}
                        small_img={item.small_img}
                        points={item.points} />
                });
                setItems(footerLi);
            }))
    if (isLoading) return <Spinner className='sectionSpinner' animation="border" />
    if (error) return <div className='sectionError'><h2>An error has occurred: {error.message}<br />Please try to reload again on the bottom button.</h2><Button className='defButton' onClick={() => window.location.reload(false)}>RELOAD</Button></div>


    return (
        <>
            <div className="footer">
                <Row className="footerRow container no-gutters">
                    <Col>
                        <a href='/'><img src={logo4} alt="" /></a>
                        <p className="footerLogoTxt">You choose the constellation. We plan you the planet exploration path based on your goals and interest</p>
                    </Col>
                    <Col>
                        <h6 className="footerTitle">Pisces -<br />
                            Entrepreneurship</h6>
                        <div id="lessonsFooterDiv">
                            {items}
                        </div>
                    </Col>
                    <Col>
                        <h6 className="footerTitle">Community</h6><br />
                        <a href="#" className="footerLink" >Discord Community</a>
                        <a href="#" className="footerLink" >Leaderboard</a>
                        <a href="#" className="footerLink" >Idea Maker Competition</a>
                    </Col>
                    <Col>
                        <h6 className="footerTitle">FAQ</h6><br />
                        <a href="#" className="footerLink" >What do MAXP courses include?</a>
                        <a href="#" className="footerLink" >How do I take a MAXP course?</a>
                        <a href="#" className="footerLink" >Troubleshooting Login Issues</a>
                        <a href="#" className="footerLink" >Forgot your password?</a>
                        <a href="#" className="footerLink" >How do I participate in the competition?</a>
                    </Col>
                    <Col>
                        <h6 className="footerTitle">Newsletter</h6><br />
                        <p className="footerNewsletter">Subscribe to stay tuned for new courses</p>
                        <input type="email" required name="newsletter" className="newsletterInput" placeholder="name@email.com" />
                        <div className="buttonWrapperMaxp subscribeBtn">
                            <div className="buttonSimple-3Maxp">
                                SUBSCRIBE
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="footerRowBottom">
                <Row className="container">
                    <Col><p>© MAXP 2023. We love our gamers!</p></Col>
                    <Col className="footerIconCol">
                        <div className="footerIconDiw">
                            <p>Follow us:</p>
                            <a href=""><i className="bi bi-discord"></i></a>
                            <a href=""><i className="bi bi-instagram"></i></a>
                            <a href=""><i className="bi bi-facebook"></i></a>
                            <a href=""><i className="bi bi-twitter"></i></a>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Footer;