import { decrypt, encrypt } from 'n-krypta';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonNajima.css';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';
import najimaHeaderImg from '../lessons_img/lessonNajima_1/najimaSmallBg.png';
import picture1a from '../lessons_img/lessonNajima_2/picture1a.jpg';
import picture2a from '../lessons_img/lessonNajima_2/picture2a.jpg';
import $ from 'jquery';
import axios from 'axios';

function LessonNajima_2() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    const [btnDisableNext, setShowLessonBtnNext] = useState('none');
    const [disabledBtnNext, setDisableBtnNext] = useState('disabledBtn');
    const [btnDisablePractice1, setShowLessonBtnPractice1] = useState('none');
    const [disabledBtnPractice1, setDisableBtnPractice1] = useState('disabledBtn');
    const [userData, setUserData] = useState([]);

    const [practice1, setPractice1] = useState('');
    const [practice2, setPractice2] = useState('');
    const [practice3, setPractice3] = useState('');
    const [checkTrue, setCheckTrue] = useState(false);

    const encryptedObject = localStorage.getItem("userData");

    useEffect(() => {
        const encryptedObjectCh = localStorage.getItem("userNextChapter");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        const decryptedNextChap = decrypt(encryptedObjectCh, secret);
        setUserData(decryptedUserData);
    }, []);

    useEffect(() => {

        const secret = localStorage.getItem("hash");
        const userData = decrypt(encryptedObject, secret);
        //const userNextChapter = decrypt(encryptedObjectCh, secret);

        if (userData.userLessonId >= 2 && userData.userPracticeId >= 5) {
            setShowLessonBtnNext('');
            setDisableBtnNext('');
        } else {
            setShowLessonBtnNext('none');
            setDisableBtnNext('disabledBtn');
        }
    }, [userData]);

    useEffect(() => {
        const practiceId = $('form').map(function (idx, elem) {
            return $(elem).attr('practiceid');
        }).get();
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;

        if (practiceId) {
            axios.post('https://maxp.quadrixsoft.com/api/checkUserPractice&Quiz.php', {
                'userId': userData.id, 'apiCall': 'checkPractice', 'chapterNumber': chapterNumber, 'lessonNumber': lessonNumber
            }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {

                if (res.data.message === 'User practice exists!') {
                    const inputQues = $('form .form-control').map(function (idx, elem) {
                        return $(elem).attr('inputques');
                    }).get();
                    console.log(res.data.userPractice)
                    for (var i = 0; i < inputQues.length; i++) {
                        if (inputQues[i] === res.data.userPractice[i].practiceQuestion) {
                            $('form[practiceid="' + res.data.userPractice[i].practiceId + '"] .form-control[inputques="' + inputQues[i] + '"]').val(res.data.userPractice[i].userAnswer).attr('disabled', 'disabled');
                        }
                    }
                } else {
                    console.log(res.data.message);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }, [userData]);

    function submit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formId = event.target.getAttribute('id');
        const practiceid = event.target.getAttribute('practiceid');
        const practiceTitle = event.target.getAttribute('name');
        const lessonNumber = '2';
        const chapterNumber = document.querySelector('.activePageSpan').innerHTML;
        const lessonPlanet = location.pathname.split('-')[1];
        const practiceQuestion = $('#' + formId + ' .practiceQuestion').map(function (idx, elem) {
            return $(elem).text();
        }).get();
        const userAnswer = [practice1, practice2, practice3];

        axios.post('https://maxp.quadrixsoft.com/api/usersPractice.php', { 'userId': userData.id, 'practiceid': practiceid, 'practiceTitle': practiceTitle, 'lessonNumber': lessonNumber, 'chapterNumber': chapterNumber, 'lessonPlanet': lessonPlanet, 'practiceQuestion': practiceQuestion, 'userAnswer': userAnswer, 'checkTrue': checkTrue }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {

            if (res.data.message === 'Successful entry') {
                localStorage.removeItem("userData");
                const secret = res.data.user.hash;
                const encryptedObject = encrypt(res.data.user, secret);
                localStorage.setItem("userData", encryptedObject);
                setUserData(res.data.user);
                setCheckTrue(false);

                if (practiceid === '5') {
                    setShowLessonBtnPractice1('none');
                    setDisableBtnPractice1('disabledBtn');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='lessonNajimaPage'>
                <div className='lessonNajimaHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Najima' >Najima</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 2</span>
                    </div>
                    <img src={najimaHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO NAJIMA</p>
                    <h2 className='lessHeaderTitle'>Be in and Trendy</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 2
                        </div>
                        <h4 className='lessTitle'>Technology shapes the world</h4>
                        <img className='lessImg' src={picture1a} alt='' />
                        <p className='lessTxt'>Technology is a complex concept full of various fields and definitions, but to keep it simple these things have a few similarities:</p>
                        <div className='lessBox'>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Languages -  People speak different languages depending on their location and background. Some may even learn a new language for specific purposes. Language is the key to understanding the complexities of both humans and technology, as it is through language that knowledge is shared and technology is developed. The language used to create technology is always evolving, just like the technology itself.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>Purpose - Each technology has to have its purpose, whether it is to connect you with other people, allow you to understand, or get you faster to buy your food in the supermarket or anything else.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'>People - There are people behind every technology, who vary in character, skills, and talents. From the creative ones that love drawing to analytical ones that dive into mathematics, or whoever in between, who loves to come up with ideas, talk to people, test new things, and create stories. There is a place for anyone and everyone.</p>
                            </div>
                        </div>
                        <img className='lessImg' src={picture2a} alt='' />
                        <p className='lessTxt'>Technology is all around you and making your life simpler. It's a set of knowledge, skills, rules, methods, and techniques designed to obtain results or solve an issue. Your spaceship will reflect all that was said above - trends, technology, purpose, language, and people.
                            <br /><br />
                            Once you “handle” the language of technology, you will have the potential to become a great pilot and participate in changing the world.</p>
                        <div className='lessPracticeHeader'>
                            <Row>
                                <Col md={10} className='lessPracticeHeaderCol'>
                                    <div className='lessPracticeHeaderTitleDiv'>
                                        <i className="bi bi-card-checklist"></i>
                                        <h4 className='lessPracticeTitle'>Practice 2.B</h4>
                                    </div>
                                    <p className='lessPracticeTxt'>Write down 3 places where you encountered technology this week outside of your home and pocket. What were they, and what was their purpose?</p>
                                </Col>
                                <Col md={2} className='lessPracticeHeaderCol'>
                                    <div className="lessPracticePlusPoints">+XXX XP</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='lessPracticeBodyNaj'>
                            <Form name='Practice 2.B' onSubmit={submit} practiceid='5' id='5'>
                                <div className='lessPracticeQuestionBody'>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>Place 1</li>
                                        </ul>
                                        <Form.Control inputques='Place 1' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice1(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv'>
                                        <ul>
                                            <li className='practiceQuestion'>Place 2</li>
                                        </ul>
                                        <Form.Control inputques='Place 2' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice2(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                    <div className='lessPracticeQuestionDiv' style={{ marginBottom: '0' }}>
                                        <ul>
                                            <li className='practiceQuestion'>Place 3</li>
                                        </ul>
                                        <Form.Control inputques='Place 3' as="textarea" aria-label="" placeholder='Fill with answer...' style={{ height: '44px', resize: 'none' }} required onChange={(e) => {
                                            setPractice3(e.target.value); setShowLessonBtnPractice1(''); setDisableBtnPractice1('');
                                        }} />
                                    </div>
                                </div>
                                <div className='lessPracticeFooter'>
                                    <p className='lessPracticeFooterTxt'>Your answers should be at least 5 words. There are no right or wrong answers. </p>
                                    <Form.Check className='lessPracticeCheckbox' type='checkbox' label='I agree that other users can see my answers.’ If you don’t check, your answer won’t receive feedback and you won’t get extra points.' onChange={() => { setCheckTrue(prevCheck => !prevCheck) }} />
                                    <div className='lessPracticeFooterRedTxtDiv'>
                                        <i className="bi bi-exclamation-triangle"></i><span className='lessPracticeFooterRedTxt'>After submitting the answer you cannot correct the answers!</span>
                                    </div>
                                    <button id='submitPrac2B' type='submit' hidden>s</button>
                                    <div className={"buttonWrapperMaxp w100 " + disabledBtnPractice1} onClick={() => { document.getElementById('submitPrac2B').click() }} style={{ pointerEvents: btnDisablePractice1 }}>
                                        <div className={"button2Maxp w100 " + disabledBtnPractice1} >
                                            SUBMIT ANSWERS
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='lessonNajimaNextCh'>
                        <div className='lessonContainer'>
                            <img src={najimaHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessNajNextChTitle'>Second Chapter of Najima</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtnNext} onClick={() => navigate('/lesson-Najima-3')} style={{ pointerEvents: btnDisableNext }}>
                                <div className={"button2Maxp w100 " + disabledBtnNext} style={{ pointerEvents: btnDisableNext }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Najima-3'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>SOLVING WORLD PROBLEMS THROUGH TECHNOLOGY</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Najima-3'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonNajima_2;