import { Accordion } from 'react-bootstrap';
import '../css/Faq.css';

function AboutMaxpFaq() {

    return (
        <>
            <Accordion className='faqAccordian' defaultActiveKey="" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What do MAXP courses include?</Accordion.Header>
                    <Accordion.Body>
                        MAXP Universe will lead you through galaxies, constellations, and planets that will show and prepare you in a game-like setting for your career of the future.<br />
                        Currently, we're focusing on entrepreneurs, but we'll be adding more options soon. In the MAXP Universe, each career is represented by a galaxy. Within each galaxy, you'll find various courses, which are like constellations. Each course is made up of lessons, or planets. These planets contain a combination of educational content, videos, texts, and hands-on activities to help you fully understand and prepare for your future career.<br />
                        Get ready to embark on an exciting journey through the MAXP Universe!
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I take a MAXP course?</Accordion.Header>
                    <Accordion.Body>
                        No login is required to visit the first planet Alzubra of the Pisces constellation of Galaxy Entrepreneur I. For further planets, constellations, and galaxies, login will be needed.<br />
                        After you enroll in MAXP you can start roaming the entire universe and find the galaxies that interest you. You have all the time in the world to complete it that is really up to you. You can also start whenever you want.<br />
                        Start enjoying the content and don't forget that an important part of your experience is also the MAXP community.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Do I have to start my MAXP course at a certain time? And how long do I have to complete it?</Accordion.Header>
                    <Accordion.Body>
                        At MAXP Universe, you have the flexibility to start and complete courses at your own pace. There are no deadlines to worry about, so you can take your time and move through the material at a pace that works best for you. You're in control of your own journey through the MAXP Universe.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Is there any way to preview a course?</Accordion.Header>
                    <Accordion.Body>
                        The first Pisces constellation in the galaxy Entrepreneurship is and always will be free. In this Pisces constellation, you will visit 7 planets, and to land on the first one, you do not have to be even logged in. Get on board your spaceship and launch it towards the MAXP universe.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>How can I pay for a course?</Accordion.Header>
                    <Accordion.Body>
                        The first constellation (“course”) in the Entrepreneur galaxy is free to try out! If you enjoy your experience and want to continue exploring new constellations and galaxies, we do ask that you subscribe to our monthly service.<br />
                        This helps us to maintain and improve the MAXP Universe so that you can continue to discover new depths and navigate the complex world of career development.<br />
                        Rest assured that your payments are securely processed through Stripe, so you can easily and smoothly access all that the MAXP Universe has to offer."
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>What if I don’t like the course I purchased?</Accordion.Header>
                    <Accordion.Body>
                        If you are unhappy with the MAXP constellation or galaxy you can quit whenever you like. You will also have the option to switch to a different galaxy to try to discover a new constellation. If you decide to cancel your subscription, you will still have access to it until the current payment period does not run out.<br />
                        We want you to be satisfied and also always aim for a better experience for our pilots. Please if you have any feedback on the platform, the experience, missing galaxies, or any tips for improvement feel free to write us an email at <a href="mailto:co-pilot@maxp.io">co-pilot@maxp.io</a> or fill in this form.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Where can I go for help?</Accordion.Header>
                    <Accordion.Body>
                        If any task in the universe will require your further understanding or help you can always go and ask the MAXP Community through our Discord channel.<br />
                        Don't be shy to ask if you don't understand anything. It is completely normal. You are just about to change your life so there will be learning and exploring. Get ready for it!
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>What does the MAXP stand for?</Accordion.Header>
                    <Accordion.Body>
                        MAXP is a platform that offers gamified learning courses that are aimed for people that are interested in careers that the traditional school system does not prepare them for. It's the first step on their self-development journey.<br />
                        What is MAXP? Since we use gamification and our aim is to make learning as fun as possible, we decided to go with this metaphor in creating the name of the universe as well. MAXP users should gain maximum experience points to level up their real-life characters. From this, we got the name MAXP (“maximum experience”).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>Can I use MAXP as a teaching aid?</Accordion.Header>
                    <Accordion.Body>
                        Yes, you can absolutely use the MAXP universe as a teaching aid. Together with teaching experts, we put together a manual and tips for teachers. You can find them here:
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>What are the MAXP points good for?</Accordion.Header>
                    <Accordion.Body>
                        MAXP universe provides a gamified experience which means that the whole education journey should feel like a game. Similar to most games you are rewarded for completing a challenge or quest with points and leveling up your game character. In MAXP you get rewarded with points for completing practices, short quizzes, and giving feedback to other pilots on the planet.<br />
                        The more points you get the better for you, because not only will your graduation certificate have a different value, but also the higher up you are in the leaderboard the more real-life experiences you can gain. These experiences vary based on the country you are from but generally speaking such options will be MAXP pilot meet-up invitations, Europe-wide competitions, and access to the first job opportunities.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>What do I get for completing MAXP career mode?</Accordion.Header>
                    <Accordion.Body>
                        You'll get something that cannot be taken away from you. You will get to know what careers interest you and which do not. You will also find out what you want to try and perhaps what school or work to apply for.<br />
                        You will discover new tools and knowledge that will help you to navigate the world and hopefully make a little sense of what you want to achieve in your life.<br />
                        We would like to support you on your path as a pilot of your own life. If you have any ideas or would like to know our opinion on your dilemma, please don't hesitate and reach out to the MAXP community or to the organizing team directly.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default AboutMaxpFaq;