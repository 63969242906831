import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import "../css/main.css";
import "../css/ResetPassword.css";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";

function ResetPassword() {

    // Browser TAB Title
    useEffect(() => {
        (document.title = 'MAXP | ' + 'Reset Password')
    });
    // END Browser TAB Title

    let navigate = useNavigate();
    let location = useLocation();

    const email = location.state.data;

    // Password Updated PopUp modal
    const [showUpdated, setShowUpdated] = useState(false);

    const handleCloseUpdated = () => setShowUpdated(false);
    const handleShowUpdated = () => setShowUpdated(true);
    //END Password Updated PopUp modal

    // Reset Password API
    const [validated, setValidated] = useState(false);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formMsgBg, setFormMsgBg] = useState('');

    function loginClick() {
        handleCloseUpdated();
        setPassword('');
        setPassword2('');
        document.querySelector('.loginBtn').click();
    }

    function submitReset(event) {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            if (password !== password2) {
                setErrorMessage('');
                const elemStyle = { "background": "#F76763", "color": "#EEF0F4" };
                setFormMsgBg(elemStyle);
                setErrorMessage("Passwords do not match. Please enter the same passwords.");
            } else {
                axios.post('https://maxp.quadrixsoft.com/api/resetPassword.php', { 'email': email, 'password': password }, {
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                }).then((res) => {
                    if (res.data.message === 'The password has been updated.') {
                        setErrorMessage('');
                        handleShowUpdated();
                    } else {
                        const elemStyle = { "background": "#F76763", "color": "#EEF0F4" };
                        setFormMsgBg(elemStyle);
                        setErrorMessage(res.data.message);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }

            event.preventDefault();
        }
    }

    return (
        <>
            <div className="resetPassBg">
                <Container>
                    <Row className="resetPassRow">
                        <Col md={{ span: 6, offset: 3 }} className="resetPassCol">
                            <h2>Reset Password</h2>
                            <div className="formMsg">
                                {
                                    errorMessage !== '' ?
                                        <span className="formMsgFalseLogin" style={formMsgBg}>
                                            <i className="bi bi-exclamation-circle" style={formMsgBg}></i>{errorMessage}
                                        </span> : null
                                }
                            </div>
                            <p>Changing password for: <span className="userEmail"> {email}</span></p>
                            <Form noValidate validated={validated} onSubmit={submitReset}>
                                <Form.Group className="mb-3" controlId="formBasicPasswordReset1">
                                    <Form.Label>NEW PASSWORD</Form.Label>
                                    <div className="passElem">
                                        <Form.Control
                                            className="passField1"
                                            placeholder="New Password"
                                            required
                                            pattern=".{8,}"
                                            title="8 characters minimum"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            type={showPass ? "text" : "password"}
                                        />
                                        {showPass ?
                                            <i className="bi bi-eye showPass showPass1" onClick={() => setShowPass(false)}></i> :
                                            <i className="bi bi-eye-slash hidePass hidePass1" onClick={() => setShowPass(true)}></i>
                                        }
                                    </div>
                                    <Form.Text className="text-muted">
                                        <span>Between 8 and 70 characters</span>
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb32" controlId="formBasicPasswordReset2">
                                    <Form.Label>NEW PASSWORD CONFIRM</Form.Label>
                                    <div className="passElem">
                                        <Form.Control
                                            className="passField2"
                                            placeholder="New Password Confirm"
                                            required
                                            pattern=".{8,}"
                                            title="8 characters minimum"
                                            onChange={(e) => setPassword2(e.target.value)}
                                            value={password2}
                                            type={showPass ? "text" : "password"}
                                        />
                                        {showPass ?
                                            <i className="bi bi-eye showPass showPass1" onClick={() => setShowPass(false)}></i> :
                                            <i className="bi bi-eye-slash hidePass hidePass1" onClick={() => setShowPass(true)}></i>
                                        }
                                    </div>
                                </Form.Group>
                                <button className="buttonWrapperMaxp w100" type="submit">
                                    <div className="button2Maxp w100">
                                        CHANGE PASSWORD
                                    </div>
                                </button>
                            </Form>
                        </Col>
                    </Row>
                </Container>

                {/* Password Updated PopUp modal */}
                <Modal className=""
                    show={showUpdated}
                    onHide={handleCloseUpdated}
                    backdrop="static"
                    size="md"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                // centered
                >
                    <Modal.Body className="modalResend resetPass">
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <h2 className="text-center loggedinH2">Pilot, You have successfully changed your password</h2>
                        <p className="text-center">Please <span className="loginTxt" onClick={loginClick} >LOG IN</span> with new password.</p>
                    </Modal.Body>
                </Modal>
                {/* END Password Updated PopUp modal */}
            </div>
        </>
    );
}

export default ResetPassword;