import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../lessons_css/LessonInfinity.css';
import infinityHeaderImg from '../lessons_img/lessonInfinity_1/infinitySmallBg.png';
import picture1b from '../lessons_img/lessonInfinity_2/picture1b.jpg';
import picture2b from '../lessons_img/lessonInfinity_2/picture2b.jpg';
import picture3b from '../lessons_img/lessonInfinity_2/picture3b.jpg';
import rocketIco from '../lessons_img/lessonAlzubra_1/rocketIco.png';

function LessonInfinity_2() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [navigate]);

    // const [btnDisable, setShowLessonBtn] = useState('none');
    // const [disabledBtn, setDisableBtnClass] = useState('disabledBtn');
    const [btnDisable, setShowLessonBtn] = useState('');
    const [disabledBtn, setDisableBtnClass] = useState('');

    return (
        <>
            <div className='lessonInfinityPage'>
                <div className='lessonInfinityHeader'>
                    <div className='breadcrumbsLessChap'>
                        <a className='' href='/course' >Pisces Constalation</a><i className="bi bi-chevron-right"></i><a className='' href='/lesson-chapters/Infinity' >Infinity</a><i className="bi bi-chevron-right"></i><span className='activePageSpan'>Chapter 2</span>
                    </div>
                    <img src={infinityHeaderImg} alt='' />
                    <p className='lessHeaderSubtitle'>WELCOME TO INFINITY</p>
                    <h2 className='lessHeaderTitle'>Present yourself</h2>
                </div>
                <div className='lessonBody'>
                    <div className='lessonContainer'>
                        <div className='lessChapter'>
                            <i className="bi bi-book-half"></i> Chapter 2
                        </div>
                        <h4 className='lessTitle'>Prepare your elevator pitch</h4>
                        <img src={picture1b} className='lessImg' alt='' />
                        <p className='lessTxt'>Pilots, consider an elevator pitch as a message you are sending to the universe. If your message is weak, it won't reach any other pilots, objects, or planets. But if your message is strong, it can attract attention from even the farthest reaches of the universe. How can you make your message powerful? Let's explore different techniques to achieve this.</p>
                        <h4 className='lessSubtitle'>An elevator pitch</h4>
                        <p className='lessTxt'>An Elevator pitch is a tool to help you define what you do and how you talk about it. It is a must-have in every plan. In a short pitch, you want to provide enough background so that the person understands the context, but you don't want to go too far into detail so that you lose the person's interest. Basically, you should be able to say what it is about in one sentence.</p>
                        <div className='lessBox'>
                            <h4 className='lessRocketTitle'>A good pitch has to have the following:</h4>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Introduce yourself</h4> describe what you do, and what you offer.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>To the point</h4> honestly describe, what is it about or what you could possibly want from the ones you are talking to.  Try to capture the attention of others - this could be referred to as the hook.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>All in one sentence</h4> say what problem you are solving, what you are dealing with, who you are helping, etc.</p>
                            </div>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt'><h4 className='lessSubtitle'>Compare</h4>compare your spaceship to something that  works on a similar basis that already exists. your audience will understand easily and will remember you.</p>
                            </div>
                        </div>
                        <h4 className='lessSubtitle'>Sample Elevator pitch from successful businesses:</h4>
                        <iframe style={{ marginBottom: "32px" }} width="100%" height="423" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        <p className='lessTxt'>Most tourists booking online care about price- and hotels are one of the highest costs when traveling. On the other hand, platforms like Couchsurfing have proven that over half a million people are willing to lend their couches or spare bedrooms. We have created a platform that connects travelers with locals, letting them rent our rooms or even entire places. Travelers save money, and locals can monetize their empty rooms- we just take a 10% commission. How does that sound?
                            <br /><br />
                            The main goal of an elevator pitch isn't to cover everything, but rather to generate interest in you and your project. We can say that the goal of an elevator pitch is to secure more time with someone, who is important to you to discuss your preferred topic in greater detail.</p>
                        <h4 className='lessSubtitle'>Tips for elevator pitches and examples</h4>
                        <img src={picture2b} className='lessImg' alt='' />
                        <p className='lessTxt'>Identify your target audience and the purpose of your pitch. Clearly and concisely state the problem you are solving or the opportunity you are presenting. Explain how your solution or opportunity is unique and beneficial. Describe your qualifications and achievements related to your solution or opportunity. End with a call to action or request for further engagement.</p>
                        <div className='lessBox'>
                            <h4 className='lessRocketTitle'>For example, if a student is pitching a new app that helps people track their daily water intake, their elevator pitch could follow this structure:</h4>
                            <div className='lessRocketDiv'>
                                <img src={rocketIco} alt='' />
                                <p className='lessTxt m0'>Hi, I'm [name] and I've developed an app called [app name] that helps people easily track their daily water intake. Many people struggle to stay hydrated, but our app makes it easy to set reminders and log your intake throughout the day. It's unique because it also provides personalized hydration recommendations based on your age, weight, and activity level. As a [relevant qualification or achievement], I have a degree in nutrition and have worked with health professionals to create a reliable and user-friendly app. I'd love the opportunity to tell you more about [app name] and see if it could be a valuable resource for you. Would you be interested in learning more?</p>
                            </div>
                        </div>
                        <h4 className='lessSubtitleGreen'>The key points of an elevator pitch: introducing yourself, clearly explaining the project or idea, highlighting the benefits and unique features, and closing with a call to action</h4>
                        <p className='lessTxt'>Remember that good pitches take a lot of practice and fine-tuning! Take the time to reflect on who you are, what you do, what makes your design different from others, and what goals you want to achieve. Take down those key points and practice it with your friend, see how your 60 seconds go! Don’t forget to always ask for feedback.</p>
                        <h4 className='lessSubtitle'>Presentation skills</h4>
                        <img src={picture3b} className='lessImg' alt='' />
                        <p className='lessTxt'>Pilot, are you in need of a presentation of your spaceship idea or collage presentation? Remember that a good presentation needs to be engaging, memorable, impactful, and fun. </p>
                        <p className='lessTxt'>Vanessa Van Edwards is a conference speaker and has given hundreds of presentations and is ready to pass on her knowledge to you! Here are her 10 best insider presentation tips you may not have heard before.</p>
                        <iframe style={{ marginBottom: "32px" }} width="100%" height="423" src="https://www.youtube-nocookie.com/embed/O40YTvkoxSs" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                        <p className='lessTxt mb64'>In the end, we are all performers, aren't we, pilots? Even though presentations might be scary at first, you'll need to do a few of them. Thinking of yourself as a performer is wonderful because it allows you to take greater control over how you present yourself in every situation.</p>
                    </div>
                    <div className='lessonInfinityNextCh'>
                        <div className='lessonContainer'>
                            <img src={infinityHeaderImg} alt='' />
                            <p className='lessHeaderSubtitle'>CONGRATULATIONS, YOU HAVE COMPLETED</p>
                            <h2 className='lessInfinityNextChTitle'>Second Chapter of Infinity</h2>
                            <div className={"buttonWrapperMaxp w100 mb24 " + disabledBtn} onClick={() => navigate('/lesson-Infinity-3')} style={{ pointerEvents: btnDisable }}>
                                <div className={"button2Maxp w100 " + disabledBtn} style={{ pointerEvents: btnDisable }}>
                                    <Row className='lessonChapterBtn lessNextChBtn'>
                                        <Col md={10}>
                                            <Link to={'/lesson-Infinity-3'}>
                                                <p className='chapterNum '>Next Chapter</p>
                                                <h4 className='chapterTitle '>Final practice - your spaceship pitch</h4>
                                            </Link>
                                        </Col>
                                        <Col md={2} className="btnRightCol">
                                            <Link to={'/lesson-Infinity-3'}>
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonInfinity_2;