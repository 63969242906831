import "../css/main.css";
import "../css/UserDashboard.css";
import woodenMedal from '../img/woodenMedal.png';
import silverMedal from '../img/silverMedal.png';
import goldMedal from '../img/goldMedal.png';
import { Col, Dropdown, DropdownButton, ProgressBar, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import { decrypt } from "n-krypta";
import { useEffect } from "react";
import alzubraSmallBg from '../lessons_img/alzubraSmallBg.png';
import najimaSmallBg from '../lessons_img/najimaSmallBg.png';
import esterSmallBg from '../lessons_img/esterSmallBg.png';
import arsirfSmallBg from '../lessons_img/arsirfSmallBg.png';
import infinitySmallBg from '../lessons_img/infinitySmallBg.png';
import moranonSmallBg from '../lessons_img/moranonSmallBg.png';
import myrthyrSmallBg from '../lessons_img/myrthyrSmallBg.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Button } from "bootstrap";

function Item(props) {

    const lessonMainPage = '/lesson-chapters' + '/' + props.planet;
    const navigate = useNavigate();

    let lessonCardBgImg = '';

    const [btnDisable, setShowLessonBtn] = useState('none');
    const [disabledBtnClass, setDisableBtnClass] = useState('disabledBtn');
    const [saturateElem, setSaturateElem] = useState('saturateElem');
    useEffect(() => {
        const encryptedObject = localStorage.getItem("userData");
        const encryptedObjectCh = localStorage.getItem("userNextChapter");

        if (encryptedObjectCh) {
            const secret = localStorage.getItem("hash");
            const userData = decrypt(encryptedObject, secret);
            const userNextChapter = decrypt(encryptedObjectCh, secret);

            if (userNextChapter.lessonNumber >= props.id) {
                setShowLessonBtn('');
                setDisableBtnClass('');
                setSaturateElem('');
            }

            if (props.id === 1 && userData.userLessonId === 0) {
                console.log(props.id)
                setShowLessonBtn('');
                setDisableBtnClass('');
                setSaturateElem('');
            }
        } else if (props.id === 1) {
            setShowLessonBtn('');
            setDisableBtnClass('');
            setSaturateElem('');
        }
    }, []);

    if (props.planet === 'Alzubra') { lessonCardBgImg = alzubraSmallBg; }
    if (props.planet === 'Najima') { lessonCardBgImg = najimaSmallBg; }
    if (props.planet === 'Ester') { lessonCardBgImg = esterSmallBg; }
    if (props.planet === 'Arsirf') { lessonCardBgImg = arsirfSmallBg; }
    if (props.planet === 'Infinity') { lessonCardBgImg = infinitySmallBg; }
    if (props.planet === 'Moranon') { lessonCardBgImg = moranonSmallBg; }
    if (props.planet === 'Myrthyr') { lessonCardBgImg = myrthyrSmallBg; }

    let cardTitle = '';
    let cardDescription = '';

    if (props.description.length > 120) { cardDescription = props.description.substr(0, props.description.lastIndexOf(' ', 120)) + '...' } else { cardDescription = props.description; }

    if (props.title.length > 24) { cardTitle = props.title.substr(0, props.title.lastIndexOf(' ', 24)) + '...' } else { cardTitle = props.title; }

    return (
        <Col md={6} className={'courseLessonCard spacePortCard ' + saturateElem}>
            <Row className='courseLessonHeaderCardRow'>
                <Col className='courseLessonHeaderCardCol' xs={6}>
                    <span className='lessonNo'>Lesson {props.lessonNumber}</span>
                    <h4 className='cardHeaderPlanet'>{props.planet}</h4>
                </Col>
                <Col className='curseCardPoint' xs={6}>
                    <div className="cardHeaderPoints">+{props.points} XP</div>
                </Col>
            </Row>
            <img src={lessonCardBgImg} alt={props.planet} />
            <div className='cardTextDiv'>
                <h4 className='lessonCardTitle'>{cardTitle}</h4>
                <p className='cardDescriptionText'>{cardDescription}</p>
                <div className='text-center'>
                    <div className={"buttonWrapperMaxp w100 " + disabledBtnClass} onClick={() => navigate(lessonMainPage)} style={{ pointerEvents: btnDisable }}>
                        <div className={"button2Maxp w100 " + disabledBtnClass}>
                            START EXPEDITION
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

function PiscesConstalationTab() {

    const location = useLocation();

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const encryptedObject = localStorage.getItem("userData");
        const secret = localStorage.getItem("hash");
        const decryptedUserData = decrypt(encryptedObject, secret);
        setUserData(decryptedUserData);
    }, []);

    const userPoints = userData.userPoints;
    const nowCal = (userPoints / 486) * 100;
    const now = nowCal.toString().split('.')[0];

    const [items, setItems] = useState([]);

    let cardLessons = [];

    const { isLoading, error, data, refetch } = useQuery('course', () =>
        fetch('https://maxp.quadrixsoft.com/api/lessons.php')
            .then(res => res.json())
            .then((res) => {

                cardLessons = res.lessons.map((item, index) => {
                    return <Item key={index}
                        id={item.id}
                        constalationId={item.constalationId}
                        planet={item.planet}
                        title={item.title}
                        subTitle={item.subTitle}
                        description={item.description}
                        lessonNumber={item.lessonNumber}
                        featured_image={item.featured_image}
                        small_img={item.small_img}
                        points={item.points} />
                });
                setItems(cardLessons);
            }));

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
            window.scrollTo(0, 0);
        }, 300);
        return () => clearTimeout(timer);
    }, [location]);

    if (isLoading) return <Spinner className='sectionSpinner' animation="border" />
    if (error) return <div className='sectionError'><h2>An error has occurred: {error.message}<br />Please try to reload again on the bottom button.</h2><Button className='defButton' onClick={() => window.location.reload(false)}>RELOAD</Button></div>

    return (
        <>
            <Row className="piscesTitleProgresRow">
                <DropdownButton id="dropdown-basic-button-constalation" title="Pisces Constellation">
                    <Dropdown.Item href="#" active>Pisces Constalation</Dropdown.Item>
                </DropdownButton>
                <Col>
                    <h2 className="piscesTitleProgresbar">Pisces Constalation Journey Progress</h2>
                </Col>
                <Col>
                    <span className="progressBarSpan">{now}% Complete</span>
                </Col>
            </Row>
            <ProgressBar now={now} label={`${now}%`} visuallyHidden />
            <Row>
                <Col md={5} className="plr0 underProgresDiv">
                    <img src={woodenMedal} />
                    <div className="barPointsDiv">
                        <span className="medalSpan">Wooden Medal</span>
                        <span className="medalSpanPoint">10000</span><br />
                        <span className="medalSpanPoints">1 - 162 Point </span>
                    </div>
                </Col>
                <Col md={4} className="plr0 underProgresDiv">
                    <img src={silverMedal} />
                    <div className="barPointsDiv">
                        <span className="medalSpan">Silver Medal</span>
                        <span className="medalSpanPoint">+20000</span><br />
                        <span className="medalSpanPoints">163 - 324 Point </span>
                    </div>
                </Col>
                <Col md={3} className="plr0 underProgresDiv" style={{ "textAlign": "right" }}>
                    <img src={goldMedal} />
                    <div className="barPointsDiv">
                        <span className="medalSpan">Gold Medal</span>
                        <span className="medalSpanPoint">+30000</span><br />
                        <span className="medalSpanPoints">325 - 486 Point </span>
                    </div>
                </Col>
            </Row>
            <hr className="hrLine" />
            <h2 className="earnedBadgesTitle">Earned Badges</h2>
            <hr className="hrLine" />
            <Row>{items}</Row>

        </>
    );
}

export default PiscesConstalationTab;