import { Modal } from "react-bootstrap";

function ProfileUpdateModal(props) {

    return (
        <Modal className=""
            show={props.show}
            onHide={props.close}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
        // centered
        >
            <Modal.Body className="modalResend">
                <h5 className="text-center loggedinH2">{props.updateMsg}</h5>
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </Modal.Body>
            {/* <Modal.Footer className="resendFooter">
                <p className="text-center">You will be redirect to your dashboard.</p>
            </Modal.Footer> */}
        </Modal>
    );
}

export default ProfileUpdateModal;