import React, { useEffect, useState } from "react";
import '../css/ForgotPassword.css';
import { Col, Row, Container, Button, Form } from "react-bootstrap";
import axios from "axios";

function ForgotPassword() {

    // Browser TAB Title
    useEffect(() => {
        (document.title = 'MAXP | ' + 'Forgot Password')
    });
    // END Browser TAB Title

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formMsgBg, setFormMsgBg] = useState('');

    function submit(event) {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            axios.post('https://maxp.quadrixsoft.com/api/forgotPassword.php', { 'email': email }, {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }).then((res) => {
                console.log(res)
                if (res.data.message === "A verification link has been sent to your email address.") {
                    setFormMsgBg('');
                    setErrorMessage(res.data.message);
                    const elemStyle = { "background": "#43E8DD", "color": "#281F53" };
                    setFormMsgBg(elemStyle);
                } else {
                    setFormMsgBg('');
                    setErrorMessage(res.data.message);
                    const elemStyle = { "background": "#F76763", "color": "#EEF0F4" };
                    setFormMsgBg(elemStyle);
                }
            }).catch((error) => {
                console.log(error);
            });

            event.preventDefault();
        }
    }

    function clickLogin() {
        document.querySelector('.loginBtn').click();
    }

    return (
        <>
            <div className="bodyBg">
                <Container>
                    <Row className="forPassRow">
                        <Col md={{ span: 6, offset: 3 }} className="forPassCol">
                            <h1>Forgot Password</h1>
                            <div className="formMsg">
                                {
                                    errorMessage !== '' ?
                                        <span className="formMsgFalseLogin" style={formMsgBg}>
                                            <i className="bi bi-exclamation-circle" style={formMsgBg}></i>{errorMessage}
                                        </span> : null
                                }
                            </div>
                            <span className="forPassTxt" >Enter the email address you use on MAXP. We'll send you a link to reset your password.</span>

                            <Form validated={validated} onSubmit={submit}>
                                <Form.Group className="mb32" controlId="formBasicEmail2">
                                    <Form.Label>EMAIL</Form.Label>
                                    <Form.Control
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        type="email"
                                        placeholder="name@email.com"
                                        required
                                    />
                                </Form.Group>
                                <button className="buttonWrapperMaxp w100" type="submit">
                                    <div className="button2Maxp w100">
                                        RESET PASSWORD
                                    </div>
                                </button>
                            </Form>
                            <div className="backToTxt">
                                <span>Back to <span className="loginTxt" onClick={clickLogin}>Log In</span></span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ForgotPassword;