import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/Activation.css";
import "../css/main.css";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useQuery } from "react-query";
import { encrypt } from "n-krypta";

function ActivatedElem(props) {

    const startIntro = 'startIntro';

    return (
        <div className="activeted">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <h1 className="activationMsg">{props.messageTxt}</h1>
            <p>Click <Link state={startIntro} to={{ pathname: "/intro" }}>HERE</Link> to enter the MAXP universe.</p>
        </div>
    );
}

function NotActivatedElem(props) {

    return (
        <div className="notActivated">
            <svg className="checkmarkx" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark_circlex" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark_checkx" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
            </svg>
            <h1 className="wrongActivationMsg">{props.messageTxt}</h1>
            <Link to={{ pathname: "/" }}>GO BACK</Link>
        </div>
    );
}

function ChangePass(props) {

    return (
        <div className="changePass">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <h1 className="changePassMsg">Pilot, Your Account is Verified! </h1>
            <p>Click <Link to={{ pathname: "/reset-password" }} state={{ data: props.email }} >HERE</Link> to change your password.</p>
        </div>
    );
}

function Activation() {

    // Browser TAB Title
    useEffect(() => {
        (document.title = 'MAXP | ' + 'Activation')
    });
    // END Browser TAB Title

    const params = new window.URLSearchParams(window.location.search);
    const email = params.get('email');
    const hash = params.get('hash');
    const changePass = params.get('changePass');

    const [items, setItems] = useState([]);
    let activElem = [];


    const { isLoading, error, data } = useQuery('activation', () =>
        axios.post('https://maxp.quadrixsoft.com/api/verify.php', { 'email': email, 'hash': hash }, {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then((res) => {
            if (!changePass) {
                if (res.data.message === 'Pilot, Your Account is Activated!') {
                    activElem = <ActivatedElem messageTxt={res.data.message} />;
                    localStorage.clear();
                    const secret = res.data.user.hash;
                    const encryptedObject = encrypt(res.data.user, secret);
                    const encryptedObjectCh = encrypt(res.data.userNextChapter, secret);
                    localStorage.setItem("userData", encryptedObject);
                    localStorage.setItem("userNextChapter", encryptedObjectCh);
                    localStorage.setItem("hash", secret);
                } else {
                    //localStorage.clear();
                    activElem = <NotActivatedElem messageTxt={res.data.message} />;
                }
            } else {
                activElem = <ChangePass email={email} />;
            }
            setItems(activElem);
        }));

    return (
        <>
            <div className="activationBg">
                <Container className="activatedCon">
                    {items}
                </Container>
            </div>
        </>
    );
}

export default Activation;