import '../css/Faq.css';
import "../css/main.css";
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AboutMaxpFaq from '../app_elements/aboutMaxpFaq';
import TechnicalQuestionsFaq from '../app_elements/technicalQuestionsFaq';
import DownloadFaq from '../app_elements/downloadFaq';

function Faq() {

    return (
        <>
            <div className="faq">
                <div className='faqHeaderSec' >
                </div>
                <div className='faqBody'>
                    <section className='faqHeader container'>
                        <div className='faqBreadcrumbs'>
                            <Link to='/'>
                                <i className="bi bi-arrow-left"></i><span className=''>Back to Home</span>
                            </Link>
                        </div>
                        <h2 className='faqTitle'>Frequently Asked Questions</h2>
                        <div className='faqTabs'>
                            <Tabs
                                defaultActiveKey="about"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="about" title="About MAXP">
                                    <AboutMaxpFaq />
                                </Tab>
                                <Tab eventKey="technical" title="Technical Questions">
                                    <TechnicalQuestionsFaq />
                                </Tab>
                                <Tab eventKey="download" title="Download">
                                    <DownloadFaq />
                                </Tab>
                            </Tabs>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Faq;